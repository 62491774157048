import React, { useEffect, useState, useCallback, useRef, createRef } from 'react';
import { Row } from 'antd';
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    useReactFlow,
    ReactFlowProvider,
    Position,
    addEdge,
    updateEdge,
} from 'react-flow-renderer';
import { Link } from 'react-router-dom';
import CustomNode from './CustomNode.jsx';
import CustomInput from './CustomInput.jsx';
import CustomOutput from './CustomOutput.jsx';
import ButtonBar from './ButtonBar.jsx';
import AddNodeUtils from './AddNodeUtils.jsx';
import FlowFilters from './FilterMenu';
import ColorFilters from './FormattingMenu';
import { scaleLinear, hierarchy, csvFormat } from 'd3';
import ButtonEdge from './ButtonEdge.jsx';
import { stratify } from 'd3-hierarchy';
//import Papa from "papaparse";
import dagre from 'dagre';
import { countryColors, productColors } from './Colors';
import { toPng } from 'html-to-image';
import { get, child } from 'firebase/database';
import { getAuth } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';

/* This file pulls from the sourceFlowData property in the user's firebase database. Look at useEffect() for more details. 
 * It then uses the data to create a hierarchy of nodes and edges by calling `formatData` with this dataset.
 * The hierarchy is then rendered in the ReactFlow component.
 * 
 * Note: At one point the nodes (suppliers) could have 'verified' or 'active' tags. Currently only the 'verified' tag is used.
 *       But the 'active' tag is still in the code. It is just commented out.
 * 
 * Note: 
 */

// Debug Toggle
const DEBUG = false;

// Custom Types
const nodeTypes = {
    defaultCustom: CustomNode,
    inputCustom: CustomInput,
    outputCustom: CustomOutput,
};
const edgeTypes = {
    buttonEdge: ButtonEdge
}


function TraceFlow({ collapsed, dbRef }) {
    // remove brand logo
    const proOptions = { account: 'paid-pro', hideAttribution: true };

    // =============================== State ===============================

    var [nodes, setNodes, onNodesChange] = useNodesState([]);
    var [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [countryColorsObject, setCountryColorsObject] = useState(countryColors);
    const [productColorsObject, setProductColorsObject] = useState(productColors);
    const [t0Color, setT0Color] = useState('#1D4690');
    const [tMaxDepthColor, setTMaxDepthColor] = useState('#e27b55');
    const edgeUpdateSuccessful = useRef(true);
    const [rawJsonData, setRawJsonData] = useState();
    const [hierarchyData, setHierarchyData] = useState();
    const [currentDirection, setCurrentDirection] = useState('TB');
    const [removeToolsFromCanvas, setRemoveToolsFromCanvas] = useState("block");
    const [parent, setParent] = useState();
    const [nodeCount, setNodeCount] = useState();
    const [t1NamesArray, setT1NamesArray] = useState([]);
    const [formattedCSV, setFormattedCSV] = useState([]);
    let [verified, setVerified] = useState(false);
    let [active, setActive] = useState(false);
    const [addFormDisplayed, setAddFormDisplayed] = useState(false);
    const [filterDisplayed, setFilterDisplayed] = useState(false);
    const [colorsFilterDisplayed, setColorsFilterDisplayed] = useState(false);
    const [filterByCountry, setFilterByCountry] = useState(false);
    const [filterByProduct, setFilterByProduct] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [targetCountryColor, setTargetCountryColor] = useState();
    const [targetProductColor, setTargetProductColor] = useState();
    const [xFilterName, setXFilterName] = useState("x");
    const [yFilterName, setYFilterName] = useState("y");
    const [zFilterName, setZFilterName] = useState("z");
    const [dataKeys, setDataKeys] = useState();
    const [selectedFileName, setSelectedFileName] = useState();

    // ======================== Load Users Source Flow Data ========================
    // load user from firebase
    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const [firebaseUserData, setFirebaseUserData] = useState([]);

    useEffect(async () => {
        if (loading) return <h2 >Loading...</h2>;
        if (!user) return <Link to="/login"></Link>;
        await get(child(dbRef, `users/${user.uid}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    console.log(snapshot.val().sourceFlowData);
                    // iterate through json object and push to array
                    for (var key in snapshot.val().sourceFlowData) {
                        firebaseUserData.push({ "name": key, "data": snapshot.val().sourceFlowData[key] });
                    }
                } else {
                    alert("No data available");
                }
            }).catch((error) => {
                alert(error);
            });
    }, [user, loading]);

    // ============================== Export as PDF =================================
    const ref = createRef(null);

    const download = (image) => {
        const a = document.createElement('a');

        a.setAttribute('download', 'reactflow.png');
        a.setAttribute('href', image);
        a.click();
    };

    const downloadScreenshot = () => {
        toPng(document.querySelector('.react-flow'), {
            filter: (node) => {
                // we don't want to add the minimap and the controls to the image
                if (
                    node?.classList?.contains('react-flow__minimap') ||
                    node?.classList?.contains('react-flow__controls')
                ) {
                    return false;
                }
                return true;
            },
        }).then(download);
    };

    // ================================== Layout ====================================
    // Dagre auto node layout helper
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    //  Dagre node body size
    const nodeWidth = 325;
    const nodeHeight = 185;

    // layout oritentation helper
    const getLayoutedElements = (nodes, edges, direction = 'TB') => {
        const isHorizontal = direction === 'LR';
        dagreGraph.setGraph({ rankdir: direction });

        nodes.forEach((node) => {
            dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
        });

        edges.forEach((edge) => {
            dagreGraph.setEdge(edge.source, edge.target);
        });

        dagre.layout(dagreGraph);

        nodes.forEach((node) => {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.targetPosition = isHorizontal ? 'left' : 'top';
            node.sourcePosition = isHorizontal ? 'right' : 'bottom';

            // We are shifting the dagre node position (anchor=center center) to the top left
            // so it matches the React Flow node anchor point (top left).
            node.position = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            };

            return node;
        });

        return { nodes, edges };
    };

    // condensed function for generating layout
    const onLayout = (direction) => {
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            nodes,
            edges,
            direction
        );

        setNodes([...layoutedNodes]);
        setEdges([...layoutedEdges]);

        setCurrentDirection(direction);
    }

    // auto zoom
    const { fitView } = useReactFlow();
    // zoom duration
    useEffect(() => {
        fitView({ duration: 750 });
    }, [currentDirection, nodes]);

    // ================================ On File Upload ================================
    // create node tree array based on the root node
    function formatData(parsedData) {

        setNodeCount(parsedData.length);

        var _stratify = stratify()
            .parentId(parsedData => parsedData.parent)
            .id(parsedData => parsedData.name)

        try {
            let _root = _stratify(parsedData);
            if (DEBUG) console.log(_root);

            setHierarchyData(_root);

            _root.descendants().forEach((data, index) => {
                data.id = `${index}`;
                data._children = data.children;
                data.children = null;
            });

            // give nodes react-flow necessary properties and create nodes
            const nodes = _root.descendants().map((node) => ({
                id: node.id,
                data: {
                    label: node.data.name,
                    depth: node.depth,
                    country: node.data['country'],
                    product: node.data['product'],
                    verified: node.data['verified'],
                    // active: node.data['active']  // no longer used
                },
                style: { padding: 0, background: colorScale(node.depth), borderRadius: '12px' },
                type: node.parent ? (node._children || node.children ? 'defaultCustom' : 'outputCustom') : "inputCustom",
            }));

            // create edges by finding the links between all the childen of the root node
            const edges = _root.links().map((link, index) => ({
                id: `${index}`,
                source: link.source.id,
                target: link.target.id,
                animated: true,
                label: '',
                style: { animationDirection: 'reverse', stroke: '#656565' },
                type: 'buttonEdge'
            }));

            const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
                nodes,
                edges,
            );

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);

        } catch (error) {
            alert(`(.csv) file not formatted correctly: ${error}`);
        }
    };

    // File input change handler
    // const changeHandler = (event) => {
    //     // Passing file data (event.target.files[0]) to parse using Papa.parse
    //     // d3.csv and d3.csvParse return the index.html page in the public folder, so not used
    //     Papa.parse(event.target.files[0], {
    //         header: true,
    //         skipEmptyLines: true,
    //         complete: function (results) {
    //             // csv file parsed into objects
    //             if (DEBUG) console.log(Object.keys(results.data[0]));
    //             setDataKeys(Object.keys(results.data[0]));
    //             let keys = Object.keys(results.data[0]);
    //             if (keys[0] != "name") alert("First column must have title: 'name'");
    //             if (keys[1] != "parent") alert("Second column must have title: 'parent'");
    //             if (keys[2] != "facility type") alert("Third column must have title: 'facility type'");
    //             if (keys[3] != "product") alert("Forth column must have title: 'product'");
    //             if (keys[4] != "country") alert("Fifth column must have title: 'country'");
    //             if (keys[5] != "verified") alert("Sixth column must have title: 'verified'");
    //             //if (keys[6] != "active") alert("Seventh column must have title: 'active'"); // no longer used
    //             setXFilterName(keys[7]);
    //             setYFilterName(keys[8]);
    //             setZFilterName(keys[9]);

    //             setRawJsonData(results.data);
    //             console.log(results.data);
    //             // format from csv to json
    //             formatData(results.data);
    //         }
    //     })
    // };

    // ===================== Reinit Nodes and Edges ======================

    function getElements() {
        const isHorizontal = currentDirection === 'LR';

        if (DEBUG) console.log("Country Filter", filterByCountry);
        if (DEBUG) console.log("Product Filter", filterByProduct);

        const nodes = hierarchyData.descendants().map((node) => ({
            id: node.id,
            data: {
                label: node.data.name,
                depth: node.depth,
                country: node.data['country'],
                product: node.data['product'],
                verified: node.data['verified'],
                //active: node.data['active'] // no longer used
            },
            position: { x: node.y, y: node.x },
            style: {
                padding: 0,
                background: filterByCountry ? (countryColorScale(node.data['country'])) : (filterByProduct ? (productColorScale(node.data['product'])) : (colorScale(node.depth))),
                borderRadius: '12px'
            },
            sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
            targetPosition: isHorizontal ? Position.Left : Position.Top,
            type: node.parent ? (node._children ? 'defaultCustom' : 'outputCustom') : "inputCustom",
        }));

        const edges = hierarchyData.links().map((d, i) => ({
            id: `${i}`,
            source: d.source.id,
            target: d.target.id,
            label: '',
            //animated: active ? (d.source.data.active == "Yes" ? false : true) : true, // no longer used
            animated: true,
            style: {
                animationDirection: 'reverse',
                stroke: verified ?
                    (d.source.data.verified && d.target.data.verified === "Yes" ? ("#4caf50") : ("#f44e3b"))
                    :
                    ('#656565'),
                // NO LONGER USED    
                // strokeWidth: active ?
                //     ((d.source.data.active === "Yes") ? (10) : (2))
                //     :
                //     (5)
            },
            type: 'buttonEdge'
        }));
        return { nodes, edges };
    };

    // ======================= Re-Format Node Colors =======================

    function reformat() {
        const isHorizontal = currentDirection === 'LR';

        if (DEBUG) console.log("Country Filter", filterByCountry);
        if (DEBUG) console.log("Product Filter", filterByProduct);

        if (hierarchyData) {
            const nodes = hierarchyData.descendants().map((node) => ({
                id: node.id,
                data: {
                    label: node.data.name,
                    depth: node.depth,
                    country: node.data['country'],
                    product: node.data['product'],
                    verified: node.data['verified'],
                    //active: node.data['active'] // no longer used
                },
                position: { x: node.y, y: node.x },
                style: {
                    padding: 0,
                    background: filterByCountry ? (countryColorScale(node.data['country'])) : (filterByProduct ? (productColorScale(node.data['product'])) : (colorScale(node.depth))),
                    borderRadius: '12px'
                },
                sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
                targetPosition: isHorizontal ? Position.Left : Position.Top,
                type: node.parent ? (node._children ? 'defaultCustom' : 'outputCustom') : "inputCustom",
            }));

            const edges = hierarchyData.links().map((d, i) => ({
                id: `${i}`,
                source: d.source.id,
                target: d.target.id,
                label: '',
                //animated: active ? (d.source.data.active == "Yes" ? false : true) : true, // no longer used
                animated: true,
                style: {
                    animationDirection: 'reverse',
                    stroke: verified ?
                        (d.source.data.verified === "Yes" ? ("#4caf50") : ("#f44e3b"))
                        :
                        ('#656565'),
                    // NO LONGER USED
                    // strokeWidth: active ?
                    //     ((d.source.data.active === "Yes") ? (10) : (2))
                    //     :
                    //     (5)
                },
                type: 'buttonEdge'
            }));
            const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
                nodes,
                edges,
                currentDirection
            );

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        }

    }

    // =================== Node Single Click Handler ====================

    const onNodeClick = (event, node) => {
        //if the add form is displayed focus the click there
        if (addFormDisplayed) {
            //set parent node as the node that was clicked on to be sent to the form
            setParent(hierarchyData.find((n) => n.id === node.id))
        }
        // if the add node form is closed, use regular onClick feature
        else {
            // find node that was clicked based on ID
            const c = hierarchyData.find((n) => n.id === node.id);

            // if top node is clicked and the T1 names array has nothing in it, are all the T1 node names for the filter dropdown
            if (c.depth == 0 && t1NamesArray.length == 0) {
                c._children.map((ele) => { t1NamesArray.push({ label: ele.data.name, value: ele.data.name }) })
            }

            if (!c._children) {
                return;
            }
            const isExpanded = !!c.children; // does c.children exist? --> if true, children are already showing 
            c.children = isExpanded ? null : c._children; // if isExpanded is true. Hide all children nodes, otherwise show them.

            const nextElements = getElements();

            const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
                nextElements.nodes,
                nextElements.edges,
                currentDirection
            );

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        }
    };

    // ============================= Collapse Tree =============================

    function collapse(d) {
        if (d.children) {
            d._children = d.children;
            d._children.forEach(collapse);
            d.children = null;
        }
    }

    const collapseAll = () => {
        try {
            hierarchyData.descendants().forEach((data, index) => {
                collapse(data);
            });

            const nextElements = getElements();

            const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
                nextElements.nodes,
                nextElements.edges,
                currentDirection
            );

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        } catch (error) {
            alert(`Please upload a .csv file first`);
        }
    }

    // ============================= Expand Tree =============================

    function expand(d) {
        if (d._children) {
            d.children = d._children;
            d.children.forEach(expand);
        }
    }

    const expandAll = () => {
        try {
            if (t1NamesArray.length == 0) {
                hierarchyData._children.map((ele) => { t1NamesArray.push({ label: ele.data.name, value: ele.data.name }) })
            }

            hierarchyData.descendants().forEach((data, index) => {
                expand(data);
            });

            const nextElements = getElements();

            const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
                nextElements.nodes,
                nextElements.edges,
                currentDirection
            );

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        } catch (error) {
            alert(`Please upload a .csv file first`);
        }
    };

    // ============================= Edge Filters =============================

    const showVerifiedEdges = () => {
        try {
            verified = !verified;
            setVerified(verified);

            const nextElements = getElements();

            const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
                nextElements.nodes,
                nextElements.edges,
                currentDirection
            );

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        } catch (error) {
            alert(`Please upload a .csv file first`);
        }
    }

    // NO LONGER USED
    // const showActiveEdges = () => {
    //     try {
    //         active = !active;
    //         setActive(active);

    //         const nextElements = getElements();

    //         const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    //             nextElements.nodes,
    //             nextElements.edges,
    //             currentDirection
    //         );

    //         setEdges([...layoutedEdges]);
    //     } catch (error) {
    //         alert(`Please upload a .csv file first`);
    //     }
    // }

    // ======================== Draw Edge Connections ========================

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(
        { ...params, animated: true, style: { animationDirection: 'reverse', stroke: verified ? (eds.source.verified == "Yes" ? "green" : "red") : '#656565' }, type: 'buttonEdge' }
        , eds
    )), []);

    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false;
    }, []);

    const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
        edgeUpdateSuccessful.current = true;
        setEdges((els) => updateEdge(oldEdge, newConnection, els));
    }, []);

    const onEdgeUpdateEnd = useCallback((_, edge) => {
        if (!edgeUpdateSuccessful.current) {
            setEdges((eds) => eds.filter((e) => e.id !== edge.id));
        }

        edgeUpdateSuccessful.current = true;
    }, []);

    // ========================= Remove Tools from Canvas =========================
    // press F8 to hide tools from canvas
    function toggleToolsOnCanvas(event) {
        if (removeToolsFromCanvas === "block" && event.keyCode == 119) {
            setRemoveToolsFromCanvas("none")
        } else {
            setRemoveToolsFromCanvas("block")
        }
    }

    useEffect(() => {
        // attach the event listener
        document.addEventListener('keydown', toggleToolsOnCanvas);

        // remove the event listener
        return () => {
            document.removeEventListener('keydown', toggleToolsOnCanvas);
        };
    }, [toggleToolsOnCanvas]);

    // ============================ Add Node to Hierarchy ============================

    const newNode = (node, parentNode) => {
        if (parentNode) {
            if (parentNode._children != null) {
                parentNode.children = parentNode._children;
                parentNode._children = null;
            }
            else {
                parentNode.children = [];
            }
        }

        //set node data from form
        const nodeData = {
            'name': node.name,
            'country': node.country,
            'product': node.product,
            'verified': node.verified,
            // 'active': node.active // no longer used
        };

        //create the new node in the d3.hierarchy format
        const newNode = hierarchy(nodeData);

        //set the parent of the new node
        newNode.parent = parentNode;

        //assign relevant data to new node
        newNode.id = nodeCount ? nodeCount.toString() : "1";
        setNodeCount(nodeCount + 1);
        newNode.children = null;
        newNode._children = null;
        newNode.depth = parentNode.depth + 1;
        newNode.height = 0;

        //add new node to children array of parent
        parentNode.children.push(newNode);
        parentNode._children = parentNode.children

        const nodes = hierarchyData.descendants().map((node) => ({
            id: node.id,
            data: {
                label: node.data.name,
                depth: node.depth,
                country: node.data['country'],
                product: node.data['product'],
                verified: node.data['verified'],
                // active: node.data['active'] // no longer used
            },
            //position: { x: 0, y: 0 }, // no need to pass a position as it is computed by getLayoutedElements
            style: { padding: 0, background: colorScale(node.depth), borderRadius: '12px' },
            type: "defaultCustom",
        }));

        // create edges by finding the links between all the childen of the root node
        const edges = hierarchyData.links().map((link, index) => ({
            id: `${index}`,
            source: link.source.id,
            target: link.target.id,
            animated: true,
            style: { animationDirection: 'reverse', stroke: '#656565' },
        }));

        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            nodes,
            edges,
        );

        setNodes([...layoutedNodes]);
        setEdges([...layoutedEdges]);
    }

    // ================================= T1 Filter ===================================

    const handleT1FilterChange = (values) => {
        // find intersection between t1 values left to display and original array.
        if (DEBUG) console.log(values)
        let itemsToRemove = t1NamesArray.filter(x => !values.includes(x.label));
        if (DEBUG) console.log(itemsToRemove)
        // save the state of the drop down menu
        let namesArray = [];
        values.map((ele) => { namesArray.push({ label: ele, value: ele }) })
        setT1NamesArray(namesArray);

        let resArray = [];
        itemsToRemove.map(x => resArray.push(x.label))
        if (DEBUG) console.log(resArray)

        let nodesToRemove = nodes.filter(node => resArray.includes(node.data.label))
        if (DEBUG) console.log(nodesToRemove);
        if (nodesToRemove.length > 0) {
            removeT1Nodes(nodesToRemove, hierarchyData);
        }
    };

    function removeT1Nodes(nodesToRemove, currentRoot) {
        let index = hierarchyData.children.findIndex(x => x.data.name == nodesToRemove[0].data.label);
        if (DEBUG) console.log(hierarchyData.children[index])
        if (DEBUG) console.log(index)

        hierarchyData.children.splice(index, 1);

        const nextElements = getElements();

        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            nextElements.nodes,
            nextElements.edges,
            currentDirection
        );

        setNodes([...layoutedNodes]);
        setEdges([...layoutedEdges]);
    }

    // ================================= Element Type Filter ===================================

    const handleCustomXFilter = () => {
        try {
            let hierarchyCopy = hierarchyData.copy();
            let nodesToRemove = hierarchyCopy.descendants().filter((ele) => ele.data[dataKeys[7]] !== "Yes")

            for (let i = 0; i < nodesToRemove.length; i++) {
                hierarchyData.descendants().map((ele) => {
                    if (ele.data == nodesToRemove[i].data) {
                        let _node = hierarchyData.find((n) => n.id === ele.id);
                        let _nodeParent = _node.parent
                        let index = _nodeParent.children.findIndex(x => x.data.name == nodesToRemove[i].data.name);
                        // recreate children without that index
                        let resultingChildren = [];
                        let len = _nodeParent.children.length;
                        for (let i = 0; i < len; i++) {
                            if (i !== index) {
                                resultingChildren.push(_nodeParent.children[i]);
                            }
                        }
                        // set parents children array to be the new array with out the specific node
                        _nodeParent.children = resultingChildren
                    }
                })
            }

            const nextElements = getElements();

            const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
                nextElements.nodes,
                nextElements.edges,
                currentDirection
            );

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        } catch (error) {
            alert("Ensure a (.csv) file is uploaded. Error: ", error);
        }
    };

    const handleCustomYFilter = () => {
        try {
            let hierarchyCopy = hierarchyData.copy();
            let nodesToRemove = hierarchyCopy.descendants().filter((ele) => ele.data[dataKeys[8]] !== "Yes")

            for (let i = 0; i < nodesToRemove.length; i++) {
                hierarchyData.descendants().map((ele) => {
                    if (ele.data == nodesToRemove[i].data) {
                        let _node = hierarchyData.find((n) => n.id === ele.id);
                        let _nodeParent = _node.parent
                        let index = _nodeParent.children.findIndex(x => x.data.name == nodesToRemove[i].data.name);
                        // recreate children without that index
                        let resultingChildren = [];
                        let len = _nodeParent.children.length;
                        for (let i = 0; i < len; i++) {
                            if (i !== index) {
                                resultingChildren.push(_nodeParent.children[i]);
                            }
                        }
                        // set parents children array to be the new array with out the specific node
                        _nodeParent.children = resultingChildren
                    }
                })
            }

            const nextElements = getElements();

            const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
                nextElements.nodes,
                nextElements.edges,
                currentDirection
            );

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        } catch (error) {
            alert("Ensure a (.csv) file is uploaded. Error: ", error);
        }
    };

    const handleCustomZFilter = () => {
        try {
            let hierarchyCopy = hierarchyData.copy();

            let nodesToRemove = hierarchyCopy.descendants().filter((ele) => ele.data[dataKeys[9]] !== "Yes")
            if (DEBUG) console.log(nodesToRemove)

            for (let i = 0; i < nodesToRemove.length; i++) {
                hierarchyData.descendants().map((ele) => {
                    if (ele.data == nodesToRemove[i].data) {
                        let _node = hierarchyData.find((n) => n.id === ele.id);
                        let _nodeParent = _node.parent
                        let index = _nodeParent.children.findIndex(x => x.data.name == nodesToRemove[i].data.name);
                        // recreate children without that index
                        let resultingChildren = [];
                        let len = _nodeParent.children.length;
                        for (let i = 0; i < len; i++) {
                            if (i !== index) {
                                resultingChildren.push(_nodeParent.children[i]);
                            }
                        }
                        // set parents children array to be the new array with out the specific node
                        _nodeParent.children = resultingChildren
                    }
                })
            }

            const nextElements = getElements();

            const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
                nextElements.nodes,
                nextElements.edges,
                currentDirection
            );

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        } catch (error) {
            alert("Ensure a (.csv) file is uploaded. Error: ", error);
        }
    };

    // ================================= Country & Product Filters ===================================

    /// filter callback function
    useEffect(() => {
        reformat();
    }, [filterByCountry, filterByProduct, nodeCount, countryColorsObject, productColorsObject])

    // country filter
    const countryFilter = () => {
        if (filterByProduct === true) { setFilterByProduct(current => !current) };
        setFilterByCountry(current => !current);

        document.querySelector(".filter-menu-button-country").classList.toggle("pressed");
    };

    // product filter
    const productFilter = () => {
        if (filterByCountry === true) { setFilterByCountry(current => !current) };
        setFilterByProduct(current => !current);

        document.querySelector(".filter-menu-button-prod").classList.toggle("pressed");
    };

    /// change specific element color in filter
    const changeFilterColor = (item, _color) => {
        if (DEBUG) console.log(item, _color);
        if (filterByCountry) {
            let index = countryColorsObject.findIndex(ele => ele.country == item)
            console.log(index);
            if (index == -1) {
                setCountryColorsObject((countryColors) => [...countryColors, { "country": item, "color": _color }]);
                setFilterByCountry(true);
            } else {
                countryColorsObject[index].color = _color;
                setFilterByCountry(true);
            }
        }
        if (filterByProduct) {
            let index = productColorsObject.findIndex(ele => ele.product == item)
            console.log(index);
            if (index == -1) {
                console.log("Not Found")
                setProductColorsObject((productColors) => [...productColors, { "product": item, "color": _color }]);
                setFilterByProduct(true);
            } else {
                productColorsObject[index].color = _color;
                setFilterByProduct(true);
            }
        }
    }

    // ====================== Node Color Filters ========================

    const colorScale = scaleLinear().domain([0, hierarchyData ? hierarchyData.height : 1]).range([t0Color, tMaxDepthColor]);

    // preset country colors array
    function countryColorScale(country) {
        try {
            let countryColorCode = countryColorsObject.find(ele => ele.country == country);
            return countryColorCode.color;
        } catch (error) {
            return 'tomato';
        }
    }

    // preset country colors array
    function productColorScale(product) {
        try {
            let productColorCode = productColorsObject.find(ele => ele.product == product);
            return productColorCode.color;
        } catch (error) {
            return 'tomato';
        }
    }

    useEffect(() => {
        changeFilterColor(selectedCountry, targetCountryColor)
        reformat();
    }, [targetCountryColor]);

    useEffect(() => {
        changeFilterColor(selectedProduct, targetProductColor)
        reformat();
    }, [targetProductColor]);

    //=================== Reformat hierarchy data into CSV ====================

    const downloadCSV = () => {
        if (hierarchyData) {
            let array = [];
            hierarchyData.descendants().map(d => array.push(d.data));
            setFormattedCSV(csvFormat(array));
            if (DEBUG) console.log(formattedCSV);
        }
    }

    // ============================== Clear Tree Data ==============================

    const clear = () => {
        // clear upload component
        // document.getElementById("upload-capture-input-file").value = ""; // file input no longer used

        // clear state
        setNodes([]);
        setEdges([]);
        setCountryColorsObject(countryColors);
        setProductColorsObject(productColors);
        setT1NamesArray([]);
        setRawJsonData();
        setHierarchyData();
        setCurrentDirection('TB');
        setRemoveToolsFromCanvas("block");
        setParent();
        setNodeCount();
        setFormattedCSV([]);
        setAddFormDisplayed(false);
        setFilterDisplayed(false);
        setColorsFilterDisplayed(false);
        setFilterByCountry(false);
        setFilterByProduct(false);
        setSelectedFileName();
        // reset color scale
        setT0Color('#203864');
        setTMaxDepthColor('#e27b55');
        // reset custom filters
        setXFilterName("x");
        setYFilterName("y");
        setZFilterName("z");
    };



    return (
        <Row ref={ref} className="wrapper" id="trace-flow-container" justify='center'>
            <FlowFilters
                removeToolsFromCanvas={removeToolsFromCanvas}
                filterDisplayed={filterDisplayed}
                setFilterDisplayed={setFilterDisplayed}
                setAddFormDisplayed={setAddFormDisplayed}
                setColorsFilterDisplayed={setColorsFilterDisplayed}
                t1NamesArray={t1NamesArray}
                handleT1FilterChange={handleT1FilterChange}
                //showActiveEdges={showActiveEdges}
                showVerifiedEdges={showVerifiedEdges}
                handleCustomXFilter={handleCustomXFilter}
                handleCustomYFilter={handleCustomYFilter}
                handleCustomZFilter={handleCustomZFilter}
                xFilterName={xFilterName}
                yFilterName={yFilterName}
                zFilterName={zFilterName}
            />
            <ColorFilters
                colorsFilterDisplayed={colorsFilterDisplayed}
                setColorsFilterDisplayed={setColorsFilterDisplayed}
                removeToolsFromCanvas={removeToolsFromCanvas}
                setAddFormDisplayed={setAddFormDisplayed}
                setFilterDisplayed={setFilterDisplayed}
                setT0Color={setT0Color}
                t0Color={t0Color}
                setTMaxDepthColor={setTMaxDepthColor}
                tMaxDepthColor={tMaxDepthColor}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                countryFilter={countryFilter}
                filterByCountry={filterByCountry}
                targetCountryColor={targetCountryColor}
                setTargetCountryColor={setTargetCountryColor}
                productFilter={productFilter}
                filterByProduct={filterByProduct}
                setSelectedProduct={setSelectedProduct}
                targetProductColor={targetProductColor}
                setTargetProductColor={setTargetProductColor}
                getElements={getElements}
                getLayoutedElements={getLayoutedElements}
                currentDirection={currentDirection}
                setNodes={setNodes}
                setEdges={setEdges}
                hierarchyData={hierarchyData}
                changeFilterColor={changeFilterColor}
            />
            <div style={{ width: collapsed ? "94vw" : "88vw", borderWidth: '1px', borderColor: '#000000' }}>
                <AddNodeUtils
                    removeToolsFromCanvas={removeToolsFromCanvas}
                    newNode={newNode}
                    addFormDisplayed={addFormDisplayed}
                    setAddFormDisplayed={setAddFormDisplayed}
                    setFilterDisplayed={setFilterDisplayed}
                    setColorsFilterDisplayed={setColorsFilterDisplayed}
                    parent={parent}
                />
                <ButtonBar
                    expandAll={expandAll}
                    collapseAll={collapseAll}
                    clear={clear}
                    onLayout={onLayout}
                    removeToolsFromCanvas={removeToolsFromCanvas}
                    formattedCSV={formattedCSV}
                    downloadCSV={downloadCSV}
                    downloadScreenshot={downloadScreenshot}
                    firebaseUserData={firebaseUserData}
                    formatData={formatData}
                    selectedFileName={selectedFileName}
                    setSelectedFileName={setSelectedFileName}
                />
                <ReactFlow
                    minZoom={-Infinity}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onNodeClick={onNodeClick}
                    onConnect={onConnect}
                    onEdgeUpdate={onEdgeUpdate}
                    onEdgeUpdateStart={onEdgeUpdateStart}
                    onEdgeUpdateEnd={onEdgeUpdateEnd}
                    fitView
                    snapToGrid
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    proOptions={proOptions}
                >
                    <MiniMap
                        nodeStrokeColor={(n) => {
                            if (n.style?.background) return n.style.background;
                            if (n.type === 'inputCustom') return '#203864';
                            if (n.type === 'outputCustom') return '#E06030';
                            if (n.type === 'defaultCustom') return '#B2B4B8';

                            return '#eee';
                        }}
                        nodeColor={(n) => {
                            if (n.style?.background) return n.style.background;
                            if (n.type === 'inputCustom') return '#203864';
                            if (n.type === 'outputCustom') return '#E06030';
                            if (n.type === 'defaultCustom') return '#B2B4B8';
                        }}
                        nodeBorderRadius={2}
                        style={{ display: removeToolsFromCanvas }}
                    />
                    <Controls style={{ display: removeToolsFromCanvas }} />
                    <Background color="black" gap={24} variant='dots' style={{ backgroundColor: '#D3D2E599', borderRadius: '8px' }} />
                </ReactFlow>
            </div >
        </Row >
    );
}

function TraceFlowWrapper(props) {
    return (
        <ReactFlowProvider>
            <TraceFlow {...props} />
        </ReactFlowProvider>
    );
}

export default TraceFlowWrapper;