// gql queries

export const GET_TOKENINFO_QUERY = `
  {
    claimTokens(first: 5) {
      id
      tokenId
      claimCreator
      country
      tokenURI
      barcodeId
      material
      mass
      purity
      owner
      approved
      timesSold
    }
    listings(first: 5) {
      id
      tokenId
      seller
      buyer
      attestor
      price
      lastPrice
      active
      claimMaterial
      claimTokenURI
      claimCountry
    }
  }
`;

export default GET_TOKENINFO_QUERY;