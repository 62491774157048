import React, { useState } from "react";
import { Button, Row, Col, Card, List, Modal, Cascader } from "antd";
import { PictureOutlined, UserOutlined } from '@ant-design/icons';
import { ethers } from "ethers";
import { WalletOutlined, CreditCardOutlined } from '@ant-design/icons';
import { Address } from "../components";
import { createPayment } from "../components/auth/ApiWrapper";
import { getFromIPFS } from "../helpers/ipfs";

const DEBUG = false;

export default function CommodityMarket({
    userProvider,
    mainnetProvider,
    marketplaceCommodityData,
    yourClaimData,
    writeContracts,
    readContracts,
    tx,
    userType,
    additionalRequirementsTrigger,
    buyerKYCTrigger,
    userCards,
    bankAccts,
}) {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isBuyModalVisible, setIsBuyModalVisible] = useState(false);
    const [isSellerModalVisible, setIsSellerModalVisible] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [docsImageUrl, setDocsImageUrl] = useState("");
    const [filteredData, setFilteredData] = useState(marketplaceCommodityData);
    const [selectedCountry, setSelectedCountry] = useState("None");
    const [selectedMaterial, setSelectedMaterial] = useState("None");

    const showBuyModal = async () => {
        setIsBuyModalVisible(true);
    };

    const handleCancel = (e) => {
        e.stopPropagation(); // prevent the click from bubbling up to the parent

        setIsModalVisible(false);
        setImageUrl("");
        setDocsImageUrl("");
    };

    const handleCancelBuy = (e) => {
        e.stopPropagation(); // prevent the click from bubbling up to the parent

        setIsBuyModalVisible(false);
    };

    const showSellerModal = async () => {
        setIsSellerModalVisible(true);
    };

    async function toDataURL(url) {
        return fetch(url)
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                return URL.createObjectURL(blob);
            });
    }

    async function downloadImageFromURL(url, name = "download", type = "png") {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = await toDataURL(url);
        a.download = name + "." + type;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const handleCancelSeller = (e) => {
        e.stopPropagation(); // prevent the click from bubbling up to the parent

        setIsSellerModalVisible(false);
    };

    function cardColor(material) {
        if (material === "Gold (Au)" || material === 'Gold') {
            return ("#fff9c4")
        }
        if (material === "Cobalt (Co)") {
            return ("#e6f2ff")
        }
        if (material === "Tantalum (Ta)" || material === 'Tantalum') {
            return ("#ffedd9")
        }
        if (material === "Tin (Sn)") {
            return ("#abb8c3")
        }
        if (material === "Mica") {
            return ("#c4def6")
        }
    }

    function getElementImage(material) {
        if (material === "Gold (Au)" || material === 'Gold') {
            return ("Au.png")
        }
        if (material === "Cobalt (Co)") {
            return ("Co.png")
        }
        if (material === "Tantalum (Ta)" || material === 'Tantalum') {
            return ("Ta.png")
        }
        if (material === "Tin (Sn)") {
            return ("Sn.png")
        }
        if (material === "Mica") {
            return ("Mica.png")
        }
    }

    const buyWithWeb3Wallet = async (item) => {
        // only 'buyer' userTypes with completed KYC can purchase claim tokens
        if (
            userType == 'creator' ||
            userType == 'beneficiary_ngo' ||
            userType == 'auditor' ||
            additionalRequirementsTrigger === true ||
            additionalRequirementsTrigger === null ||
            buyerKYCTrigger === true ||
            buyerKYCTrigger === null
        ) {
            alert("User type not authorized to purchase claim tokens, please verify your account status and user type to proceed.")
        };

        // purchase with web3 wallet route
        if (userProvider) {
            const usdcContract = new ethers.Contract(
                "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", // USDC contract address on mainnet
                ["function approve(address spender, uint256 amount) public returns (bool)"], // approve function signature
                userProvider.getSigner()
            );
            // approve usdc from users wallet
            await tx(usdcContract.approve(writeContracts.ARMarketplace.address, item.price * 10 ** 6));
            // send buy tx
            await tx(writeContracts.ARMarketplace.buyItem(parseInt(item.id)));
        } else {
            alert("Wallet must be connected for Web3 purchase.")
        }
        setIsBuyModalVisible(false);
    }

    const buyWithCreditCard = async (item) => {
        let cvv = prompt("enter credit card cvv code: ")

        try {
            let amount = item.price / 10 ** 6;
            let res = await createPayment(amount.toString(), "USD", userCards.data[0].id.toString(), cvv);
            console.log(res);
            if (res.data.status == 'pending') {
                setIsBuyModalVisible(false);
                alert("Payment pending, please check your email for confirmation.");
            }
        } catch (error) {
            alert(error);
        }
    }

    const buyWithBankAccount = async (item) => {
        // need to create a transfer function in Adaptive API to transfer the price/ 10**6 to the merchant account
        setIsBuyModalVisible(false);
        alert("Payment pending, please check your email for confirmation.");
    }

    // Filter data function
    const filterData = (country, material) => {
        let filtered = marketplaceCommodityData;

        // Apply the country filter
        if (country !== "None") {
            filtered = filtered.filter((claim) => claim.claimCountry.split(', ')[0] === country);
        }

        // Apply the material filter
        if (material !== "None") {
            filtered = filtered.filter((claim) => claim.claimMaterial === material);
        }

        // Update the filteredData state with the result
        setFilteredData(filtered);
    };


    return (
        <div style={{ minHeight: '100vh' }}>
            <Row justify="left" style={{ marginLeft: '2vw' }}>
                <h1 style={{ color: "#203864", marginTop: '30px' }}>Commodity Market</h1>
            </Row>
            <Row justify="left" style={{ marginBottom: '12px', marginLeft: '2vw' }}>
                <Col>
                    <p style={{ fontSize: '99%', textAlign: 'left' }}>
                        On Chain marketplace for buying Commodity Claim tokens. Select a claim token to view it's metadata.
                    </p>
                </Col>
            </Row>
            <Row justify="left" style={{ marginBottom: '12px', marginLeft: '2vw' }}>
                <Col >
                    <Cascader
                        size="small"
                        style={{ marginLeft: '2px' }}
                        options={[
                            {
                                value: "None",
                                label: "None",
                            },
                            {
                                value: "Uganda",
                                label: "Uganda",
                            },
                            {
                                value: "Rwanda",
                                label: "Rwanda",
                            },
                            {
                                value: "Kenya",
                                label: "Kenya",
                            },
                            {
                                value: "Tanzania",
                                label: "Tanzania",
                            },
                            {
                                value: "DRC",
                                label: "Democratic Republic of the Congo",
                            },
                        ]}
                        placeholder="Filter by Country"
                        allowClear={false}
                        onChange={(value) => {
                            setSelectedCountry(value[0]);
                            filterData(value[0], selectedMaterial);
                        }}
                    />
                </Col>
                <Col >
                    <Cascader
                        size="small"
                        style={{ marginLeft: '2px' }}
                        options={[
                            {
                                value: "None",
                                label: "None",
                            },
                            {
                                value: "Gold (Au)",
                                label: "Gold",
                            },
                            {
                                value: "Cobalt (Co)",
                                label: "Cobalt",
                            },
                            {
                                value: "Tantalum (Ta)",
                                label: "Tantalum",
                            },
                            {
                                value: "Tin (Sn)",
                                label: "Tin",
                            },
                            {
                                value: "Mica",
                                label: "Mica",
                            },
                        ]}
                        placeholder="Filter by Commodity"
                        allowClear={false}
                        onChange={(value) => {
                            setSelectedMaterial(value[0]);
                            filterData(selectedCountry, value[0]);
                        }}
                    />
                </Col>
            </Row>
            <div >
                <Row justify='center'>
                    <Col span={23}>
                        <List
                            grid={{
                                gutter: 8,
                                xs: 1,
                                sm: 2,
                                md: 3,
                                lg: 4,
                                xl: 5,
                                xxl: 5,
                            }}
                            dataSource={filteredData}
                            renderItem={(item) => {
                                let listedByUser = marketplaceCommodityData.filter((claim) => claim.id === item.id); // filter yourClaimData for claim ids you own
                                if (item.active !== null && item.id > 1) {
                                    return (
                                        <List.Item key={item.id + "_" + item.claimMaterial + "_" + item.claimTokenURI}>
                                            <Card
                                                hoverable
                                                style={{ borderRadius: "36px", background: cardColor(item.claimMaterial) }}
                                                onClick={async (e) => {
                                                    e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                    let uri = await readContracts.ARClaimToken.tokenURI(item.tokenId)
                                                    let uriSplit = uri.split('ipfs/')[1]
                                                    console.log(uriSplit)
                                                    window.open("https://adaptiveresources.infura-ipfs.io/ipfs/" + uriSplit)
                                                }}
                                            >
                                                <div style={{ marginBottom: "16px", fontSize: '14px' }}>
                                                    <h4 style={{ fontWeight: 'bolder', fontSize: '16px' }}>{item.claimCountry.split(',')[1] + ", " + item.claimCountry.split(",")[0]}</h4>
                                                </div>
                                                <div >
                                                    <img src={getElementImage(item.claimMaterial)} style={{ width: "75%" }} alt={"Claim Image"} />
                                                </div>
                                                <div style={{ fontSize: "16px", fontWeight: "500", margin: "12px" }}>
                                                    <Row align="middle" justify="center">
                                                        {item.price / 10 ** 6}
                                                        <img src="USDC_Logo.svg" alt="*USDC" style={{ width: '25px', marginLeft: 5, marginTop: 2 }} />
                                                    </Row>
                                                </div>
                                                <Row justify="center">
                                                    <Col flex={1}>
                                                        <Button
                                                            style={{ backgroundColor: cardColor(item.claimMaterial), border: cardColor(item.claimMaterial) }}
                                                            icon={<PictureOutlined />}
                                                            onClick={async (e) => {
                                                                e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                                // get token uri from contract, returns metadata content
                                                                const uri = await readContracts.ARClaimToken.tokenURI(item.tokenId);
                                                                if (DEBUG) console.log("token uri: ", uri)
                                                                // scrape image from metadata content
                                                                const content = await getFromIPFS(uri.split('ipfs/')[1]);
                                                                const startIndex = content._bufs.toString('utf8').indexOf("Image: https://ipfs.io/ipfs/") // cant use 'Product Image' because DD tokens say 'Site Image'
                                                                if (startIndex > 0) {
                                                                    const imageIPFS = content._bufs.toString('utf8').slice(startIndex)
                                                                    const hash = imageIPFS.split("/ipfs/")[1].slice(0, 46)
                                                                    const infuraURL = "https://adaptiveresources.infura-ipfs.io/ipfs/" + hash
                                                                    setImageUrl(infuraURL)
                                                                } else {
                                                                    const imageIPFS = "https://adaptiveresources.infura-ipfs.io/ipfs/QmTxkcN897hdiRYyCAFW57gzLpfbbqLaq9hyYvwdjD7Xgw"
                                                                    setImageUrl(imageIPFS)
                                                                }
                                                                setIsModalVisible(true);
                                                            }}
                                                        >
                                                        </Button>
                                                    </Col>
                                                    <Col flex={1}>
                                                        {listedByUser.length > 0 ?
                                                            <Button
                                                                disabled
                                                                type="primary"
                                                                shape="round"
                                                                style={{ background: "#ff7875", borderColor: "#ff7875" }}
                                                            >
                                                                Buy
                                                            </Button>
                                                            :
                                                            <Button
                                                                onClick={async (e) => {
                                                                    e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                                    await showBuyModal();
                                                                }}
                                                                type="primary"
                                                                shape="round"
                                                                style={{ background: "#ff7875", borderColor: "#ff7875" }}
                                                            >
                                                                Buy
                                                            </Button>
                                                        }
                                                    </Col>
                                                    <Col flex={1}>
                                                        <Button
                                                            onClick={async (e) => {
                                                                e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                                // get token uri from contract, returns metadata content
                                                                const uri = await readContracts.ARClaimToken.tokenURI(item.tokenId);
                                                                if (DEBUG) console.log("token uri: ", uri)
                                                                // scrape image from metadata content
                                                                const content = await getFromIPFS(uri.split('ipfs/')[1]);
                                                                const startIndex = content._bufs.toString('utf8').indexOf("Documentation: https://ipfs.io/ipfs/") // cant use 'Product Image' because DD tokens say 'Site Image'
                                                                if (startIndex > 0) {
                                                                    const imageIPFS = content._bufs.toString('utf8').slice(startIndex)
                                                                    const hash = imageIPFS.split("/ipfs/")[1].slice(0, 46)
                                                                    const infuraURL = "https://adaptiveresources.infura-ipfs.io/ipfs/" + hash
                                                                    setDocsImageUrl(infuraURL)
                                                                } else {
                                                                    const imageIPFS = "https://adaptiveresources.infura-ipfs.io/ipfs/QmTxkcN897hdiRYyCAFW57gzLpfbbqLaq9hyYvwdjD7Xgw"
                                                                    setDocsImageUrl(imageIPFS)
                                                                }
                                                                showSellerModal();
                                                            }}
                                                            style={{ backgroundColor: cardColor(item.claimMaterial), border: cardColor(item.claimMaterial) }}
                                                            icon={<UserOutlined />}
                                                        >
                                                        </Button>
                                                    </Col>
                                                    <div >
                                                        <Modal open={isBuyModalVisible} onCancel={handleCancelBuy} forceRender={true} footer={null}>
                                                            <Row >
                                                                <h3 >Select Payment Method</h3>
                                                            </Row>
                                                            <Card style={{ borderRadius: '16px', backgroundColor: '#c5cae950' }}>
                                                                <Row justify="center" >
                                                                    <Button className="payment-method-card"
                                                                        disabled={userProvider == null}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                                            buyWithWeb3Wallet(item)
                                                                        }}
                                                                    >
                                                                        <WalletOutlined style={{ paddingRight: '4px' }} />Web3 Wallet
                                                                    </Button>
                                                                    <Button className="payment-method-card"
                                                                        disabled={userCards == null || userCards.ok == false}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                                            buyWithCreditCard(item)
                                                                        }}
                                                                    >
                                                                        <CreditCardOutlined style={{ paddingRight: '4px' }} />Credit Card
                                                                    </Button>
                                                                    <Button className="payment-method-card"
                                                                        disabled={bankAccts == null || bankAccts.ok == false}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                                            buyWithBankAccount(item)
                                                                        }}
                                                                    >
                                                                        <img src="USDC_Logo.svg" alt="*USDC" style={{ width: '25px', marginRight: '8px', opacity: '60%', marginBottom: '2px' }} />Circle USDC
                                                                    </Button>
                                                                </Row>
                                                            </Card>
                                                        </Modal>
                                                        <Modal open={isModalVisible} title="" onCancel={handleCancel} forceRender={true} footer={null}>
                                                            <Row >
                                                                <h4 >{item.claimCountry.split(",")[1] + ', ' + item.claimCountry.split(",")[0]}</h4>
                                                                <img src={imageUrl} style={{ width: "75%" }} alt={"Claim Image"} />
                                                            </Row>
                                                        </Modal>
                                                        <Modal open={isSellerModalVisible} onCancel={handleCancelSeller} forceRender={true} footer={null} onClick={(e) => { e.stopPropagation() }}>
                                                            <Row onClick={(e) => { e.stopPropagation() }}>
                                                                <h3 onClick={(e) => { e.stopPropagation() }}>Claim Token seller:
                                                                    <Address
                                                                        address={item.seller}
                                                                        ensProvider={mainnetProvider}
                                                                        blockExplorer={"https://etherscan.io/"}
                                                                        fontSize={20}
                                                                    />
                                                                </h3>
                                                            </Row>

                                                            <Row justify="left" style={{ marginTop: '18px' }} onClick={(e) => { e.stopPropagation() }}>
                                                                <h4 onClick={(e) => { e.stopPropagation() }}>Export Documentation</h4>
                                                                <img src={docsImageUrl} style={{ width: "100%" }} alt={"Claim Image"} onClick={(e) => { e.stopPropagation() }} />
                                                                {/* Download button */}
                                                                <Row onClick={(e) => { e.stopPropagation() }}>
                                                                    <Button shape="round" onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        downloadImageFromURL(
                                                                            docsImageUrl,
                                                                            'export_doc.png'
                                                                        )
                                                                    }}>
                                                                        Download
                                                                    </Button>
                                                                </Row>
                                                            </Row>
                                                        </Modal>
                                                    </div>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )
                                }
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </div >
    );
}