import React, { useState } from "react";
import { Row, Col, Card, Statistic, Table, Tag } from "antd";
import { dataFake, dataFake2, SBGMineData } from "./psuedoData/psuedoData";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend, Sector, LineChart, Line } from 'recharts';

export default function SBGDashboard() {

    // const COLORS = ['#1890ff', '#2fc25b', '#facc14', '#f04864', '#8543e0'];

    const SBGColumns = [
        {
            title: 'Mine Name',
            dataIndex: 'mineName',
            key: 'mineName',
            render: (text) => <b>{text}</b>,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                let color = "";
                switch (text) {
                    case "Active":
                        color = "green";
                        break;
                    case "Inactive":
                        color = "orange";
                        break;
                    case "Exploration Permit":
                        color = "blue";
                        break;
                    default:
                        color = "gray";
                        break;
                }
                return <Tag color={color}>{text.toUpperCase()}</Tag>;
            },
        },
        {
            title: 'Last Updated',
            dataIndex: 'lastUpdated',
            key: 'lastUpdated',
        },
    ];

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc' }}>
                    <h5 style={{ fontSize: '16px' }}>{`${payload[0].payload.name} : ${payload[0].payload.tokens}%`}</h5>
                    <p style={{ fontSize: '12px' }}>{`${payload[0].payload.description}`}</p>
                </div>
            );
        }

        return null;
    };

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} >
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };

    const [activeIndex, setActiveIndex] = useState({ activeIndex: 0 })

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };


    return (
        <div style={{ minHeight: '100vh', background: '#F5F5F5', padding: '24px' }}>
            <Row gutter={[24, 24]} justify="center" align="middle" style={{ paddingBottom: '18px' }}>
                <Col xs={24} sm={12} md={6}>
                    <Card>
                        <Statistic
                            title="Total Mines"
                            value={`5`}
                            valueStyle={{ color: '#1890ff' }}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card>
                        <Statistic
                            title="Total Members"
                            value={`3`}
                            valueStyle={{ color: '#1890ff' }}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card>
                        <Statistic
                            title="Total Investment"
                            value={`$10,500`}
                            precision={2}
                            valueStyle={{ color: '#1890ff' }}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card>
                        <Statistic
                            title="Total Exports"
                            value={`$60,800`}
                            valueStyle={{ color: '#1890ff' }}
                        />
                    </Card>
                </Col>
            </Row>

            <Row gutter={[24, 24]} justify="center">
                <Col xs={24} md={12}>
                    <Card style={{ backgroundColor: '#fafafa' }}>
                        <h4 style={{ textAlign: 'center' }}>Flow of Premium Investments</h4>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={dataFake2}>
                                <defs>
                                    <filter id="shadow" height="200%">
                                        <feDropShadow dx="0" dy="10" stdDeviation="10" />
                                    </filter>
                                </defs>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip cursor={{ stroke: '#8884d825', strokeWidth: 1 }} />
                                <Legend layout="horizontal" />
                                <Line
                                    type="monotone"
                                    dataKey="USD"
                                    stroke="#3b5998"
                                    strokeWidth={2}
                                    filter="url(#shadow)"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>
                <Col xs={24} md={12}>
                    <Card style={{ backgroundColor: '#fafafa' }}>
                        <h4>Between Mine and Members</h4>
                        <div className="claims-chart">
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={dataFake}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis dataKey="tokens" />
                                    <Tooltip cursor={{ stroke: '#8884d825', strokeWidth: 1 }} />
                                    <Legend layout="horizontal" />
                                    <Bar dataKey="tokens" fill="#708fd2" radius={[8, 8, 0, 0]} />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Card>
                </Col>
            </Row>

            <div style={{ overflow: 'auto' }}>
                <Row style={{ paddingTop: '18px' }}>
                    {/* <Col xs={24} md={12} lg={12}>
                        <ResponsiveContainer width={'100%'} height={400}>
                            <PieChart>
                                <Pie
                                    data={payoutStructure}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={true}
                                    legendType="circle"
                                    innerRadius={'40%'}
                                    outerRadius={'50%'}
                                    nameKey='name'
                                    dataKey="tokens"
                                    fill="#8884d8"
                                    label
                                >
                                    {
                                        payoutStructure.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))
                                    }
                                </Pie>
                                <Legend verticalAlign="top" height={8} />
                            </PieChart>
                        </ResponsiveContainer>
                    </Col> */}
                    <Col xs={24} md={24} lg={24}>
                        <Table
                            className="mine-status-table"
                            columns={SBGColumns}
                            dataSource={SBGMineData}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </div>

        </div >
    );
}