import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { auth } from "./Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { get, child, ref, getDatabase } from "firebase/database";
import { Spin } from 'antd';

function PrivateRoute({ isAllowed, redirectTo = "/", ...props }) {
    const [user, loading, error] = useAuthState(auth);
    const [userType, setUserType] = useState(null);
    const navigate = useNavigate();

    const dbRef = ref(getDatabase());

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (loading) {
                    return <h2>Loading...</h2>;
                }
                if (!user) {
                    return navigate("/login");
                }

                // Get user data from Firebase
                const snapshot = await get(child(dbRef, `users/${user.uid}`));
                if (snapshot.exists()) {
                    const userData = snapshot.val();

                    if (userData.newAccountTrigger) {
                        return window.location.replace('https://signup.adaptiveresources.io/');
                    }

                    setUserType(userData.userType);
                } else {
                    alert('No data available');
                }

            } catch (error) {
                alert(error);
            }
        };

        fetchData();
    }, [user, loading, navigate]);

    if (loading || userType === null) {
        return (
            <div style={{ margin: '48vh' }}>
                <Spin />
            </div>
        );
    }
    if (!isAllowed.includes(userType)) {
        return (
            <div style={{ margin: '48vh' }}>
                <h1>User Not Authorized</h1>
                <p>For more information please contact support</p>
            </div>
        );
    }

    return <Outlet />;
}

export default PrivateRoute;
