export default function RiskAssessmentIndicator(props) {

    function getOECDStatusColor(data) {
        if (data === "Low") {
            return ("#00ff0080") // green
        }
        else if (data === "Medium") {
            return ("#ffbf0080") // orange
        }
        else if (data === "High") {
            return ("#ff3d0080") // red
        }
        else {
            return ("#abb8c380") // grey
        }
    }

    function getOECDStatusText(data) {
        if (data === "Low") {
            return ("L")
        }
        else if (data === "Medium") {
            return ("M")
        }
        else if (data === "High") {
            return ("H")
        }
        else {
            return ("")
        }
    }

    return (
        <span style={{ marginRight: '4px' }}>
            <svg height="15" width="15">
                <circle cx="7.5" cy="7.5" r="7.5"
                    fill={(
                        getOECDStatusColor(props.riskData)
                    )}
                />
                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="10">
                    {getOECDStatusText(props.riskData)}
                </text>
            </svg>
        </span>
    );
}