import React from 'react';
import { ExclamationCircleFilled } from "@ant-design/icons"
import { Link } from 'react-router-dom';
import { Row, Col, Alert } from 'antd';
import './../Profile.css';

/**
   This component displays the status of a user's account setup status. Each user type is required to complete
   different forms of KYC. This component displays the status of each form of KYC. To be considered "active" a user
   must complete all forms of KYC in order to be eligible to receive payments, create claims, buy claim tokens, or audit tokens.

   All form submissions are collected by docusign and reviewed by the Adaptive admins before being approved. Once approved, the user's
   account will be considered "active".

   NOTE: all users are required to complete the "Additional User Information" form.
*/

export default function AccountSetupStatus({
    userType,
    additionalRequirementsTrigger,
    createClaimBusinessActivityKYCTrigger,
    createClaimManagementKYCTrigger,
    createClaimResponsibleSupplyChainKYC,
    createClaimSignedAcknowledgementKYCTrigger,
    beneficiaryNGOKYCTrigger,
    buyerKYCTrigger
}) {
    return (
        <div className="account-status">
            <Col >
                {/* additional-requirements - ALL USER TYPES */}
                <Row justify='left' style={{ marginBottom: '3px' }}>
                    {additionalRequirementsTrigger === true ? (
                        <span className="account-kyc">
                            <ExclamationCircleFilled style={{ marginRight: '4px' }} />
                            <Link to="/Additional_Requirements.pdf" style={{ color: '#f4c63c' }} target='_blank' download>
                                Additional User Information
                            </Link>
                        </span>
                    ) : ("")}
                </Row>
                {/* admin */}
                <div style={{ marginBottom: '3px' }}>
                    {userType == 'admin' && additionalRequirementsTrigger === false && createClaimSignedAcknowledgementKYCTrigger === false && createClaimResponsibleSupplyChainKYC === false && createClaimManagementKYCTrigger === false && createClaimBusinessActivityKYCTrigger === false && beneficiaryNGOKYCTrigger === false && buyerKYCTrigger === false ? (
                        <Row justify="center">
                            <Alert
                                message={<span style={{ margin: '0px', fontWeight: 'bold' }}>Active</span>}
                                type="success"
                                showIcon
                                style={{ borderRadius: '8px' }}
                            />
                        </Row>
                    ) : ("")}
                </div>
                {/* creator */}
                <Row justify='left' style={{ marginBottom: '3px' }}>
                    {userType == 'creator' && createClaimBusinessActivityKYCTrigger === true ? (
                        <span className="account-kyc">
                            <ExclamationCircleFilled style={{ marginRight: '4px' }} />
                            <Link to={'/Business_Activity.pdf'} style={{ color: '#f4c63c' }} target='_blank' download>
                                Business Activity
                            </Link>
                        </span>
                    ) : ("")}
                </Row>
                <Row justify='left' style={{ marginBottom: '3px' }}>
                    {userType == 'creator' && createClaimManagementKYCTrigger === true ? (
                        <span className="account-kyc">
                            <ExclamationCircleFilled style={{ marginRight: '4px' }} />
                            <Link to={'/Ownership_and_Management.pdf'} style={{ color: '#f4c63c' }} target='_blank' download>
                                Ownership and Management
                            </Link>
                        </span>
                    ) : ("")}
                </Row>
                <Row justify='left' style={{ marginBottom: '3px' }}>
                    {userType == 'creator' && createClaimResponsibleSupplyChainKYC === true ? (
                        <span className="account-kyc">
                            <ExclamationCircleFilled style={{ marginRight: '4px' }} />
                            <Link to={'/Supply_Chain_Policy.pdf'} style={{ color: '#f4c63c' }} target='_blank' download>
                                Supply Chain Policy
                            </Link>
                        </span>
                    ) : ("")}
                </Row>
                <Row justify='left' style={{ marginBottom: '3px' }}>
                    {userType == 'creator' && createClaimSignedAcknowledgementKYCTrigger === true ? (
                        <span className="account-kyc">
                            <ExclamationCircleFilled style={{ marginRight: '4px' }} />
                            <Link to={'/Signed_Acknowledgement.pdf'} style={{ color: '#f4c63c' }} target='_blank' download>
                                Signed Acknowledgement
                            </Link>
                        </span>
                    ) : ("")}
                </Row>
                <div style={{ marginBottom: '3px' }}>
                    {userType == 'creator' && additionalRequirementsTrigger === false && createClaimSignedAcknowledgementKYCTrigger === false && createClaimResponsibleSupplyChainKYC === false && createClaimManagementKYCTrigger === false && createClaimBusinessActivityKYCTrigger === false ? (
                        <Row justify="center">
                            <Alert
                                message={<span style={{ margin: '0px', fontWeight: 'bold' }}>Active</span>}
                                type="success"
                                showIcon
                                style={{ borderRadius: '8px' }}
                            />
                        </Row>
                    ) : ("")}
                </div>
                {/* beneficiary_ngo */}
                <Row justify='left' style={{ marginBottom: '3px' }}>
                    {userType == 'beneficiary_ngo' && beneficiaryNGOKYCTrigger === true ? (
                        <span className="account-kyc">
                            <ExclamationCircleFilled style={{ marginRight: '4px' }} />
                            <Link to={'/Financial_Info_and_Mobile_Money.pdf'} style={{ color: '#f4c63c' }} target='_blank' download>
                                Financial Institution / Mobile Money Info
                            </Link>
                        </span>
                    ) : ("")}
                </Row>
                <div style={{ marginBottom: '3px' }}>
                    {userType == 'beneficiary_ngo' && additionalRequirementsTrigger === false && beneficiaryNGOKYCTrigger === false ? (
                        <Row justify="center">
                            <Alert
                                message={<span style={{ margin: '0px', fontWeight: 'bold' }}>Active</span>}
                                type="success"
                                showIcon
                                style={{ borderRadius: '8px' }}
                            />
                        </Row>
                    ) : ("")}
                </div>
                {/* buyer */}
                <Row justify='left' style={{ marginBottom: '3px' }}>
                    {userType == 'buyer' && buyerKYCTrigger === true ? (
                        <span className="account-kyc">
                            <ExclamationCircleFilled style={{ marginRight: '4px' }} />
                            <Link to={'/Downstream_Supply_Chain_Policy.pdf'} style={{ color: '#f4c63c' }} target='_blank' download>
                                Downstream Supply Chain Policy
                            </Link>
                        </span>
                    ) : ("")}
                </Row>
                <div style={{ marginBottom: '3px' }}>
                    {userType == 'buyer' && additionalRequirementsTrigger === false && buyerKYCTrigger === false ? (
                        <Row justify="center">
                            <Alert
                                message={<span style={{ margin: '0px', fontWeight: 'bold' }}>Active</span>}
                                type="success"
                                showIcon
                                style={{ borderRadius: '8px' }}
                            />
                        </Row>
                    ) : ("")}
                </div>
                {/* auditor */}
                <div style={{ marginBottom: '3px' }}>
                    {userType == 'auditor' && additionalRequirementsTrigger === false ? (
                        <Row justify="center">
                            <Alert
                                message={<span style={{ margin: '0px', fontWeight: 'bold' }}>Active</span>}
                                type="success"
                                showIcon
                                style={{ borderRadius: '8px' }}
                            />
                        </Row>
                    ) : ("")}
                </div>
            </Col>
        </div>
    );
}
