import { Row, Col } from 'antd';
import { TraceFlow } from '../components/';

export default function AdaptiveTrace({ collapsed, dbRef }) {

    return (
        <div>
            <Row justify='center'>
                <Col span={24}>
                    <TraceFlow collapsed={collapsed} dbRef={dbRef} />
                </Col>
            </Row>
        </div>
    );
}