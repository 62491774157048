import { Button, Card, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { addCard } from "./ApiWrapper.js";


export default function CircleUserCCSignUp({ }) {
    const [form] = Form.useForm();

    // state
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(); // must be at least a first and last name or Circle will reject
    const [city, setCity] = useState();
    const [country, setCountry] = useState(); // must be 2-letter country code
    const [streetAddr1, setStreetAddr1] = useState(); // line1
    const [district, setDistrict] = useState();
    const [postalCode, setPostalCode] = useState();
    const [cardNumber, setCardNumber] = useState();
    const [cardCVV, setCardCVV] = useState();
    const [expDate, setExpDate] = useState(); // [0] = year, [1] = month
    const [submitted, setSubmitted] = useState(false);

    // *** FORM ITEMS ****

    // Handle Failed

    const onFinishFailed = async errorInfo => {
        console.log("Failed", form.getFieldsError);
    };

    // *** HANDLE SUBMIT ***

    const handleSubmit = async () => {
        setLoading(true);

        const response = await addCard(
            name,
            city,
            country,
            streetAddr1,
            district,
            postalCode,
            parseInt(cardNumber),
            parseInt(cardCVV),
            parseInt(expDate[0]),
            parseInt(expDate[1]),
        );

        console.log(response);

        setLoading(false);
        setSubmitted(true);
    };

    return (
        <div style={{ margin: "30px", minHeight: "100vh" }}>
            <h1 style={{ color: "#203864" }}>Adaptive Wallet Setup - Credit Card</h1>
            <Row justify="center">
                <div className="create-claim-form" style={{ margin: "16px", fontSize: "16px" }}>
                    More{" "}
                    <a href="https://www.circle.com/en/merchant-payments" target="blank">
                        information
                    </a>{" "}
                    on Circle/ USDC crypto payments
                </div>
            </Row>
            <Row justify="center">
                <Col xs={0} sm={0} md={1} lg={2} xl={5}></Col>
                <Col xs={24} sm={24} md={22} lg={18} xl={14}>
                    <Card hoverable={true} style={{ background: "#d9d9d950", borderRadius: "18px" }}>
                        <Form
                            className="create-claim-form"
                            form={form}
                            layout="horizontal"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={handleSubmit}
                            onFinishFailed={onFinishFailed}
                            requiredMark={false}
                            labelAlign="right"
                            labelWrap
                            colon={false}
                        >
                            <Form.Item
                                label={"Name"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    disabled={submitted}
                                    placeholder="Full Name"
                                    onChange={e => {
                                        setName(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={"Address"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    disabled={submitted}
                                    placeholder="Address"
                                    onChange={e => {
                                        setStreetAddr1(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={"Country 2-digit code"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    disabled={submitted}
                                    placeholder="Country"
                                    onChange={e => {
                                        setCountry(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={"City"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    disabled={submitted}
                                    placeholder="City"
                                    onChange={e => {
                                        setCity(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            {/*  */}
                            <Form.Item
                                label={"State 2-digit code e.g. MA"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    disabled={submitted}
                                    placeholder="State"
                                    onChange={e => {
                                        setDistrict(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            {/*  */}
                            <Form.Item
                                label={"Postal Code"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    disabled={submitted}
                                    placeholder="Postal / ZIP code"
                                    onChange={e => {
                                        setPostalCode(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={"Card Number"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    disabled={submitted}
                                    placeholder="Card Number"
                                    onChange={e => {
                                        setCardNumber(e.target.value);
                                    }}
                                />
                                <Input
                                    disabled={submitted}
                                    placeholder="CVV"
                                    onChange={e => {
                                        setCardCVV(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={"Card Expiration"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    disabled={submitted}
                                    type="month"
                                    placeholder="Card Expiration (MM-YYYY) for e.g. 01-2025"
                                    onChange={e => {
                                        setExpDate(e.target.value.split("-"));
                                    }}
                                />
                            </Form.Item>
                            <Button type="primary" shape="round" htmlType="submit" loading={loading} disabled={submitted}>
                                Submit
                            </Button>
                        </Form>
                        <div style={{ position: 'relative', marginTop: '18px' }}>
                            <p style={{ fontSize: '12px', padding: '0px' }}>Powered By:</p>
                            <img src="circle_logo.png" style={{ width: '25%' }} />
                        </div>
                    </Card>
                </Col>
                <Col xs={0} sm={0} md={1} lg={2} xl={5}></Col>
            </Row>
        </div>
    );
}