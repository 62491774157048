import { useState } from "react";
import usePoller from "./Poller";
import axios from "axios";

export default function useGasPrice(targetNetwork, localProvider, speed) {
  const [gasPrice, setGasPrice] = useState();
  const loadGasPrice = async () => {
    if (targetNetwork.gasPrice) {
      setGasPrice(targetNetwork.gasPrice);
    } else {
      // axios (DEPRICATED)
      //   .get("https://ethgasstation.info/json/ethgasAPI.json")
      //   .then(response => {
      //     const newGasPrice = response.data[speed || "fast"] * 100000000;
      //     if (newGasPrice !== gasPrice) {
      //       setGasPrice(newGasPrice);
      //     }
      //   })
      //   .catch(error => console.log(error));

      // THIS MAY NOT BE FORMATTED FOR THE RIGHT TYPE WHEN USED IN TRANSACTOR.JS
      let gas = await localProvider.getGasPrice(); // 3
      setGasPrice(gas.toString());
      // console.log(gas.toString()); // wei, div by 10**9 to get gwei
    }
  };

  usePoller(loadGasPrice, 39999);
  return gasPrice;
}
