import React from 'react';
import { Handle } from 'react-flow-renderer';
import { Row, Col } from 'antd';
import "../../App.css"

const CustomNode = ({ data, isConnectable, sourcePosition, targetPosition }) => {

    return (
        <div className='node-default'>
            <div style={{ position: 'absolute', top: 12, right: 4, marginLeft: '10px' }}>
                {data.verified === "Yes" ? <img src="https://img.icons8.com/ios/50/00D803/verified-account-filled.png" alt="verified" style={{ width: '65%' }} /> : <img src="https://img.icons8.com/ios/50/FF0707/unverified-account-filled.png" alt="not verified" style={{ width: '65%' }} />}
                {/* {data.active === "Yes" ? <img src="https://img.icons8.com/ios/50/00D803/checked-checkbox.png" alt="active" style={{ width: '35%' }} /> : <img src="https://img.icons8.com/ios/50/FF0707/unchecked-checkbox.png" alt="not active" style={{ width: '35%' }} />} */}
            </div>
            <Handle
                type="target"
                position={targetPosition}
                id="a"
                style={{ background: '#B2B4B8', width: '12px', height: '12px' }}
                isConnectable={isConnectable}
            />

            <div style={{ position: 'relative', width: '70%' }}>
                <h3 style={{ textAlign: 'left', color: "#fff", textOverflow: 'clip' }} >{data.label}</h3>
            </div>

            <div>
                <div style={{ marginTop: 3, textAlign: 'left', fontWeight: 'bold' }}>{data.country}</div>
                <div style={{ textAlign: 'left' }}>{data.product}</div>
            </div>

            <Handle
                type="source"
                position={sourcePosition}
                id="b"
                style={{ background: '#B2B4B8', width: '12px', height: '12px' }}
                isConnectable={isConnectable}
            />
        </div >

    );
};

export default CustomNode;