import React, { useState } from "react";
import { Button, Row, Col, Card, List, Modal, Input, Popover } from "antd";
import { PictureOutlined, QrcodeOutlined } from '@ant-design/icons';

import { useCommodityPrices } from "../hooks";
import { getFromIPFS } from "../helpers/ipfs";

var QRCode = require('qrcode.react');

export default function YourClaims({
    mainnetProvider,
    writeContracts,
    yourClaimData,
    marketplaceCommodityData,
    marketplaceDueDiligenceData,
    tx
}) {

    const { goldPrice, TantalumCobaltTinMicaPrice } = useCommodityPrices(mainnetProvider);
    const gPrice = Math.round((parseInt(goldPrice) / 10 ** 8) * 100) / 100 / 31;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isQRModalVisible, setIsQRModalVisible] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [modalBarcodeId, setModalBarcodeId] = useState()
    const [isListingModalVisible, setIsListingModalVisible] = useState(false);
    const [listingModalPrice, setListingModalPrice] = useState();

    const showModal = async (propHash, barcodeID) => {
        setIsModalVisible(true);
        setModalBarcodeId(barcodeID)
        // getFromIPFS, returns metadata content
        const content = await getFromIPFS(propHash)
        const startIndex = content._bufs.toString('utf8').indexOf("Image: https://ipfs.io/ipfs/") // cant use 'Product Image' because DD tokens say 'Site Image'
        if (startIndex > 0) {
            const imageIPFS = content._bufs.toString('utf8').slice(startIndex)
            const hash = imageIPFS.split("/ipfs/")[1].slice(0, 46)
            const infuraURL = "https://adaptiveresources.infura-ipfs.io/ipfs/" + hash
            setImageUrl(infuraURL)
        } else {
            const imageIPFS = "https://adaptiveresources.infura-ipfs.io/ipfs/QmSJ5kSkEX3i1CBkYKbt6iCfT9Av8rBVRZ2R7xxg6hFADo"
            setImageUrl(imageIPFS)
        }
    };

    const showHandleListingModal = async () => {
        setIsListingModalVisible(true);
    };

    const handleCancel = (e) => {
        e.stopPropagation();

        setIsModalVisible(false);
        setListingModalPrice()
        setIsListingModalVisible(false);
    };

    const handleCancelQR = (e) => {
        e.stopPropagation();

        setIsQRModalVisible(false);
    };

    const downloadQR = (barcodeId) => {
        const canvas = document.getElementById('QRCode');
        if (canvas) {
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = "claim_token_" + barcodeId + ".png";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    function cardColor(material) {
        if (material === "Gold (Au)" || material === 'Gold') {
            return ("#fff9c4")
        }
        if (material === "Cobalt (Co)") {
            return ("#e6f2ff")
        }
        if (material === "Tantalum (Ta)" || material === 'Tantalum') {
            return ("#ffedd9")
        }
        if (material === "Tin (Sn)") {
            return ("#abb8c3")
        }
        if (material === "Mica") {
            return ("#8ed1fc")
        }
    };

    function getElementImage(material) {
        if (material === "Gold (Au)" || material === 'Gold') {
            return ("Au.png")
        }
        if (material === "Cobalt (Co)") {
            return ("Co.png")
        }
        if (material === "Tantalum (Ta)" || material === 'Tantalum') {
            return ("Ta.png")
        }
        if (material === "Tin (Sn)") {
            return ("Sn.png")
        }
        if (material === "Mica") {
            return ("Mica.png")
        }
    };

    return (
        <div style={{ minHeight: '100vh' }}>
            <Row justify="left" style={{ marginLeft: '2vw' }}>
                <h1 style={{ color: "#203864", marginTop: '30px' }}>Your Claim Tokens</h1>
            </Row>
            <Row justify="left" style={{ marginBottom: '15px', marginLeft: '2vw' }}>
                <Col>
                    <p style={{ fontSize: '99%', textAlign: 'left' }}>
                        Review your portfolio of claim tokens. When a claim is ready to be shipped downstream, sell the Claim Token representing that shippment on the Marketplace.
                    </p>
                </Col>
            </Row>
            <div >
                <Row justify='center'>
                    <Col span={23}>
                        <List
                            grid={{
                                gutter: 8,
                                xs: 1,
                                sm: 2,
                                md: 3,
                                lg: 4,
                                xl: 5,
                                xxl: 5,
                            }}
                            dataSource={yourClaimData}
                            renderItem={(item) => {
                                const id = item.id;
                                const recommendedPrice = Math.round((item.mass / 100) * (item.purity / 10000) * gPrice * 0.1 * 100) / 100;
                                const listedCommodity = marketplaceCommodityData.filter((listed) => listed.tokenId === item.tokenId);
                                const listedDD = marketplaceDueDiligenceData.filter((listed) => listed.tokenId === item.tokenId);
                                return (
                                    <List.Item key={id + "_" + item.tokenURI + "_" + item[3]}>
                                        <Card
                                            hoverable
                                            style={{ borderRadius: "36px", background: cardColor(item.material) }}
                                            onClick={async (e) => {
                                                e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                window.open("https://adaptiveresources.infura-ipfs.io/ipfs/" + item.tokenURI)
                                            }}
                                        >
                                            <div style={{ marginBottom: "16px", fontSize: '14px' }}>
                                                <h4 style={{ fontWeight: 'bolder' }}>{item.country.split(",")[0]}</h4>
                                                <div style={{ textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: 'nowrap', paddingLeft: '10%', paddingRight: '10%' }}>
                                                    {item.barcodeId}
                                                </div>
                                            </div>
                                            <img src={getElementImage(item.material)} style={{ width: "75%" }} alt={"Claim Image"} />
                                            <div >
                                                <Modal open={isModalVisible} onCancel={handleCancel} forceRender={true} footer={null} cancelButtonProps={{ style: { display: 'none' } }}>
                                                    <h3 style={{ fontSize: '95%' }}>Product ID: </h3>
                                                    <p>{modalBarcodeId}</p>
                                                    <img src={imageUrl} style={{ width: "75%" }} />
                                                </Modal>
                                            </div>
                                            <Row justify="center" style={{ marginTop: '18px' }}>
                                                <Col flex={1}>
                                                    <Button
                                                        onClick={async (e) => {
                                                            e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                            await showModal(item.tokenURI, item.barcodeId)
                                                        }}
                                                        style={{ backgroundColor: cardColor(item.material), border: cardColor(item.material) }}
                                                        icon={<PictureOutlined />}
                                                    >
                                                    </Button>
                                                </Col>
                                                <Col flex={1}>
                                                    {listedCommodity[0] || listedDD[0] ?
                                                        <Popover content={`Token is listed for sale`}>
                                                            <Button
                                                                disabled
                                                                shape="round"
                                                                type="primary"
                                                                style={{ background: "#ff7875", borderColor: "#ff7875" }}
                                                            >
                                                                Sell
                                                            </Button>
                                                        </Popover>
                                                        :
                                                        <Button onClick={async (e) => {
                                                            e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                            showHandleListingModal();
                                                        }}
                                                            shape="round"
                                                            type="primary"
                                                            style={{ background: "#ff7875", borderColor: "#ff7875" }}
                                                        >
                                                            Sell
                                                        </Button>}
                                                </Col>
                                                <Col flex={1}>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setIsQRModalVisible(true);
                                                        }}
                                                        style={{ backgroundColor: cardColor(item.material), border: cardColor(item.material) }}
                                                        icon={<QrcodeOutlined />}
                                                    >
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <div >
                                                <Modal open={isListingModalVisible} onCancel={handleCancel} footer={null} cancelButtonProps={{ style: { display: 'none' } }}>
                                                    <h3 style={{ marginBottom: '24px' }}>Create Listing</h3>
                                                    <p>Enter the price you would like to sell this token for.</p>
                                                    <div style={{ marginTop: '24px' }}>
                                                        <p ><span style={{ fontWeight: 'bold' }}>Mass:</span> {item.mass / 100}</p>
                                                        <p ><span style={{ fontWeight: 'bold' }}>Purity:</span> {(item.purity) / 100}%</p>
                                                        <p ><span style={{ fontWeight: 'bold' }}>Total Claim Value:</span> ${Math.round(recommendedPrice * 10 * 100) / 100} USD</p>
                                                        <p ><span style={{ fontWeight: 'bold' }}>Estimated Mineral Value:</span> ${recommendedPrice} USD</p>
                                                    </div>
                                                    <div >
                                                        $ <Input
                                                            placeholder={recommendedPrice}
                                                            required
                                                            onChange={(e) => {
                                                                e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                                setListingModalPrice(e.target.value)
                                                            }}
                                                            onSelect={(e) => {
                                                                e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                            }}
                                                            on
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                            }}
                                                            style={{ width: '30%' }}
                                                        />
                                                        USD
                                                    </div>
                                                    <Button
                                                        onClick={async (e) => {
                                                            e.stopPropagation(); // prevent card from being clicked when button is clicked
                                                            // approve the marketplace to transfer the token
                                                            await tx(writeContracts.ARClaimToken.approve(
                                                                writeContracts.ARMarketplace.address,
                                                                item.tokenId
                                                            ));
                                                            // create the listing
                                                            await tx(writeContracts.ARMarketplace.createListing(
                                                                parseInt(id),
                                                                listingModalPrice ? (listingModalPrice * 10 ** 6) : (recommendedPrice * 10 ** 6)
                                                            ));
                                                            setIsListingModalVisible(false);
                                                        }}
                                                        disabled={!listingModalPrice}
                                                        shape="round"
                                                        type="primary"
                                                        style={{ marginTop: '24px' }}
                                                    >
                                                        List Token
                                                    </Button>
                                                </Modal>
                                                <Modal open={isQRModalVisible} onCancel={handleCancelQR} footer={null} cancelButtonProps={{ style: { display: 'none' } }}>
                                                    <h4 style={{ marginBottom: '18px' }}>Claim ID</h4>
                                                    <p style={{ marginBottom: '18px' }}>Scan or download this Claim Token's unique identifier.</p>
                                                    <Row justify={'center'}>
                                                        <div >
                                                            <QRCode id={"QRCode"} value={"https://adaptiveresources.infura-ipfs.io/ipfs/" + item.tokenURI} size={425} imageSettings={{ src: "Brand_Images/Adaptive_Logo_Blue.png", excavate: true, width: 187.5, height: 51.25 }} level="H" />
                                                        </div>
                                                        <div style={{ marginTop: '24px', fontSize: '20px' }}>
                                                            <a onClick={(e) => {
                                                                e.stopPropagation();
                                                                downloadQR(item.barcodeId)
                                                            }}>Download</a>
                                                        </div>
                                                    </Row>
                                                </Modal>
                                            </div>
                                        </Card>
                                    </List.Item>
                                )
                            }}
                        />
                    </Col>
                </Row>
            </div >
        </div >
    );
}