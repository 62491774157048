import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { getBezierPath, getEdgeCenter } from 'react-flow-renderer';
import '../../App.jsx';

const foreignObjectSize = 166;

export default function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    label
}) {

    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const [edit, setEdit] = useState(false)

    const [text, setText] = useState('')
    const [color, setColor] = useState('grey')
    const [display, setDisplay] = useState('notdisplayed');

    useEffect(() => {
        if (edit == true) setDisplay("displayed");
        else setDisplay("notdisplayed");
    }, [edit])

    const onEdgeClick = () => {
        console.log(edit)
        console.log(display)
        setEdit((edit) => !edit);
    };

    function handleSubmit(event) {
        event.preventDefault();
        setText(text)
        setColor(color);
    }

    const showButton = e => {
        setDisplay("displayed");
    };

    const hideButton = e => {
        e.preventDefault();
        setDisplay("notdisplayed");
    };

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
                label={label}
            />

            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
                onMouseEnter={e => showButton(e)}
                onMouseLeave={e => hideButton(e)}
            >

                {(edit) ?
                    (display == "notdisplayed") ?
                        (text.length > 0) ?
                            <div className='edge-label'>{text}</div>
                            :
                            <button className={display} id="edgebutton" onClick={(event) => onEdgeClick(event, id)}>  </button>
                        :
                        <div className='edge-label'>
                            <form onSubmit={handleSubmit}>
                                <Input style={{ width: '90%' }} value={text} onChange={(event) => setText(event.target.value)} />
                            </form>
                        </div>
                    :
                    <button className={display} id="edgebutton" onClick={(event) => onEdgeClick(event, id)}>  </button>
                }
            </foreignObject>
        </>
    );
}