import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { DownloadOutlined, PictureOutlined, ApartmentOutlined, DownCircleOutlined } from '@ant-design/icons';
import '../../App.jsx';
import { Tooltip, Modal, List } from 'antd';

function ButtonBar({
    onLayout,
    expandAll,
    collapseAll,
    clear,
    removeToolsFromCanvas,
    downloadCSV,
    formattedCSV,
    downloadScreenshot,
    firebaseUserData,
    formatData,
    selectedFileName,
    setSelectedFileName
}) {

    const [modalVisible, setModalVisible] = useState(false);

    return (
        <div className='sidebar-trace-right' style={{ display: removeToolsFromCanvas }}>
            <div >
                {/* File/ Data Uploader */}
                {/* NO LONGER USED */}
                {/* <input
                    className='flow-button'
                    id="upload-capture-input-file"
                    type="file"
                    name="file"
                    onChange={changeHandler}
                    accept=".csv"
                /> */}
                {/* Select file from Firebase */}
                <Tooltip title="Select user data">
                    <button
                        className='flow-button'
                        style={{ width: '67%' }}
                        id="upload-capture-select-file"
                        onClick={() => setModalVisible(true)}
                    ><DownCircleOutlined style={{ marginRight: '10%' }} />
                        {selectedFileName ? selectedFileName : 'Select File'}
                    </button>
                </Tooltip>
                {/* CSV Download */}
                <Tooltip title="Download CSV">
                    <CSVLink
                        data={formattedCSV}
                        asyncOnClick={true}
                        onClick={(event, done) => {
                            downloadCSV();
                            done();
                        }}
                        filename={'source-flow-data.csv'}
                        target="_blank"
                    >
                        <button className='flow-button'><DownloadOutlined /></button>
                    </CSVLink>
                </Tooltip>
                {/* PDF Screenshot */}
                <Tooltip title="Download screenshot. Press F8 to hide buttons." placement='left'>
                    <button className='flow-button' onClick={downloadScreenshot}><PictureOutlined /></button>
                </Tooltip>
            </div>

            {/* Bottom Button Row */}
            <div style={{ textAlign: 'right' }}>
                <button className='flow-button' onClick={() => clear()}>Clear</button>
                <button className='flow-button' onClick={() => expandAll()}>Expand</button>
                <button className='flow-button' onClick={() => collapseAll()}>Collapse</button>
                <button className='flow-button' onClick={() => { onLayout('TB'); }}><ApartmentOutlined style={{ fontSize: '14px' }} /></button>
                <button className='flow-button' onClick={() => { onLayout('LR'); }}><ApartmentOutlined className='rotated-icon-90' /></button>
            </div>

            <Modal open={modalVisible} onCancel={() => setModalVisible(false)} footer={null}>
                <div >
                    <h3 >Your Source Flow Data</h3>
                    <List
                        itemLayout="horizontal"
                        dataSource={firebaseUserData}
                        pagination={{
                            onChange: page => {
                                console.log(page);
                            },
                            pageSize: 5,
                        }}
                        renderItem={item => (
                            <List.Item
                                actions={[<a onClick={() => {
                                    formatData(item.data);
                                    setModalVisible(false);
                                    setSelectedFileName(item.name);
                                }}>Select</a>]}
                            >
                                <List.Item.Meta
                                    title={item.name}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            </Modal>
        </div>

    );
}

export default ButtonBar;
