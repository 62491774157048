import React, { useState } from "react";
import { Form, Input, Card, Row, Col, Button, Select } from "antd";
import { addBusinessNonUSIBanSupportedBankAccount } from "./ApiWrapper";

const { Option } = Select;

export default function CircleUserBankIBANSignUp({ }) {

    const [form] = Form.useForm();

    // state
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(); // must be at least a first and last name or Circle will reject
    const [city, setCity] = useState();
    const [country, setCountry] = useState(); // must be 2-letter country code
    const [streetAddr1, setStreetAddr1] = useState(); // line1
    const [streetAddr2, setStreetAddr2] = useState(); // line2 
    const [district, setDistrict] = useState(); // must be 2-letter abbreiviation
    const [postalCode, setPostalCode] = useState();
    const [IBAN, setIBAN] = useState();
    const [acctNum, setAcctNum] = useState();
    const [bankCity, setBankCity] = useState();
    const [submitted, setSubmitted] = useState(false);

    // *** FORM ITEMS ****

    // Handle Failed
    const onFinishFailed = async (errorInfo) => {
        console.log("Failed", form.getFieldsError)
    }

    // *** HANDLE SUBMIT ***

    const handleSubmit = async () => {
        setLoading(true);

        // create user IBAN business wire account
        const response = await addBusinessNonUSIBanSupportedBankAccount(
            acctNum,
            IBAN,
            name,
            city,
            country,
            streetAddr1,
            district,
            postalCode,
            bankCity
        );

        console.log(response);

        setLoading(false);
        setSubmitted(true);
    };

    return (
        <div style={{ margin: '30px', minHeight: "100vh" }}>
            <h1 style={{ color: "#203864" }}>Adaptive Wallet Setup - IBAN Supported</h1>
            <Row justify="center">
                <div className="create-claim-form" style={{ margin: '16px', fontSize: '16px' }}>
                    More <a href="https://www.circle.com/en/merchant-payments" target='blank'>information</a> on Circle/ USDC crypto payments
                </div>
            </Row>
            <Row justify="center">
                <Col xs={0} sm={0} md={1} lg={2} xl={5}></Col>
                <Col xs={24} sm={24} md={22} lg={18} xl={14}>
                    <Card
                        hoverable={true}
                        style={{ background: '#d9d9d950', borderRadius: '18px' }}
                    >
                        <Form
                            className="create-claim-form"
                            form={form}
                            layout="horizontal"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={handleSubmit}
                            onFinishFailed={onFinishFailed}
                            requiredMark={false}
                            labelAlign="right"
                            labelWrap
                            colon={false}
                        >
                            <Form.Item
                                label={'Name'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Input disabled={submitted} placeholder="Full Name" onChange={(e) => { setName(e.target.value) }} />
                            </Form.Item>
                            <Form.Item
                                label={'Address'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Input disabled={submitted} placeholder="Address" onChange={(e) => { setStreetAddr1(e.target.value) }} />
                                <Input disabled={submitted} placeholder="Apt or unit" onChange={(e) => { setStreetAddr2(e.target.value) }} />
                            </Form.Item>
                            <Form.Item
                                label={'Country'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Select disabled={submitted} placeholder="Country" onChange={(e) => { setCountry(e) }}>
                                    <Option value="AR">Argentina</Option>
                                    <Option value="AU">Australia</Option>
                                    <Option value="AT">Austria</Option>
                                    <Option value="BE">Belgium</Option>
                                    <Option value="BR">Brazil</Option>
                                    <Option value="CA">Canada</Option>
                                    <Option value="CL">Chile</Option>
                                    <Option value="CN">China</Option>
                                    <Option value="CO">Colombia</Option>
                                    <Option value="HR">Croatia</Option>
                                    <Option value="CZ">Czech Republic</Option>
                                    <Option value="CD">Democratic Rebuplic of the Congo</Option>
                                    <Option value="DK">Denmark</Option>
                                    <Option value="EG">Egypt</Option>
                                    <Option value="FR">France</Option>
                                    <Option value="DE">Germany</Option>
                                    <Option value="IN">India</Option>
                                    <Option value="ID">Indonesia</Option>
                                    <Option value="IT">Italy</Option>
                                    <Option value="JP">Japan</Option>
                                    <Option value="KE">Kenya</Option>
                                    <Option value="MX">Mexico</Option>
                                    <Option value="NG">Nigeria</Option>
                                    <Option value="PK">Pakistan</Option>
                                    <Option value="PE">Peru</Option>
                                    <Option value="PH">Philippines</Option>
                                    <Option value="PL">Poland</Option>
                                    <Option value="RU">Russia</Option>
                                    <Option value="RW">Rwanda</Option>
                                    <Option value="SA">Saudi Arabia</Option>
                                    <Option value="ZA">South Africa</Option>
                                    <Option value="KR">South Korea</Option>
                                    <Option value="ES">Spain</Option>
                                    <Option value="SE">Sweden</Option>
                                    <Option value="CH">Switzerland</Option>
                                    <Option value="TW">Taiwan</Option>
                                    <Option value="TH">Thailand</Option>
                                    <Option value="TZ">Tanzania</Option>
                                    <Option value="TR">Turkey</Option>
                                    <Option value="UG">Uganda</Option>
                                    <Option value="GB">United Kingdom</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={'City'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Input disabled={submitted} placeholder="City" onChange={(e) => { setCity(e.target.value) }} />
                            </Form.Item>
                            <Form.Item
                                label={'District'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Input disabled={submitted} placeholder="State / Province / District" onChange={(e) => { setDistrict(e.target.value) }} />
                            </Form.Item>
                            <Form.Item
                                label={'Postal Code'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Input disabled={submitted} placeholder="Postal / ZIP code" onChange={(e) => { setPostalCode(e.target.value) }} />
                            </Form.Item>
                            <Form.Item
                                label={'Bank City'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Input disabled={submitted} placeholder="Bank City" onChange={(e) => { setBankCity(e.target.value) }} />
                            </Form.Item>
                            <Form.Item
                                label={'IBAN Number'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Input disabled={submitted} placeholder="IBAN" onChange={(e) => { setIBAN(e.target.value) }} />
                            </Form.Item>
                            <Form.Item
                                label={'Account Number'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Input disabled={submitted} placeholder="Account Number" onChange={(e) => { setAcctNum(e.target.value) }} />
                            </Form.Item>
                            <Button
                                type="primary"
                                shape="round"
                                htmlType="submit"
                                loading={loading}
                                disabled={submitted}
                            >
                                Submit
                            </Button>
                        </Form>
                        <div style={{ position: 'relative', marginTop: '24px' }}>
                            <p style={{ fontSize: '12px', padding: '0px' }}>Powered By:</p>
                            <img src="circle_logo.png" style={{ width: '25%' }} />
                        </div>
                    </Card>
                </Col>
                <Col xs={0} sm={0} md={1} lg={2} xl={5}></Col>
            </Row>
        </div>
    );
}