//Infura
export const INFURA_ID = process.env.REACT_APP_INFURA_API_KEY;
//Etherscan
export const ETHERSCAN_KEY = process.env.REACT_APP_ETHERSCAN_KEY;
// BLOCKNATIVE ID FOR Notify.js:
export const BLOCKNATIVE_DAPPID = process.env.REACT_APP_BLOCKNATIVE_DAPPID;
// The Graph API
export const GraphAPI = "https://api.studio.thegraph.com/query/2957/adaptive-mainnet/v0.1.3";

//Networks
export const NETWORK = (chainId) => {
    for (let n in NETWORKS) {
        if (NETWORKS[n].chainId === chainId) {
            return NETWORKS[n]
        }
    }
}

export const NETWORKS = {
    localhost: {
        name: "localhost",
        color: '#666666',
        chainId: 31337,
        blockExplorer: '',
        rpcUrl: "http://" + window.location.hostname + ":8545",
    },
    mainnet: {
        name: "mainnet",
        color: '#ff8b9e',
        chainId: 1,
        rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
        blockExplorer: "https://etherscan.io/",
    },
    kovan: {
        name: "kovan",
        color: '#7003DD',
        chainId: 42,
        rpcUrl: `https://kovan.infura.io/v3/${INFURA_ID}`,
        blockExplorer: "https://kovan.etherscan.io/",
        faucet: "https://gitter.im/kovan-testnet/faucet",
    },
    rinkeby: {
        name: "rinkeby",
        color: '#e0d068',
        chainId: 4,
        rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
        faucet: "https://faucet.rinkeby.io/",
        blockExplorer: "https://rinkeby.etherscan.io/",
    },
    ropsten: {
        name: "ropsten",
        color: '#F60D09',
        chainId: 3,
        faucet: "https://faucet.ropsten.be/",
        blockExplorer: "https://ropsten.etherscan.io/",
        rpcUrl: `https://ropsten.infura.io/v3/${INFURA_ID}`,
    },
    goerli: {
        name: "goerli",
        color: '#0975F6',
        chainId: 5,
        faucet: "https://goerli-faucet.slock.it/",
        blockExplorer: "https://goerli.etherscan.io/",
        rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`,
    },
    gnosis: {
        name: "gnosis chain",
        color: '#48a9a6',
        chainId: 100,
        price: 1,
        gasPrice: 1000000000,
        rpcUrl: "https://gnosischain-rpc.gateway.pokt.network", // "https://rpc.gnosischain.com/" 
        faucet: "https://xdai-faucet.top/",
        blockExplorer: "https://blockscout.com/poa/xdai/",
    }
}
