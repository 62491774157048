import { useState, useEffect } from "react";
import { Row, Col, Select, Input, Button, Card, Spin, Typography, Form } from "antd";
import { Address, AddressInput } from "../components";
import { getFromIPFS } from "../helpers/ipfs";

const DEBUG = false;

const { Option } = Select;
const { Text } = Typography;

export default function Validate({
    address,
    useContractReader,
    writeContracts,
    readContracts,
    blockExplorer,
    mainnetProvider,
    tx
}) {
    // Attestation States
    const [tokenIdToValidate, setTokenIdToValidate] = useState()
    const [attestationContents, setAttestationContents] = useState()
    // Tool states
    const [URI, setLookupURI] = useState();
    const [lookupBarcodeID, newLookupBarcodeID] = useState();
    const [tokenID, setTokenID] = useState();
    const [newOwnerProp, setNewOwnerProp] = useState();
    const [newTokenID, setNewTokenID] = useState();
    const [lookupAddress, setLookupAddress] = useState("");
    const [addressBalance, setAddressBalance] = useState();
    const [submitClicked, setSubmitClicked] = useState("none")

    // checks for most recent attestation by users address
    const myAttestation = useContractReader(readContracts, "ClaimToken", "attestations", [address], 1777);

    const asyncGetAttestation = async () => {
        let result = await getFromIPFS(myAttestation)
        setAttestationContents(result.toString())
    }
    //when submit is clicked use asynGetAttestation
    useEffect(async () => {
        if (myAttestation) asyncGetAttestation()
    }, [submitClicked])

    let attestationDisplay = ""
    if (myAttestation) {
        if (!attestationContents) {
            attestationDisplay = (
                <Spin />
            )
        } else {
            attestationDisplay = (
                <div>
                    <Address value={address} fontSize={24} /> <p style={{ color: "#1890ff", fontSize: "20px" }}>Attests to: </p>
                    <pre style={{ margin: 8, padding: 8, border: "1px solid #dddddd", backgroundColor: "#ededed" }}>
                        {attestationContents}
                    </pre>
                </div>
            )
        }
    }

    let spinner = ""
    if (submitClicked == "") {
        if (!attestationContents) {
            spinner = (
                <Spin />
            )
        } else {
            spinner = ""
        }
    }
    // pass claim hash to ipfs-claims tests
    async function handleValidateSubmit() {
        setSubmitClicked("");
        // Adaptive Automated Audit and Risk Protection Package
        //const data = await getClaimData(ipfsHashValidate);
        //const result = riskMitigation(data);
        //if (DEBUG) console.log(result); // pass = true, fail = false
        const tx1 = await tx(writeContracts.ARMarketplace.attest(tokenIdToValidate))
        if (tx1) {
            setSubmitClicked("block");
        } else {
            setSubmitClicked("none")
            alert("This claim token DOES NOT pass the Adaptive Claims risk mitigation testing. Risk mitigation testing must be passing in order to attest claim token by a 3rd party.")
        }
    }

    // Form Layout
    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    return (
        <div style={{ minHeight: '100vh', marginBottom: '30px' }}>
            <Row justify="left" style={{ marginLeft: '2vw' }}>
                <h1 style={{ color: "#203864", marginTop: '30px' }}>Claim Validation</h1>
            </Row>
            <Row justify="left" style={{ marginBottom: '15px', marginLeft: '2vw' }}>
                <Col>
                    <p style={{ fontSize: '99%', textAlign: 'left' }}>
                        Approved users may enter the Claim Token URI (Universal Resource Identifier) for a token they are validating. This users signs a transaction adding their 3rd party attestation to the public blockchain. For more information read <a href="https://adaptive-resources.gitbook.io/adaptive-resources-docs/frequently-asked-questions/annex-ii-risk-assessment" target='blank'>here</a>.
                    </p>
                </Col>
            </Row>
            <Row style={{ margin: '18px' }} justify='center'>
                <Col xs={23} sm={23} md={23} lg={18} xl={16}>
                    <Card style={{ background: '#d9d9d950', borderRadius: '18px' }}>
                        <Form
                            className="create-claim-form"
                            {...layout}
                            onFinish={handleValidateSubmit}
                        >
                            <Form.Item label="Assurance Agency" name="AA" wrapperCol={{ ...layout }}>
                                <Select
                                    placeholder='Select'
                                    onChange={async (value) => {
                                        let res = await readContracts.ARMarketplace.attestationWhitelist(address)
                                        if (address != value) {
                                            alert("You must be connected using a wallet from the selected auditing agency.")
                                        }
                                    }}
                                >
                                    <Option value="BSP">BSP</Option>
                                    <Option value="CRAFT">CRAFT</Option>
                                    <Option value="FairTrade">FairTrade</Option>
                                    <Option value="IMPACT">IMPACT</Option>
                                    <Option value="iTSCi">iTSCi</Option>
                                    <Option value="PACT">PACT</Option>
                                    <Option value="The Impact Facility">The Impact Facility</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label="Claim to Validate" name="CV" wrapperCol={{ ...layout }}>
                                <Input
                                    placeholder="Claim ID"
                                    onChange={(e) => {
                                        setTokenIdToValidate(e.target.value)
                                    }}
                                />
                            </Form.Item>

                            <Form.Item name="submit" wrapperCol={{ ...layout }} >
                                <Button
                                    shape="round"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Attest Claim
                                </Button>
                                {spinner ? (spinner) : ("")}
                            </Form.Item>
                        </Form>

                        <div style={{ padding: 16, textAlign: "left", display: submitClicked }}>
                            <Card title="Most Recently Validated Claim" style={{ background: '#d9d9d950' }}>
                                {attestationDisplay ? (attestationDisplay) : ("")}
                            </Card>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginTop: '12px' }}>
                <Col span={24}>
                    <Row justify="left" style={{ marginLeft: '2vw' }}>
                        <h4 style={{ color: "#203864", marginTop: '30px', fontSize: '24px' }}>Read Claim Data</h4>
                    </Row>
                    <Row justify="left" style={{ marginBottom: '15px', marginLeft: '2vw' }}>
                        <Col>
                            <p style={{ fontSize: '99%' }}>
                                Use the utilites below to read information from the Claim Token smart contract.
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ margin: '30px' }} justify='center' align='middle' gutter={[24, 24]}>
                <Col flex={1}>
                    <Card
                        className="create-claim-form"
                        style={{ background: '#d9d9d950', borderRadius: '18px' }}
                    >
                        <h4 style={{ color: "#455A64" }}>Claim Token Metadata</h4>
                        <Input type="text"
                            placeholder="Claim ID"
                            onChange={(e) => {
                                setNewTokenID(e.target.value)
                            }}
                        />
                        <Button
                            shape="round"
                            style={{ margin: '8px' }}
                            onClick={async () => {
                                try {
                                    const URI = await readContracts.ARClaimToken.tokenURI(newTokenID);
                                    setLookupURI(URI)
                                } catch (error) {
                                    console.log("ERROR: ", error)
                                }
                            }}
                        >
                            Search
                        </Button>
                        {URI ? (
                            <div >
                                <h6 style={{ color: "#434343" }}>
                                    Claim Token URI: <Text style={{ display: {}, color: "#1890ff" }} copyable={{ text: URI.split('ipfs/')[1] }}>
                                        {URI.split('ipfs/')[1]}
                                    </Text>
                                </h6>
                                <p style={{ margin: '16px', fontSize: '14px' }}>IPFS Metadata: <a href={URI} target='blank'>{URI}</a></p>
                                <p style={{ margin: '16px', fontSize: '14px' }}>Adaptive Metadata:
                                    <a href={"https://adaptiveresources.infura-ipfs.io/ipfs/" + URI.split('ipfs/')[1]} target="blank">
                                        {" https://adaptiveresources.infura-ipfs.io/ipfs/" + URI.split('ipfs/')[1]}
                                    </a>
                                </p>
                            </div>
                        ) : ("")}
                    </Card>
                </Col>
                <Col flex={1}>
                    <Card
                        className="create-claim-form"
                        style={{ background: '#d9d9d950', borderRadius: '18px' }}
                    >
                        <h4 style={{ color: "#455A64" }}>Account Claim Token Balance</h4>
                        <AddressInput
                            ensProvider={mainnetProvider}
                            placeholder="Enter Wallet Address"
                            value={lookupAddress}
                            onChange={(newVal) => {
                                setLookupAddress(newVal);
                            }}
                        />
                        <Button
                            shape="round"
                            style={{ margin: '8px' }}
                            onClick={async () => {
                                try {
                                    const result = await readContracts.ARClaimToken.balanceOf(lookupAddress);
                                    console.log("RESULT: ", result)
                                    setAddressBalance(parseInt(result));
                                } catch (error) {
                                    console.log("ERROR: ", error)
                                }
                            }}
                        >
                            Search
                        </Button>
                        {addressBalance != null ? (
                            <h6 >
                                Balance: <Text style={{ fontWeight: 'bold' }}>{addressBalance}</Text>
                            </h6>
                        ) : ("")}
                    </Card>
                </Col>
                <Col flex={1}>
                    <Card
                        className="create-claim-form"
                        style={{ background: '#d9d9d950', borderRadius: '18px' }}
                    >
                        <h4 style={{ color: "#455A64" }}>Claim Token Owner</h4>
                        <AddressInput
                            ensProvider={mainnetProvider}
                            placeholder="Claim Token URI"
                            value={lookupBarcodeID}
                            onChange={(newVal) => {
                                newLookupBarcodeID(newVal)
                            }}
                        />
                        <Button
                            shape="round"
                            style={{ margin: '8px' }}
                            onClick={async () => {
                                try {
                                    let result = await readContracts.ARClaimToken.barcodeToTokenId(lookupBarcodeID);
                                    console.log("RESULT: ", parseInt(result))
                                    setTokenID(parseInt(result))
                                    let owner = await readContracts.ARClaimToken.ownerOf(parseInt(result));
                                    console.log("OWNER: ", owner)
                                    setNewOwnerProp(owner);
                                } catch (error) {
                                    console.log("ERROR: ", error)
                                }
                            }}
                        >
                            Search
                        </Button>
                        {newOwnerProp ? (
                            <div style={{ color: "#434343", margin: '16px' }}>
                                Current Token Owner: <Address
                                    address={newOwnerProp}
                                    ensProvider={mainnetProvider}
                                    blockExplorer={blockExplorer}
                                    fontSize={16}
                                />
                                <div >
                                    <span >TokenID: <Text copyable={{ text: tokenID }}>{tokenID}</Text></span>
                                </div>
                            </div>
                        ) : ("")}
                    </Card>
                </Col>
            </Row>
        </div >
    );
}
