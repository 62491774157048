// List of all mining regions

export const mineRegions = [
    {
        value: "Burundi",
        label: "Burundi",
        children: [
            {
                value: " Bubanza",
                label: "Bubanza"
            },
            {
                value: " Bujumbura",
                label: "Bujumbura"
            },
            {
                value: " Bujumbura Mairie",
                label: "Bujumbura Mairie"
            },
            {
                value: " Bururi",
                label: "Bururi"
            },
            {
                value: " Cankuzo",
                label: "Cankuzo"
            },
            {
                value: " Cibitoke",
                label: "Cibitoke"
            },
            {
                value: " Gitega",
                label: "Gitega"
            },
            {
                value: " Karuzi",
                label: "Karuzi"
            },
            {
                value: " Kayanza",
                label: "Kayanza"
            },
            {
                value: " Kirundo",
                label: "Kirundo"
            },
            {
                value: " Makamba",
                label: "Makamba"
            },
            {
                value: " Muramvya",
                label: "Muramvya"
            },
            {
                value: " Muyinga",
                label: "Muyinga"
            },
            {
                value: " Mwaro",
                label: "Mwaro"
            },
            {
                value: " Ngozi",
                label: "Ngozi"
            },
            {
                value: " Rutana",
                label: "Rutana"
            },
            {
                value: " Ruyigi",
                label: "Ruyigi"
            }
        ]
    },
    {
        value: "Kenya",
        label: "Kenya",
        children: [
            {
                value: " Baringo",
                label: "Baringo"
            },
            {
                value: " Bomet",
                label: "Bomet"
            },
            {
                value: " Bungoma",
                label: "Bungoma"
            },
            {
                value: " Busia",
                label: "Busia"
            },
            {
                value: " Elgeyo-Marakwet",
                label: "Elgeyo-Marakwet"
            },
            {
                value: " Embu",
                label: "Embu"
            },
            {
                value: " Garissa",
                label: "Garissa"
            },
            {
                value: " Homa Bay",
                label: "Homa Bay"
            },
            {
                value: " Isiolo",
                label: "Isiolo"
            },
            {
                value: " Kajiado",
                label: "Kajiado"
            },
            {
                value: " Kakamega",
                label: "Kakamega"
            },
            {
                value: " Kericho",
                label: "Kericho"
            },
            {
                value: " Kiambu",
                label: "Kiambu"
            },
            {
                value: " Kilifi",
                label: "Kilifi"
            },
            {
                value: " Kirinyaga",
                label: "Kirinyaga"
            },
            {
                value: " Kisii",
                label: "Kisii"
            },
            {
                value: " Kisumu",
                label: "Kisumu"
            },
            {
                value: " Kitui",
                label: "Kitui"
            },
            {
                value: " Kwale",
                label: "Kwale"
            },
            {
                value: " Laikipia",
                label: "Laikipia"
            },
            {
                value: " Lamu",
                label: "Lamu"
            },
            {
                value: " Machakos",
                label: "Machakos"
            },
            {
                value: " Makueni",
                label: "Makueni"
            },
            {
                value: " Mandera",
                label: "Mandera"
            },
            {
                value: " Marsabit",
                label: "Marsabit"
            },
            {
                value: " Meru",
                label: "Meru"
            },
            {
                value: " Migori",
                label: "Migori"
            },
            {
                value: " Mombasa",
                label: "Mombasa"
            },
            {
                value: " Murang'a",
                label: "Murang'a"
            },
            {
                value: " Nairobi",
                label: "Nairobi"
            },
            {
                value: " Nakuru",
                label: "Nakuru"
            },
            {
                value: " Narok",
                label: "Narok"
            },
            {
                value: " Nyamira",
                label: "Nyamira"
            },
            {
                value: " Nyandarua",
                label: "Nyandarua"
            },
            {
                value: " Nyeri",
                label: "Nyeri"
            },
            {
                value: " Samburu",
                label: "Samburu"
            },
            {
                value: " Siaya",
                label: "Siaya"
            },
            {
                value: " Taita-Taveta",
                label: "Taita-Taveta"
            },
            {
                value: " Tana River",
                label: "Tana River"
            },
            {
                value: " Tharaka-Nithi",
                label: "Tharaka-Nithi"
            },
            {
                value: "trans Nzoia",
                label: "trans Nzoia"
            },
            {
                value: " Turkana",
                label: "Turkana"
            },
            {
                value: " Uasin Gishu",
                label: "Uasin Gishu"
            },
            {
                value: " Vihiga",
                label: "Vihiga"
            },
            {
                value: " Wajir",
                label: "Wajir"
            },
            {
                value: " West Pokot",
                label: "West Pokot"
            }
        ]
    },
    {
        value: "Dominican Republic of the Congo",
        label: "Dominican Republic of the Congo",
        children: [
            {
                value: " Kinshasa",
                label: "Kinshasa"
            },
            {
                value: " Kongo Central",
                label: "Kongo Central"
            },
            {
                value: " Kwango",
                label: "Nwango"
            },
            {
                value: " Nwilu",
                label: "Nwilu"
            },
            {
                value: " Nyenyezi ",
                label: "Nyenyezi "
            },
            {
                value: " Mai-Ndombe",
                label: "Mai-Ndombe"
            },
            {
                value: " Kasai-Oriental",
                label: "Kasai-Oriental"
            },
            {
                value: " Kasai-Central",
                label: "Kasai-Central"
            },
            {
                value: " Lomami",
                label: "Lomami"
            },
            {
                value: " Equateur",
                label: "Equateur"
            },
            {
                value: " Haut-Katanga",
                label: "Haut-Katanga"
            },
            {
                value: " Haut-Lomami",
                label: "Haut-Lomami"
            },
            {
                value: " Haut-Uele",
                label: "Haut-Uele"
            },
            {
                value: " Ituri",
                label: "Ituri"
            },
            {
                value: " Kasai-Occidental",
                label: "Kasai-Occidental"
            },
            {
                value: " Katanga",
                label: "Katanga"
            },
            {
                value: " Lualaba",
                label: "Lualaba"
            },
            {
                value: " Mongala",
                label: "Mongala"
            },
            {
                value: " Nord-Kivu",
                label: "Nord-Kivu"
            },
            {
                value: " Sud-Kivu",
                label: "Sud-Kivu"
            },
            {
                value: " Tanganika",
                label: "Tanganika"
            },
            {
                value: " Tshopo",
                label: "Tshopo"
            },
            {
                value: " Tshuapa",
                label: "Tshuapa"
            },
            {
                value: " Bas-Uele",
                label: "Bas-Uele"
            },
            {
                value: " Bandundu",
                label: "Bandundu"
            },
            {
                value: " Maniema",
                label: "Maniema"
            },
            {
                value: " Nord-Ubangi",
                label: "Nord-Ubangi"
            },
            {
                value: " Sud-Ubangi",
                label: "Sud-Ubangi"
            }
        ]
    },
    {
        value: "Rwanda",
        label: "Rwanda",
        children: [
            {
                value: ' Rubavu',
                label: "Rubavu"
            },
            {
                value: ' Nyamasheke',
                label: "Nyamasheke"
            },
            {
                value: ' Nyaruguru',
                label: "Nyaruguru"
            },
            {
                value: ' Nyagatare',
                label: "Nyagatare"
            },
            {
                value: ' Gicumbi',
                label: "Gicumbi"
            },
            {
                value: ' Rutsiro',
                label: "Rutsiro"
            },
            {
                value: ' Nyabihu',
                label: "Nyabihu"
            },
            {
                value: ' Ngororero',
                label: "Ngororero"
            },
            {
                value: ' Karongi',
                label: "Karongi"
            },
            {
                value: ' Bugesera',
                label: "Bugesera"
            },
            {
                value: ' Gakenke',
                label: "Gakenke"
            },
            {
                value: ' Rwamagana',
                label: "Rwamagana"
            },
            {
                value: ' Nyamagabe',
                label: "Nyamagabe"
            },
            {
                value: ' Huye',
                label: "Huye"
            },
            {
                value: ' Gisagara',
                label: "Gisagara"
            },
            {
                value: ' Gatsibo',
                label: "Gatsibo"
            },
            {
                value: ' Kayonza',
                label: "Kayonza"
            },
            {
                value: ' Kicukiro',
                label: "Kicukiro"
            },
            {
                value: ' Musanze',
                label: "Musanze"
            },
            {
                value: ' Rulindo',
                label: "Rulindo"
            },
            {
                value: ' Kamonyi',
                label: "Kamonyi"
            },
            {
                value: ' Muhanga',
                label: "Muhanga"
            },
            {
                value: ' Nyanza',
                label: "Nyanza"
            },
            {
                value: ' Ruhango',
                label: "Ruhango"
            },
            {
                value: ' Kirehe',
                label: "Kirehe"
            },
            {
                value: ' Rusizi',
                label: "Rusizi"
            },
            {
                value: ' Burera',
                label: "Burera"
            },
            {
                value: ' Ngoma',
                label: "Ngoma"
            }

        ],
    },
    {
        value: "Tanzania",
        label: "Tanzania",
        children: [
            {
                value: " Kigoma",
                label: "Kigoma"
            },
            {
                value: " Kagera",
                label: "Kagera"
            },
            {
                value: " Katavi",
                label: "Katavi"
            },
            {
                value: " Mara",
                label: "Mara"
            },
            {
                value: " Mbeya",
                label: "Mbeya"
            },
            {
                value: " Morogoro",
                label: "Morogoro"
            },
            {
                value: " Mtwara",
                label: "Mtwara"
            },
            {
                value: " Mwanza",
                label: "Mwanza"
            },
            {
                value: " Pwani",
                label: "Pwani"
            },
            {
                value: " Rukwa",
                label: "Rukwa"
            },
            {
                value: " Ruvuma",
                label: "Ruvuma"
            },
            {
                value: " Shinyanga",
                label: "Shinyanga"
            },
            {
                value: " Singida",
                label: "Singida"
            },
            {
                value: " Tabora",
                label: "Tabora"
            },
            {
                value: " Tanga",
                label: "Tanga"
            },
            {
                value: " Arusha",
                label: "Arusha"
            },
            {
                value: " Dodoma",
                label: "Dodoma"
            },
            {
                value: " Geita",
                label: "Geita"
            },
            {
                value: " Iringa",
                label: "Iringa"
            },
            {
                value: " Kilimanjaro",
                label: "Kilimanjaro"
            },
            {
                value: " Lindi",
                label: "Lindi"
            },
            {
                value: " Manyara",
                label: "Manyara"
            },
            {
                value: " Dar es Salaam",
                label: "Dar es Salaam"
            },
            {
                value: " Simiyu",
                label: "Simiyu"
            },
            {
                value: " Songwe",
                label: "Songwe"
            }
        ]
    },
    {
        value: "Uganda",
        label: "Uganda",
        children: [
            {
                value: ' Busia',
                label: 'Busia',
            },
            {
                value: ' Kampala',
                label: 'Kampala',
            },
            {
                value: ' Kassanda',
                label: 'Kassanda'
            },
            {
                value: ' Ntungamo',
                label: 'Ntungamo'
            },
            {
                value: ' Kiboga',
                label: 'Kiboga'
            },
            {
                value: ' Kamuli',
                label: 'Kamuli'
            },
            {
                value: ' Kamwenge',
                label: 'Kamwenge'
            },
            {
                value: ' Kanungu',
                label: 'Kanungu'
            },
            {
                value: ' Kapchorwa',
                label: 'Kapchorwa'
            },
            {
                value: ' Kasese',
                label: 'Kasese'
            },
            {
                value: ' Katakwi',
                label: 'Katakwi'
            },
            {
                value: ' Kayunga',
                label: 'Kayunga'
            },
            {
                value: ' Kibaale',
                label: 'Kibaale'
            },
            {
                value: ' Kibuku',
                label: 'Kibuku'
            },
            {
                value: ' Kiruhura',
                label: 'Kiruhura'
            },
            {
                value: ' Kisoro',
                label: 'Kisoro'
            },
            {
                value: ' Kitgum',
                label: 'Kitgum'
            },
            {
                value: ' Koboko',
                label: 'Koboko'
            },
            {
                value: ' Kole',
                label: 'Kole'
            },
            {
                value: ' Kotido',
                label: 'Kotido'
            },
            {
                value: ' Kumi',
                label: 'Kumi'
            },
            {
                value: ' Kyenjojo',
                label: 'Kyenjojo'
            },
            {
                value: ' Lira',
                label: 'Lira'
            },
            {
                value: ' Luwero',
                label: 'Luwero'
            },
            {
                value: ' Lyantonde',
                label: 'Lyantonde'
            },
            {
                value: ' Manafwa',
                label: 'Manafwa'
            },
            {
                value: ' Maracha',
                label: 'Maracha'
            },
            {
                value: ' Masaka',
                label: 'Masaka'
            },
            {
                value: ' Masindi',
                label: 'Masindi'
            },
            {
                value: ' Mayuge',
                label: 'Mayuge'
            },
            {
                value: ' Mbale',
                label: 'Mbale'
            },
            {
                value: ' Mbarara',
                label: 'Mbarara'
            },
            {
                value: ' Mitooma',
                label: 'Mitooma'
            },
            {
                value: ' Mityana',
                label: 'Mityana'
            },
            {
                value: ' Moroto',
                label: 'Moroto'
            },
            {
                value: ' Moyo',
                label: 'Moyo'
            },
            {
                value: ' Mpigi',
                label: 'Mpigi'
            },
            {
                value: ' Mubende',
                label: 'Mubende'
            },
            {
                value: ' Mukono',
                label: 'Mukono'
            },
            {
                value: ' Nakapiripirit',
                label: 'Nakapiripirit'
            },
            {
                value: ' Nakaseke',
                label: 'Nakaseke'
            },
            {
                value: ' Nakasongola',
                label: 'Nakasongola'
            },
            {
                value: ' Namayingo',
                label: 'Namayingo'
            },
            {
                value: ' Namutumba',
                label: 'Namutumba'
            },
            {
                value: ' Nebbi',
                label: 'Nebbi'
            },
            {
                value: ' Ngora',
                label: 'Ngora'
            },
            {
                value: ' Ntoroko',
                label: 'Ntoroko'
            },
            {
                value: ' Nwoya',
                label: 'Nwoya'
            },
            {
                value: ' Omoro',
                label: 'Omoro'
            },
            {
                value: ' Pader',
                label: 'Pader'
            },
            {
                value: ' Pallisa',
                label: 'Pallisa'
            },
            {
                value: ' Rakai',
                label: 'Rakai'
            },
            {
                value: ' Rubanda',
                label: 'Rubanda'
            },
            {
                value: ' Rubirizi',
                label: 'Rubirizi'
            },
            {
                value: ' Rukiga',
                label: 'Rukiga'
            },
            {
                value: ' Rukungiri',
                label: 'Rukungiri'
            },
            {
                value: ' Sembabule',
                label: 'Sembabule'
            },
            {
                value: ' Serere',
                label: 'Serere'
            },
            {
                value: ' Sheema',
                label: 'Sheema'
            },
            {
                value: ' Sironko',
                label: 'Sironko'
            },
            {
                value: ' Soroti',
                label: 'Soroti'
            },
            {
                value: ' Tororo',
                label: 'Tororo'
            },
            {
                value: ' Wakiso',
                label: 'Wakiso'
            },
            {
                value: ' Yumbe',
                label: 'Yumbe'
            },
            {
                value: ' Zombo',
                label: 'Zombo'
            }
        ],
    }
]