import { useMemo } from "react";

/*
  ~ What it does? ~

  Gets user provider

  ~ How can I use? ~

  const userProvider = useUserProvider(injectedProvider, localProvider);

  ~ Features ~

  - Specify the injected provider from Metamask
  - Specify the local provider
  - Usage examples:
    const address = useUserAddress(userProvider);
    const tx = Transactor(userProvider, gasPrice)
*/

// Debug toggle
const DEBUG = false;

const useUserProvider = (injectedProvider, localProvider) =>
  useMemo(() => {
    if (injectedProvider) {
      if (DEBUG) console.log("🦊 Using injected provider");
      return injectedProvider;
    }
    if (!localProvider) return undefined;

    let burnerConfig = {}

    if (window.location.pathname) {
      if (window.location.pathname.indexOf("/pk") >= 0) {
        let incomingPK = window.location.hash.replace("#", "")
        let rawPK
        if (incomingPK.length === 64 || incomingPK.length === 66) {
          if (DEBUG) console.log("🔑 Incoming Private Key...");
          rawPK = incomingPK
          burnerConfig.privateKey = rawPK
          window.history.pushState({}, "", "/");
          let currentPrivateKey = window.localStorage.getItem("metaPrivateKey");
          if (currentPrivateKey && currentPrivateKey !== rawPK) {
            window.localStorage.setItem("metaPrivateKey_backup" + Date.now(), currentPrivateKey);
          }
          window.localStorage.setItem("metaPrivateKey", rawPK);
        }
      }
    }

  }, [injectedProvider, localProvider]);

export default useUserProvider;
