import React from "react";
import Blockies from "react-blockies";
import { Typography, Skeleton } from "antd";
import { useLookupAddress } from "../hooks";

/*
  ~ What it does? ~

  Displays an address with a blockie image and option to copy address

  ~ How can I use? ~

  <Address
    address={address}
    ensProvider={mainnetProvider}
    blockExplorer={blockExplorer}
    fontSize={fontSize}
  />

  ~ Features ~

  - Provide ensProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. "0xa870" => "user.eth")
  - Provide blockExplorer={blockExplorer}, click on address and get the link
              (ex. by default "https://etherscan.io/" or for xdai "https://blockscout.com/poa/xdai/")
  - Provide fontSize={fontSize} to change the size of address text
*/

const { Text } = Typography;

export default function Address(props) {
  const { value, address, ensProvider, blockExplorer, fontSize, size, onChange, minimized } = props;

  const resolvedAddress = value || address;

  const ensName = useLookupAddress(ensProvider, resolvedAddress);

  if (!resolvedAddress) {
    return (
      <span>
        <Skeleton avatar paragraph={{ rows: 1 }} />
      </span>
    );
  }

  let displayAddress = resolvedAddress.substr(0, 6);

  if (ensName && ensName.indexOf("0x") < 0) {
    displayAddress = ensName;
  } else if (size === "short") {
    displayAddress += "..." + resolvedAddress.substr(-4);
  } else if (size === "long") {
    displayAddress = resolvedAddress;
  }

  const etherscanLink = `${blockExplorer || "https://etherscan.io/"}address/${resolvedAddress}`;

  const handleClick = (e) => {
    e.stopPropagation();
  };

  const text = (
    <Text editable={onChange ? { onChange: onChange } : null} copyable={{ text: resolvedAddress }}>
      <a target="_blank" href={etherscanLink} rel="noopener noreferrer" onClick={handleClick}>
        {displayAddress}
      </a>
    </Text>
  );

  return (
    <span>
      <span style={{ fontSize: fontSize ? fontSize : 28 }} onClick={handleClick}>
        {minimized ? (
          <a target="_blank" href={etherscanLink} rel="noopener noreferrer" onClick={handleClick}>
            <Blockies seed={resolvedAddress.toLowerCase()} size={8} scale={2} />
          </a>
        ) : (
          text
        )}
      </span>
    </span>
  );
};
