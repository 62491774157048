import { useCallback, useEffect, useState } from "react";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { formatEther } from "@ethersproject/units";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
  useExchangePrice,
  useContractReader,
  useContractLoader,
  useUserProvider,
  useGasPrice,
  useBalance,
  useOnBlock,
  useUserSigner,
} from "./hooks";
import { useUserAddress } from "eth-hooks";
import {
  Profile,
  PrivateRoute,
  Reset,
  UpdateUserProfile,
  NetworkDisplay,
  LoginAuth,
  UtilitiesButtons,
  AdaptiveMapBox,
} from "./components";
import { Transactor } from "./helpers";
import { Layout, Menu, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { menuItems } from "./helpers/menu";
import {
  CreateTokens,
  Validate,
  CommodityMarket,
  DueDiligenceMarket,
  BeneficiaryTable,
  SuppliersTable,
  SupplierProfile,
  YourClaims,
  AdaptiveTrace,
  SBGDashboard,
  CorrectiveActionForm,
  CorrectiveActionTable,
  CircleUserCCSignUp,
  CircleUserUSBankSignUp,
  CircleUserBankIBANSignUp,
  CircleUserBankNonIBANSignUp,
  BinusuUserSignUp,
} from "./views";
import { INFURA_ID, NETWORKS, GraphAPI } from "./constants";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import GET_TOKENINFO_QUERY from './apollo/queries.js'
import WalletLink from "walletlink";
import Portis from "@portis/web3";
import { auth } from "./components/auth/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getDatabase, ref, onValue, get, child } from "firebase/database";
import { getCards, getBankAccounts } from "./components/auth/ApiWrapper";

import './App.css';
import 'antd/dist/antd.css';
import 'graphiql/graphiql.min.css';

const { Sider, Content } = Layout;

//--------------------------DEBUG-----------------------//
//
const DEBUG = true;
//
//--------------------------NETWORK-----------------------//
//
//
// 
//
const targetNetwork = NETWORKS['mainnet'];
const NETWORKCHECK = true;
//
//
//
//--------------------------PROVIDER & WALLET SETUP--------------------------//
// Connecting...
if (DEBUG) console.log("📡 Connecting Initialized");

const mainnetInfura = new StaticJsonRpcProvider("https://mainnet.infura.io/v3/" + INFURA_ID)  // 1

const targetProviderRpcUrl = targetNetwork.rpcUrl; // 1
const blockExplorer = targetNetwork.blockExplorer; // 1
// As you deploy to other networks you can set REACT_APP_PROVIDER=https://dai.poa.network in packages/react-app/.env
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : targetProviderRpcUrl;
if (DEBUG) console.log("🏠 Connecting to provider:", localProviderUrlFromEnv);
// Set provider used for app
const localProvider = new StaticJsonRpcProvider(targetProviderRpcUrl); // 2

// Coinbase walletLink init
const walletLink = new WalletLink({
  appName: "coinbase",
});
// WalletLink provider
const walletLinkProvider = walletLink.makeWeb3Provider("https://mainnet.infura.io/v3/" + INFURA_ID, 1);
//Web3Modal helps connect external wallets:
const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  theme: "dark",
  providerOptions: {
    "custom-walletlink": {
      display: {
        logo: "https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0",
        name: "Coinbase",
        description: "Connect to Coinbase Wallet",
      },
      package: walletLinkProvider,
      connector: async (provider, _options) => {
        await provider.enable();
        return provider;
      },
    },
  },
});

//-------------------------- Apollo client --------------------------//
const client = new ApolloClient({
  uri: GraphAPI,
  cache: new InMemoryCache()
});

//-------------------------- Global State --------------------------//
let yourClaimData;
let marketplaceCommodityData;
let marketplaceDueDiligenceData;
let totalClaimsCreated = 0;
let totalClaimsForSale = 0;
let maxVal = 0;
let minVal = 0;
let totalClaimValue = 0;
let totals;

//-------------------------- Firebase DB --------------------------//
const dbRef = ref(getDatabase());


// ============================================================================================
// =========================================== APP ============================================
// ============================================================================================
function App(props) {
  // Firebase Auth
  //
  //
  const [user, loading] = useAuthState(auth);
  //
  //
  //

  //------------ Web3 Provider and Utils ------------
  // 
  //
  const mainnetProvider = mainnetInfura; // 3 Dont touch! mainnetInfura
  if (DEBUG) console.log("🌎 mainnetProvider", mainnetProvider);
  //
  //

  // Price of ETH on 🦄 Uniswap:
  const price = useExchangePrice(targetNetwork, mainnetProvider);

  // Use an injected provider from 🦊 Metamask, this is require to write to the blockchain
  const [injectedProvider, setInjectedProvider] = useState();

  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProvider = useUserProvider(injectedProvider, localProvider); // localProvider
  const address = useUserAddress(userProvider);

  // 📝 read from the blockchain
  const readContracts = useContractLoader(localProvider);
  if (DEBUG) console.log("📝 readContracts", readContracts);

  // 🔐 write transactions to your contract
  const writeContracts = useContractLoader(userProvider);
  if (DEBUG) console.log("🔐 writeContracts", writeContracts);

  // Keep track of a variable from a contract in local state
  const currentClaimBalance = useContractReader(readContracts, "ARClaimToken", "balanceOf", [address]);
  const yourClaimBalance = parseInt(currentClaimBalance);
  if (DEBUG) console.log("🤗 Your GC balance:", yourClaimBalance);

  // If you want to call a function on a new block instead of using a poller
  useOnBlock(mainnetProvider, () => {
    if (DEBUG) console.log(`⛓ A new mainnet block is here: ${mainnetProvider._lastBlockNumber}`);
  });

  //------------ Fetch Firebase User Data ------------
  const [userType, setUserType] = useState();
  const [dataView, setDataView] = useState();
  const [additionalRequirementsTrigger, setAdditionalRequirementsTrigger] = useState();
  const [createClaimBusinessActivityKYCTrigger, setCreateClaimBusinessActivityKYCTrigger] = useState();
  const [createClaimManagementKYCTrigger, setCreateClaimManagementKYCTrigger] = useState();
  const [createClaimResponsibleSupplyChainKYC, setCreateClaimResponsibleSupplyChainKYC] = useState();
  const [createClaimSignedAcknowledgementKYCTrigger, setCreateClaimSignedAcknowledgementKYCTrigger] = useState();
  const [beneficiaryNGOKYCTrigger, setBeneficiaryNGOKYCTrigger] = useState();
  const [buyerKYCTrigger, setBuyerKYCTrigger] = useState();
  const [primaryContactName, setPrimaryContactName] = useState();
  const [emailNotifications, setEmailNotifications] = useState();
  const [userCards, setUserCards] = useState();
  const [bankAccts, setBankAccts] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (loading) {
          return <h2 >Loading...</h2>;
        }
        if (!user) {
          return <Link to="/login"></Link>;
        }

        // Get user data from Firebase
        const snapshot = await get(child(dbRef, `users/${user.uid}`));
        if (snapshot.exists()) {
          const userData = snapshot.val();

          if (userData.newAccountTrigger) {
            return window.location.replace('https://signup.adaptiveresources.io/');
          }

          setUserType(userData.userType);
          setDataView(userData.dataKey);
          setAdditionalRequirementsTrigger(userData.additionalRequirementsTrigger);
          setCreateClaimBusinessActivityKYCTrigger(userData.createClaimBusinessActivityKYCTrigger);
          setCreateClaimManagementKYCTrigger(userData.createClaimManagementKYCTrigger);
          setCreateClaimResponsibleSupplyChainKYC(userData.createClaimResponsibleSupplyChainKYC);
          setCreateClaimSignedAcknowledgementKYCTrigger(userData.createClaimSignedAcknowledgementKYCTrigger);
          setBeneficiaryNGOKYCTrigger(userData.beneficiaryNGOKYCTrigger);
          setBuyerKYCTrigger(userData.buyerKYCTrigger);
          setPrimaryContactName(userData.primaryContactName);
          setEmailNotifications(userData.emailNotifications);
        } else {
          alert('No data available');
        }

        // Get user cards and bank accounts from Circle
        const [cards, bankAccounts] = await Promise.all([
          getCards(),
          getBankAccounts(),
        ]);
        setUserCards(cards);
        setBankAccts(bankAccounts);
      } catch (error) {
        alert(error);
      }
    };

    fetchData();

  }, [user, loading]);

  //-------------- Fetch Firebase Bene/ Supplier Data --------------
  // Data Consumers
  // 1. Bene/ Suppliers tables -> used to display data in the tables, separated by dataView
  // 2. Create Claim forms -> used to populate the dropdowns in the create claim forms, separated by dataView & if there is a wallet address & verified & approved treatment areas
  // 3. CAP forms -> used to populate the dropdowns in the CAP forms, separated by dataView & if there is a wallet address & verified & approved treatment areas

  const [supplierData, setSupplierData] = useState([]);
  const [beneficiaryData, setBeneficiaryData] = useState([]);

  useEffect(() => {
    const db = getDatabase();

    let supplierData = [];
    let beneficiaryData = [];

    onValue(ref(db, 'suppliers/index'), async (snapshot) => {
      snapshot.forEach(function (childSnapshot) {
        var item = childSnapshot.val();
        // all suppliers
        if (dataView === 'all' && item.eth_address !== "") {
          supplierData.push({
            label: item.name,
            value: item.eth_address,
            id: childSnapshot.key,
            data: item
          })
        }
        // only DRC gold suppliers
        else if (item.country === dataView && item.commodity === "Gold" && item.eth_address !== "") {
          supplierData.push({
            label: item.name,
            value: item.eth_address,
            id: childSnapshot.key,
            data: item
          })
        }
        // only east africa suppliers, this excludes DRC
        else if (dataView === 'east-africa' && item.country !== 'DRC' && item.eth_address !== "") {
          supplierData.push({
            label: item.name,
            value: item.eth_address,
            id: childSnapshot.key,
            data: item
          })
        }
      });
    });

    onValue(ref(db, 'beneficiaries/index'), async (snapshot) => {
      snapshot.forEach(function (childSnapshot) {
        var item = childSnapshot.val();
        // all beneficiaries
        if (dataView === 'all' && item.eth_address !== "") {
          beneficiaryData.push({
            label: item.name,
            value: item.eth_address,
            id: childSnapshot.key,
            data: item
          })
        }
        // only DRC gold beneficiaries
        else if (item.country === dataView && item.commodity === "Gold" && item.eth_address !== "") {
          beneficiaryData.push({
            label: item.name,
            value: item.eth_address,
            id: childSnapshot.key,
            data: item
          })
        }
        // only east africa beneficiaries, this excludes DRC
        else if (dataView === 'east-africa' && item.country !== 'DRC' && item.eth_address !== "") {
          beneficiaryData.push({
            label: item.name,
            value: item.eth_address,
            id: childSnapshot.key,
            data: item
          })
        }
      });
    });

    setSupplierData(supplierData);
    setBeneficiaryData(beneficiaryData);
  }, [user, loading, dataView]);

  //------------ The Graph & Dashboard Data Parsing ------------
  // const [data, setData] = useState([]);
  const data = [];

  // ** your claims
  function createClaimsData(data) {
    yourClaimData = data.claimTokens.filter(item => (item.owner === address.toLowerCase()));
    totalClaimsCreated = data.claimTokens.length;
  }

  // ** commodity marketplace claim tokens
  // The only thing which separates the commodity tokens from the due diligence tokens is the
  // mass and purity fields. If mass and purity are 0, then it is a due diligence token.
  async function createCommodityData(data) {
    // check if commodity token
    marketplaceCommodityData = data.claimTokens.filter(item => (item.mass !== "0" && item.purity !== "0"));
    // check if in listings array
    marketplaceCommodityData = data.listings.filter(listing => (marketplaceCommodityData.some(token => token.tokenId === listing.tokenId)));
    // filter out inactive listings
    marketplaceCommodityData = marketplaceCommodityData.filter(item => (item.active === true));
    // add to total claims for sale
    if (marketplaceCommodityData.length > 0) {
      totalClaimsForSale = marketplaceCommodityData.length;
    }
  }

  // ** due diligence marketplace tokens
  async function createDueDiligenceData(data) {
    // check if due diligence token
    marketplaceDueDiligenceData = data.claimTokens.filter(item => (item.mass === "0" && item.purity === "0"));
    // check if in listings array
    marketplaceDueDiligenceData = data.listings.filter(listing => (marketplaceDueDiligenceData.some(token => token.tokenId === listing.tokenId)));
    // filter out inactive listings
    marketplaceDueDiligenceData = marketplaceDueDiligenceData.filter(item => (item.active === true));
    // add to total claims for sale
    if (marketplaceDueDiligenceData.length > 0) {
      totalClaimsForSale += marketplaceDueDiligenceData.length;
    }
  }

  // dashboard - claims 
  function createClaimsDashData() {
    // total value on marketplace
    if (marketplaceCommodityData[0]) {
      // dashboard stats
      let totalVal = 0;
      let maxClaimVal = marketplaceCommodityData[0].price / 100;
      let minClaimVal = marketplaceCommodityData[0].price / 100;
      for (let i = 0; i < marketplaceCommodityData.length; i++) {
        totalVal = totalVal + (marketplaceCommodityData[i].price / 100);
        if (marketplaceCommodityData[i].value > maxClaimVal) {
          maxClaimVal = marketplaceCommodityData[i];
        }
        if (minClaimVal > marketplaceCommodityData[i].value) {
          minClaimVal = marketplaceCommodityData[i];
        }
      }
      maxVal = maxClaimVal;
      minVal = minClaimVal;
      totalClaimValue = totalVal;
    }
  }

  // dashboard - claims - transfer data **(NEED TO FIX MAPPING FILE IN SUBGRAPH)**
  function createTransferData(data) {
    totals = data.totals;
  }

  // call The Graph API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await client.query({ query: gql(GET_TOKENINFO_QUERY) });

        if (data !== undefined) {
          createClaimsData(data);
          createCommodityData(data);
          createDueDiligenceData(data);
          createTransferData(data);
          createClaimsDashData(data);

          if (DEBUG) console.log("Claims data: ", data);
          if (DEBUG) console.log("Your tokens: ", yourClaimData);
          if (DEBUG) console.log("Commodity Marketplace data: ", marketplaceCommodityData);
          if (DEBUG) console.log("Due Diligence Marketplace data: ", marketplaceDueDiligenceData);
          if (DEBUG) console.log("Total Claims Created: ", totalClaimsCreated);
          if (DEBUG) console.log("Total Claims For Sale: ", totalClaimsForSale);
          if (DEBUG) console.log("Max Value Claim: ", maxVal);
          if (DEBUG) console.log("Min Value Claim: ", minVal);
          if (DEBUG) console.log("Value of all claims on Marketplace: ", totalClaimValue);
        }
      } catch (err) {
        console.log("Error fetching data: ", err);
      }
    };

    fetchData();
  });

  //------------ Hooks ------------
  // Use your injected provider from 🦊 Metamask 
  const userSigner = useUserSigner(injectedProvider, localProvider);

  // You can warn the user if you would like them to be on a specific network. See NetworkDisplay component below.
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId = userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  // 🔥 Get the price of Gas from ⛽️ EtherGasStation 
  const gasPrice = useGasPrice(targetNetwork, localProvider, "fast");

  // The transactor wraps transactions and provides notificiations for either success or failure of tx.
  const tx = Transactor(userSigner, gasPrice);

  // Get your accounts balance
  const yourLocalBalance = useBalance(localProvider, address);
  if (DEBUG) console.log("💵 yourLocalBalance", yourLocalBalance ? formatEther(yourLocalBalance) : "");

  // Plug in different providers to get your balance on different chains
  const yourMainnetBalance = useBalance(mainnetProvider, address);
  if (DEBUG) console.log("💵 yourMainnetBalance", yourMainnetBalance ? formatEther(yourMainnetBalance) : "");

  // Multiple provider support... Metamask, WalletConnect, etc...
  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
    // eslint-disable-next-line
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  // ------------------------------------------- Router/ Routes / Sidebar --------------------------------------------

  const [route, setRoute] = useState();
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="App">
      {loading || user && !dataView ? (
        <div style={{ margin: '50vh' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Router >
          <NetworkDisplay
            NETWORKCHECK={NETWORKCHECK}
            localChainId={localChainId}
            selectedChainId={selectedChainId}
            targetNetwork={targetNetwork}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
          />
          <Layout >
            <Sider
              width={'230px'}
              collapsedWidth={'70px'}
              breakpoint='lg'
              onCollapse={() => setCollapsed(!collapsed)}
              style={{ background: "#203864" }}
            >
              <div style={{ marginLeft: '16px', marginTop: '24px', textAlign: 'left' }}>
                {collapsed ? (
                  <Link to="." >
                    <img
                      className="logo-container-collapsed"
                      src="/Brand_Images/Adaptive_A_collapsed_menu.svg"
                      alt="Adaptive Logo"
                    />
                  </Link>
                ) : (
                  <Link to="." >
                    <img
                      className="logo-container"
                      src="/Brand_Images/Adaptive_Logo_Slash_LightGray.png"
                      alt="Adaptive Logo"
                    />
                  </Link>
                )}
              </div>

              <div style={{ marginTop: '30px' }}>
                <Link to={'/profile'}>
                  <button
                    className="user-profile-btn"
                    style={{ width: collapsed ? (50) : (210), textAlign: collapsed ? ('center') : ('left') }}
                  >
                    <UserOutlined style={{ paddingRight: collapsed ? (0) : (16), paddingLeft: collapsed ? (0) : (4) }} /> {primaryContactName && !collapsed ? (primaryContactName) : ("")}
                  </button>
                </Link>
              </div>

              <Menu
                mode="vertical"
                theme="dark"
                style={{ background: "#203864", marginRight: '8px', marginTop: '24px' }}
                items={menuItems}
                selectedKeys={[route]}
                onClick={({ key }) => {
                  setRoute(key);
                  // navigate to selected route
                  window.location.pathname = key;
                }}
              />
              <UtilitiesButtons mainnetProvider={mainnetProvider} collapsed={collapsed} />
            </Sider>

            <Content >
              <Routes>
                {/* 🌐 Public Routes*/}
                <Route exact path="/login" element={<LoginAuth
                  dbRef={dbRef}
                />} />
                <Route exact path="/reset" element={<Reset />} />
                <Route path="*" element={<div style={{ margin: '50vh' }}><h1>404 Page not found</h1><Link to={"/"}>Home Page</Link></div>} />

                {/* 🗝️ Private Routes */}
                <Route exact path='/' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path="/" element={<AdaptiveMapBox
                    collapsed={collapsed}
                    dataView={dataView}
                  />} />
                </Route>
                <Route exact path='/profile' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path="/profile" element={<Profile
                    userCards={userCards}
                    bankAccts={bankAccts}
                    user={user}
                    userType={userType}
                    primaryContactName={primaryContactName}
                    emailNotifications={emailNotifications}
                    setEmailNotifications={setEmailNotifications}
                    dataView={dataView}
                    additionalRequirementsTrigger={additionalRequirementsTrigger}
                    createClaimBusinessActivityKYCTrigger={createClaimBusinessActivityKYCTrigger}
                    createClaimManagementKYCTrigger={createClaimManagementKYCTrigger}
                    createClaimResponsibleSupplyChainKYC={createClaimResponsibleSupplyChainKYC}
                    createClaimSignedAcknowledgementKYCTrigger={createClaimSignedAcknowledgementKYCTrigger}
                    beneficiaryNGOKYCTrigger={beneficiaryNGOKYCTrigger}
                    buyerKYCTrigger={buyerKYCTrigger}
                    address={address}
                    localProvider={localProvider}
                    mainnetProvider={mainnetProvider}
                    price={price}
                    web3Modal={web3Modal}
                    loadWeb3Modal={loadWeb3Modal}
                    blockExplorer={blockExplorer}
                    logoutOfWeb3Modal={logoutOfWeb3Modal}
                  />} />
                </Route>
                <Route exact path='/circle-user-cc-signup' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator"]} />}>
                  <Route exact path="/circle-user-cc-signup" element={<CircleUserCCSignUp />} />
                </Route>
                <Route exact path='/circle-user-usbank-signup' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator"]} />}>
                  <Route exact path="/circle-user-usbank-signup" element={<CircleUserUSBankSignUp />} />
                </Route>
                <Route exact path='/circle-user-non-us-iban-signup' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator"]} />}>
                  <Route exact path="/circle-user-non-us-iban-signup" element={<CircleUserBankIBANSignUp />} />
                </Route>
                <Route exact path='/circle-user-non-us-non-iban-signup' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator"]} />}>
                  <Route exact path="/circle-user-non-us-non-iban-signup" element={<CircleUserBankNonIBANSignUp />} />
                </Route>
                <Route exact path='/binusu-user-signup' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator"]} />}>
                  <Route exact path="/binusu-user-signup" element={<BinusuUserSignUp />} />
                </Route>
                <Route exact path='/beneficiaries' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path="/beneficiaries" element={<BeneficiaryTable
                    beneficiaryData={beneficiaryData}
                    mainnetProvider={mainnetProvider}
                    blockExplorer={blockExplorer}
                  />} />
                </Route>
                <Route exact path='/suppliers/:adaptive_id' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path="/suppliers/:adaptive_id" element={<SuppliersTable
                    supplierData={supplierData}
                  />} />
                </Route>
                <Route exact path='/suppliers' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path="/suppliers" element={<SuppliersTable
                    supplierData={supplierData}
                  />} />
                </Route>
                <Route exact path='/supplier-profile/:adaptive_id' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path="/supplier-profile/:adaptive_id" element={<SupplierProfile
                  />} />
                </Route>
                {/* <Route exact path='/dashboardbeneficiaries' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                <Route exact path="/dashboardbeneficiaries" element={<DashboardBeneficiaries
                  address={address}
                  data={data}
                  totalActiveBenes={totalActiveBenes}
                  totalCooperatives={totalCooperatives}
                  mainnetProvider={mainnetProvider}
                />} />
              </Route>
              <Route exact path='/dashboardclaims' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                <Route exact path="/dashboardclaims" element={<DashboardClaims
                  address={address}
                  data={data}
                  totalClaimsCreated={totalClaimsCreated}
                  totalClaimValue={totalClaimValue}
                  totalClaimsForSale={totalClaimsForSale}
                  maxVal={maxVal}
                  minVal={minVal}
                  totals={totals}
                  mainnetProvider={mainnetProvider}
                />} />
              </Route> */}
                <Route exact path='/dashboard' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path="/dashboard" element={<SBGDashboard
                    address={address}
                    data={data}
                    totalClaimsCreated={totalClaimsCreated}
                    totalClaimValue={totalClaimValue}
                    totalClaimsForSale={totalClaimsForSale}
                    maxVal={maxVal}
                    minVal={minVal}
                    totals={totals}
                    mainnetProvider={mainnetProvider}
                  />} />
                </Route>
                <Route exact path='/update-user-profile' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path='/update-user-profile' element={<UpdateUserProfile />} />
                </Route>
                <Route exact path='/create' element={<PrivateRoute isAllowed={["admin", "creator"]} />}>
                  <Route exact path="/create" element={<CreateTokens
                    beneData={beneficiaryData}
                    coopData={supplierData}
                    mainnetProvider={mainnetProvider}
                    tx={tx}
                    writeContracts={writeContracts}
                    address={address}
                    userType={userType}
                    additionalRequirementsTrigger={additionalRequirementsTrigger}
                    createClaimBusinessActivityKYCTrigger={createClaimBusinessActivityKYCTrigger}
                    createClaimManagementKYCTrigger={createClaimManagementKYCTrigger}
                    createClaimResponsibleSupplyChainKYC={createClaimResponsibleSupplyChainKYC}
                    createClaimSignedAcknowledgementKYCTrigger={createClaimSignedAcknowledgementKYCTrigger}
                  />} />
                </Route>
                <Route exact path='/your-tokens' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path="/your-tokens" element={<YourClaims
                    mainnetProvider={mainnetProvider}
                    writeContracts={writeContracts}
                    yourClaimData={yourClaimData}
                    marketplaceCommodityData={marketplaceCommodityData}
                    marketplaceDueDiligenceData={marketplaceDueDiligenceData}
                    tx={tx}
                  />} />
                </Route>
                <Route exact path='/commodity-market' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path="/commodity-market" element={<CommodityMarket
                    userProvider={userProvider}
                    mainnetProvider={mainnetProvider}
                    marketplaceCommodityData={marketplaceCommodityData}
                    yourClaimData={yourClaimData}
                    writeContracts={writeContracts}
                    readContracts={readContracts}
                    tx={tx}
                    userType={userType}
                    additionalRequirementsTrigger={additionalRequirementsTrigger}
                    buyerKYCTrigger={buyerKYCTrigger}
                    userCards={userCards}
                    bankAccts={bankAccts}
                  />} />
                </Route>
                <Route exact path='/due-diligence-market' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path="/due-diligence-market" element={<DueDiligenceMarket
                    userProvider={userProvider}
                    mainnetProvider={mainnetProvider}
                    marketplaceDueDiligenceData={marketplaceDueDiligenceData}
                    yourClaimData={yourClaimData}
                    writeContracts={writeContracts}
                    readContracts={readContracts}
                    tx={tx}
                    userType={userType}
                    additionalRequirementsTrigger={additionalRequirementsTrigger}
                    buyerKYCTrigger={buyerKYCTrigger}
                    userCards={userCards}
                    bankAccts={bankAccts}
                  />} />
                </Route>
                <Route exact path='/validate' element={<PrivateRoute isAllowed={["admin", "auditor"]} />}>
                  <Route exact path="/validate" element={<Validate
                    address={address}
                    useContractReader={useContractReader}
                    writeContracts={writeContracts}
                    readContracts={readContracts}
                    blockExplorer={blockExplorer}
                    mainnetProvider={mainnetProvider}
                    tx={tx}
                    additionalRequirementsTrigger={additionalRequirementsTrigger}
                  />} />
                </Route>
                <Route exact path='/cap-edit/:name/:issue/:subIssue/:directive' element={<PrivateRoute isAllowed={["admin", "auditor"]} />}>
                  <Route exact path="/cap-edit/:name/:issue/:subIssue/:directive" element={<CorrectiveActionForm
                    beneData={beneficiaryData}
                    coopData={supplierData}
                  />} />
                </Route>
                <Route exact path='/cap-edit' element={<PrivateRoute isAllowed={["admin", "auditor", "creator"]} />}>
                  <Route exact path="/cap-edit" element={<CorrectiveActionForm
                    beneData={beneficiaryData}
                    coopData={supplierData}
                  />} />
                </Route>
                <Route exact path='/cap-report/:name' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path="/cap-report/:name" element={<CorrectiveActionTable
                    coopData={supplierData}
                    primaryContactName={primaryContactName}
                  />} />
                </Route>
                <Route exact path='/cap-report' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path="/cap-report" element={<CorrectiveActionTable
                    coopData={supplierData}
                    primaryContactName={primaryContactName}
                  />} />
                </Route>
                <Route exact path='/trace' element={<PrivateRoute isAllowed={["admin", "buyer", "beneficiary_ngo", "creator", "auditor"]} />}>
                  <Route exact path="/trace" element={<AdaptiveTrace
                    collapsed={collapsed}
                    dbRef={dbRef}
                  />} />
                </Route>
              </Routes>
            </Content>
          </Layout>
        </Router>
      )}
    </div >
  );
}

const logoutOfWeb3Modal = async () => {
  await web3Modal.clearCachedProvider();
  setTimeout(() => {
    window.location.reload();
  }, 1);
};

// ------------------------------- Reload After Wallet Network Switch -----------------------------
window.ethereum &&
  window.ethereum.on("chainChanged", chainId => {
    web3Modal.cachedProvider &&
      setTimeout(() => {
        window.location.reload();
      }, 1);
  });

export default App;