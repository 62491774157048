import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Table, Button, Tag, Input, Space, Card, Popover } from "antd";
import { CheckCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { ref as sRef, listAll, getStorage, getDownloadURL } from 'firebase/storage';
import Highlighter from 'react-highlight-words';
import "../App.css";

// Need to define a new component to use useState and useEffect hooks
const ExpandedRow = ({ record }) => {
    const [supplierRiskAssessment, setSupplierRiskAssessment] = useState([]);
    const [supplierKYC, setSupplierKYC] = useState([]);

    useEffect(() => {
        const storage = getStorage();
        const folderRefRA = sRef(storage, 'Risk Assessments/' + record.data.name + '/');
        const folderRefKYC = sRef(storage, 'KYC Check/' + record.data.name + '/');

        let isMounted = true;

        const fetchData = async () => {
            try {
                const [raRes, kycRes] = await Promise.all([
                    listAll(folderRefRA).then((res) => {
                        const promises = res.items.map((itemRef) => {
                            const pathReference = sRef(storage, itemRef.fullPath);
                            return getDownloadURL(pathReference);
                        });

                        return Promise.all(promises);
                    }),
                    listAll(folderRefKYC).then((res) => {
                        const promises = res.items.map((itemRef) => {
                            const pathReference = sRef(storage, itemRef.fullPath);
                            return getDownloadURL(pathReference);
                        });

                        return Promise.all(promises);
                    })
                ]);

                if (isMounted) {
                    setSupplierRiskAssessment(raRes);
                    setSupplierKYC(kycRes);
                }
            } catch (error) {
                console.log('Error getting files:', error);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [record.data.name]);

    const columns = [
        {
            title: 'Details',
            dataIndex: 'data',
            key: 'description',
            width: '50%',
            ellipsis: true,
            render: (text, record) => (
                record.description ? (
                    <p style={{ fontSize: '99%', wordBreak: "break-word", whiteSpace: 'normal' }}>{record.description}</p>
                ) : (
                    <p style={{ fontSize: '99%' }}>Not Approved</p>
                )
            ),
        },
        {
            title: 'Risk Assessment',
            dataIndex: 'data',
            key: 'risk_assessment',
            width: '25%',
            render: (text, record) => (
                supplierRiskAssessment.length > 0 ? (
                    <a href={supplierRiskAssessment[0]} target="_blank"><p style={{ fontSize: '99%' }}>Risk Assessment Information</p></a>
                ) : (
                    <p style={{ fontSize: '99%' }}>Not Available</p>
                )
            ),
        },
        {
            title: 'KYC Check',
            dataIndex: 'data',
            key: 'kyc',
            width: '25%',
            render: (text, record) => (
                supplierKYC.length > 0 ? (
                    <a href={supplierKYC[0]} target="_blank"><p style={{ fontSize: '99%' }}>KYC Screen Information</p></a>
                ) : (
                    <p style={{ fontSize: '99%' }}>Not Available</p>
                )
            ),
        }
    ];

    let data = [];
    data.push({
        key: '1',
        kyc: supplierKYC,
        risk_assessment: supplierRiskAssessment,
        description: record.data.description,
    });

    return <Table bordered columns={columns} dataSource={data} pagination={false} />;
};


export default function SuppliersTable({ supplierData }) {
    const navigate = useNavigate();

    const tableRef = useRef(null);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    function getColors(text) {
        if (text === 'Smelter') {
            return "sandybrown";
        }
        if (text == "Mine") {
            return "yellowgreen";
        }
        if (text == "Exporter") {
            return "violet";
        }
        if (text == "COOP") {
            return "lightsteelblue";
        }
        else {
            return "grey";
        }
    }

    const columns = [
        {
            title: 'Adaptive ID',
            dataIndex: 'id',
            key: 'adaptive_id',
            width: 'calc(5% - 8px)',
            ellipsis: true,
            sorter: (a, b) => a.id.localeCompare(b.id),
            ...getColumnSearchProps('adaptive_id'),
            render: (text) => <Row id={text} justify="center" align="middle"><span className="bene-table">{text}</span></Row>
        },
        {
            title: 'Name',
            dataIndex: ['data', 'name'],
            key: 'name',
            width: '35%',
            ellipsis: true,
            sorter: (a, b) => a.data.name.localeCompare(b.data.name),
            ...getColumnSearchProps('name'),
            render: (text, record) => (
                <Row justify="left" align="middle">
                    <div id={"#" + record.data.name} title={text}>
                        <span className="bene-table" style={{ fontWeight: 'bold' }}>
                            {text}
                        </span>
                    </div>

                    {record.data.verified === true ? <Popover content="KYC Verified"><CheckCircleOutlined style={{ color: 'blue', marginLeft: '6px' }} /></Popover> : ""}
                </Row>
            ),
        },
        {
            title: 'User Type',
            dataIndex: ['data', 'facility_type'],
            key: 'facility_type',
            width: 'calc(15% - 8px)',
            filters: [
                {
                    text: 'Smelter',
                    value: 'Smelter',
                },
                {
                    text: 'Mine',
                    value: 'Mine',
                },
                {
                    text: 'Exporter',
                    value: 'Exporter',
                },
                {
                    text: 'COOP',
                    value: 'COOP',
                },
            ],
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.data.facility_type.startsWith(value),
            ellipsis: true,
            render: (text) => (
                <Row justify="center" align="middle">
                    <div className="bene-table">
                        <Tag color={getColors(text)}>{text}</Tag>
                    </div>
                </Row>
            )
        },
        {
            title: 'Commodity',
            dataIndex: ['data', 'commodity'],
            key: 'commodity',
            width: 'calc(15% - 8px)',
            filters: [
                {
                    text: 'Gold',
                    value: 'Gold',
                },
                {
                    text: 'Cobalt',
                    value: 'Cobalt',
                },
                {
                    text: 'Nickel',
                    value: 'Nickel',
                },
                {
                    text: 'Tungsten',
                    value: 'Tungsten',
                },
                {
                    text: 'Tantalum',
                    value: 'Tantalum',
                },
                {
                    text: 'Tin',
                    value: 'Tin',
                },
                {
                    text: 'Multiple',
                    value: 'Multiple',
                },
            ],
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.data.commodity.startsWith(value),
            ellipsis: true,
            render: (text) => <Row justify="center" align="middle"><span className="bene-table">{text}</span></Row>
        },
        {
            title: "Country",
            dataIndex: ['data', "country"],
            key: "country",
            width: 'calc(15% - 8px)',
            filters: [
                {
                    text: 'Andorra',
                    value: 'Andorra',
                },
                {
                    text: 'Austrailia',
                    value: 'Austrailia',
                },
                {
                    text: 'Austria',
                    value: 'Austria',
                },
                {
                    text: 'Belgium',
                    value: 'Belgium',
                },
                {
                    text: 'Bolivia',
                    value: 'Bolivia',
                },
                {
                    text: 'Brazil',
                    value: 'Brazil',
                },
                {
                    text: 'Canada',
                    value: 'Canada',
                },
                {
                    text: 'Chile',
                    value: 'Chile',
                },
                {
                    text: 'China',
                    value: 'China',
                },
                {
                    text: 'Czechia',
                    value: 'Czechia',
                },
                {
                    text: 'DRC',
                    value: 'DRC',
                },
                {
                    text: 'Estonia',
                    value: 'Estonia',
                },
                {
                    text: 'Finland',
                    value: 'Finland',
                },
                {
                    text: 'France',
                    value: 'France',
                },
                {
                    text: 'Germany',
                    value: 'Germany',
                },
                {
                    text: 'India',
                    value: 'India',
                },
                {
                    text: 'Indonesia',
                    value: 'Indonesia',
                },
                {
                    text: 'Italy',
                    value: 'Italy',
                },
                {
                    text: 'Japan',
                    value: 'Japan',
                },
                {
                    text: 'Kazakhstan',
                    value: 'Kazakhstan',
                },
                {
                    text: 'South Korea',
                    value: 'South Korea',
                },
                {
                    text: 'Malaysia',
                    value: 'Malaysia',
                },
                {
                    text: 'Mexico',
                    value: 'Mexico',
                },
                {
                    text: 'Morroco',
                    value: 'Morroco',
                },
                {
                    text: 'Netherlands',
                    value: 'Netherlands',
                },
                {
                    text: 'North Macedonia',
                    value: 'North Macedonia',
                },
                {
                    text: 'Peru',
                    value: 'Peru',
                },
                {
                    text: 'Philippines',
                    value: 'Philippines',
                },
                {
                    text: 'Poland',
                    value: 'Poland',
                },
                {
                    text: 'Russia',
                    value: 'Russia',
                },
                {
                    text: 'Rwanda',
                    value: 'Rwanda',
                },
                {
                    text: 'Singapore',
                    value: 'Singapore',
                },
                {
                    text: 'South Africa',
                    value: 'South Africa',
                },
                {
                    text: 'Spain',
                    value: 'Spain',
                },
                {
                    text: 'Sweden',
                    value: 'Sweden',
                },
                {
                    text: 'Switzerland',
                    value: 'Switzerland',
                },
                {
                    text: 'Taiwan',
                    value: 'Taiwan',
                },
                {
                    text: 'Thailand',
                    value: 'Thailand',
                },
                {
                    text: 'Turkey',
                    value: 'Turkey',
                },
                {
                    text: 'U.A.E.',
                    value: 'U.A.E.',
                },
                {
                    text: 'Uganda',
                    value: 'Uganda',
                },
                {
                    text: 'United States',
                    value: 'United States',
                },
                {
                    text: 'Uzbekistan',
                    value: 'Uzbekistan',
                },
                {
                    text: 'Vietnam',
                    value: 'Vietnam',
                },
            ],
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.data.country.startsWith(value),
            ellipsis: true,
            render: text => <Row justify="center" align="middle"><span className="bene-table">{text}</span></Row>,
        }
    ];

    // render the ExpandedRow component
    const expandedRowRender = (record) => {
        return (
            <ExpandedRow record={record} />
        );
    };


    return (
        <div style={{ minHeight: '100vh', marginLeft: '12px', marginRight: '12px', marginBottom: '36px' }}>
            <Row justify="left" style={{ marginLeft: '2vw' }}>
                <h1 style={{ color: "#203864", marginTop: '30px' }}>Suppliers</h1>
            </Row>
            <Row justify="left" style={{ marginBottom: '15px', marginLeft: '2vw' }}>
                <Col>
                    <p style={{ fontSize: '98%', textAlign: 'left' }}>
                        Additional information about supplier organizations. Adaptive IDs come from organizations and coordinating bodies for the purpose of interoperability whenever possible.
                    </p>
                </Col>
            </Row>
            <Row justify='center'>
                <Col span={24}>
                    <Card ref={tableRef} hoverable={true} bodyStyle={{ padding: "0" }}>
                        <Table
                            bordered
                            dataSource={supplierData}
                            size="small"
                            columns={columns}
                            pagination={false}
                            scroll={{ x: '1250px' }}
                            expandable={{
                                expandedRowRender,
                                defaultExpandedRowKeys: ['0'],
                                rowExpandable: record => record.data.description !== "",
                                columnWidth: '50px',
                            }}
                            rowKey={"id"}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        navigate(`/supplier-profile/${record.id}`);
                                    }, // click row link to supplier page
                                };
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </div >
    );
}