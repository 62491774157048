import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import { getDatabase, ref, onValue } from "firebase/database";
import { ref as sRef, listAll, getStorage, getDownloadURL } from 'firebase/storage';
import { Col, Divider, Row, Tag, Tooltip, Spin, Button } from "antd";
import { CheckCircleOutlined, SelectOutlined, LeftOutlined } from '@ant-design/icons';

const DEBUG = false;

/*
 * This page is used to view details about a supplier.
*/
export default function SupplierProfile() {
    // state
    const [supplierData, setSupplierData] = useState([]);
    const [supplierLicences, setSupplierLicences] = useState([]);
    const [supplierRiskAssessment, setSupplierRiskAssessment] = useState([]);
    const [supplierKYCCheck, setSupplierKYCCheck] = useState([]);

    const { adaptive_id } = useParams();

    // Get the supplier data from the database using URL parameters
    useEffect(() => {
        if (adaptive_id != undefined) {
            const db = getDatabase();
            let licences;

            onValue(ref(db, `suppliers/index/${adaptive_id}`), (snapshot) => {
                if (DEBUG) console.log(snapshot.val());
                try {
                    licences = snapshot.val().licence_num.slice(',')
                } catch (error) {
                    licences = [snapshot.val().licence_num]
                }
                setSupplierData(snapshot.val());
                setSupplierLicences(licences)
            });
        }
    }, []);

    // Get the supplier Risk Assessment and KYC Check docs from the database using suppliers name
    useEffect(() => {
        const storage = getStorage();
        const folderRefRA = sRef(storage, 'Risk Assessments/' + supplierData.name + '/');
        const folderRefKYC = sRef(storage, 'KYC Check/' + supplierData.name + '/');

        listAll(folderRefRA).then((res) => {
            const promises = res.items.map((itemRef) => {
                const pathReference = sRef(storage, itemRef.fullPath);
                return getDownloadURL(pathReference);
            });

            Promise.all(promises)
                .then((urls) => {
                    setSupplierRiskAssessment(urls);
                })
                .catch((error) => {
                    console.log('Error getting download urls:', error);
                });
        })
            .catch((error) => {
                console.log('Error getting files:', error);
            });

        listAll(folderRefKYC).then((res) => {
            const promises = res.items.map((itemRef) => {
                const pathReference = sRef(storage, itemRef.fullPath);
                return getDownloadURL(pathReference);
            });

            Promise.all(promises)
                .then((urls) => {
                    setSupplierKYCCheck(urls);
                })
                .catch((error) => {
                    console.log('Error getting download urls:', error);
                });
        })
            .catch((error) => {
                console.log('Error getting files:', error);
            });
    }, [supplierData])

    function getFacilityColors(text) {
        if (text === 'Smelter') {
            return "orange";
        }
        if (text == "Mine") {
            return "lime";
        }
        if (text == "Exporter") {
            return "purple";
        }
        if (text == "COOP") {
            return "cyan";
        }
        else {
            return "grey";
        }
    }

    function getRiskColors(text) {
        if (text === 'High') {
            return "red";
        }
        if (text == "Medium") {
            return "orange";
        }
        if (text == "Low") {
            return "green";
        }
        else {
            return "grey";
        }
    }

    function getPriorityImpactAreas(priority_impact_areas) {
        try {
            priority_impact_areas = priority_impact_areas.split(",");
            let icons = [];

            for (let i = 0; i < priority_impact_areas.length; i++) {
                if (priority_impact_areas[i] === "Community Rights" && !icons.some(element => element.icon == "/icon1.png")) {
                    icons.push({ icon: "/icon1.png", description: "Community Rights" });
                }
                else if (priority_impact_areas[i] === "Economic Development" && !icons.some(element => element.icon == "/icon2.png")) {
                    icons.push({ icon: "/icon2.png", description: "Economic Development" });
                }
                else if (priority_impact_areas[i] === "Land Use and Biodiversity" && !icons.some(element => element.icon == "/icon3.png")) {
                    icons.push({ icon: "/icon3.png", description: "Land Use and Biodiversity" });
                }
                else if (priority_impact_areas[i] === "Water Use" && !icons.some(element => element.icon == "/icon4.png")) {
                    icons.push({ icon: "/icon4.png", description: "Water Use" });
                }
                else if (priority_impact_areas[i] === "Management Practices" && !icons.some(element => element.icon == "/icon6.png")) {
                    icons.push({ icon: "/icon6.png", description: "Management Practices" });
                }
                else if (priority_impact_areas[i] === "Production Improvement" && !icons.some(element => element.icon == "/icon8.png")) {
                    icons.push({ icon: "/icon8.png", description: "Production Improvement" });
                }
                else if (priority_impact_areas[i] === "Occupational Health & Safety" && !icons.some(element => element.icon == "/icon5.png")) {
                    icons.push({ icon: "/icon5.png", description: "Occupational Health & Safety" });
                }
                else if (priority_impact_areas[i] === "Serious Human Rights Abuses" && !icons.some(element => element.icon == "/icon7.png")) {
                    icons.push({ icon: "/icon7.png", description: "Serious Human Rights Abuses" });
                }
            }

            return icons.map((icon, j) => <Tooltip title={<span>{icon.description}</span>} key={icon.description}><span><img style={{ width: "33px", hieght: "33px" }} src={icon.icon} key={j} /></span></Tooltip>);
        } catch (error) {
            if (DEBUG) console.log(error);
        }
    }

    return (
        <div className="container">
            {supplierData.name !== undefined ? (
                <div style={{ margin: '30px' }}>
                    {/**
                     * Back button
                     */}
                    <Row justify={'left'}>
                        <Link to={'/suppliers'}>
                            <Button
                                shape="round"
                                style={{ backgroundColor: '#20386499', color: 'white', marginBottom: '20px' }}
                            >
                                <LeftOutlined /> Table
                            </Button>
                        </Link>
                    </Row>
                    <Row justify={'left'}>
                        <h1 style={{ color: "#203864" }}>{supplierData.name}{supplierData.verified === 'yes' ? <CheckCircleOutlined style={{ color: 'blue', marginLeft: '15px' }} /> : ""}</h1>
                    </Row>
                    <Row justify={'left'}>
                        <Tooltip title="Supplier Type" placement="bottom"><Tag color={getFacilityColors(supplierData.facility_type)} >{supplierData.facility_type}</Tag></Tooltip>
                        <Tooltip title="Risk level" placement="bottom"><Tag color={getRiskColors(supplierData.risk_level)} >{supplierData.risk_level}</Tag></Tooltip>
                    </Row>
                    <Row justify={'left'} style={{ marginTop: '30px' }}>
                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <Row justify={'left'} style={{ marginBottom: '6px' }}>
                                <h4 >General Information</h4>
                            </Row>
                            <Row justify={'left'}>
                                <ul style={{ textAlign: 'left' }}>
                                    <li><span style={{ fontWeight: 'bold' }}>Country: </span>{supplierData.country}</li>
                                    <li><span style={{ fontWeight: 'bold' }}>Commodity Type: </span>{supplierData.commodity}</li>
                                    <li><span style={{ fontWeight: 'bold' }}>Number of Workers: </span>{supplierData.num_workers}</li>
                                    <li><span style={{ fontWeight: 'bold' }}>Estimated Production: </span>{supplierData.est_production !== "N/A" ? supplierData.est_production + " /month" : "N/A"}</li>
                                    <li><span style={{ fontWeight: 'bold' }}>Licences: </span>{supplierLicences}</li>
                                </ul>
                            </Row>
                        </Col>
                        <Col xs={0} sm={0} md={2} lg={2} xl={2}>
                            <Divider type="vertical" style={{ height: '100%' }} />
                        </Col>
                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <Row justify={'left'} style={{ marginBottom: '6px' }}>
                                <h4 >Risk Data</h4>
                            </Row>
                            <Row >
                                <span style={{ fontWeight: 'bold' }}>Priority Impact Areas: </span>
                            </Row>
                            <Row >
                                <div >
                                    {supplierData.impact_areas != "N/A" ? getPriorityImpactAreas(supplierData.impact_areas) : "N/A"}
                                </div>
                            </Row>
                            <Row >
                                <div ><span style={{ fontWeight: 'bold' }}>Corrective Action Plan: </span>{supplierData.verified === true ? <Link to={`/cap-report/${adaptive_id}`}><SelectOutlined /></Link> : "N/A"}</div>
                            </Row>
                            <Row >
                                <div ><span style={{ fontWeight: 'bold' }}>Risk Assessment: </span>{supplierRiskAssessment.length > 0 ? <a href={supplierRiskAssessment[0]} target='_blank'><SelectOutlined /></a> : "N/A"}</div>
                            </Row>
                            <Row >
                                <div ><span style={{ fontWeight: 'bold' }}>KYC Screen: </span>{supplierKYCCheck.length > 0 ? <a href={supplierKYCCheck[0]} target='_blank'><SelectOutlined /></a> : "N/A"}</div>
                            </Row>
                        </Col>
                    </Row>
                    <Row justify={'left'} style={{ marginTop: '15px' }}>
                        <p style={{ color: "#203864", textAlign: 'left' }}>{supplierData.description}</p>
                    </Row>
                </div>
            ) : (<div style={{ textAlign: 'center', marginTop: '20px' }}><Spin size="large" style={{ marginTop: '50px' }} /></div>)
            }
        </div >
    );
}