import axios from "axios";
import { getAuth } from "firebase/auth";

export async function signIn(email, password) {
    const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/signIn`,
        {
            email: email,
            password: password,
        },
        {
            headers: {},
            validateStatus: _ => {
                return true;
            },
        },
    );
    return response.data;
}

export async function signUp(email, password) {
    const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/signUp`,
        {
            email: email,
            password: password,
        },
        {
            headers: {},
            validateStatus: _ => {
                return true;
            },
        },
    );
    return response.data;
}

// e.g. await addCard("John Doe", "Boston", "US", "MA", "ABCEDFG", "12345", 4757140000000001, 888, 1, 2023)
export async function addCard(name, city, country, district, line1, postalCode, card_number, cvv, exp_month, exp_year) {
    const access_token = await getAuth()
        .currentUser.getIdToken(true)
        .catch(_ => {
            return undefined;
        });
    if (!access_token) {
        return {
            ok: false,
            message: "User not logged in",
        };
    }

    const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/addCard`,
        {
            cardDetails: {
                number: card_number,
                cvv: cvv,
                expMonth: exp_month,
                expYear: exp_year,
            },
            billingDetails: {
                name,
                city,
                country,
                line1,
                district,
                postalCode,
            },
        },
        {
            headers: {
                "Access-Token": access_token,
            },
            validateStatus: _ => {
                return true;
            },
        },
    );

    return response.data;
}

export async function getCards() {
    const access_token = await getAuth()
        .currentUser.getIdToken(true)
        .catch(_ => {
            return undefined;
        });
    if (!access_token) {
        return {
            ok: false,
            message: "User not logged in",
        };
    }

    const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/getCards`,
        {},
        {
            headers: {
                "Access-Token": access_token,
            },
            validateStatus: _ => {
                return true;
            },
        },
    );

    return response.data;
}

// e.g. await createPayment("12.04", "USD", "4c480826-d3dd-4e3b-9635-e3960af49e61", 888)
export async function createPayment(amount, currency, card_id, cvv) {
    const access_token = await getAuth()
        .currentUser.getIdToken(true)
        .catch(_ => {
            return undefined;
        });
    if (!access_token) {
        return {
            ok: false,
            message: "User not logged in",
        };
    }

    const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/createPayment`,
        { amount, currency, card_id, cvv },
        {
            headers: {
                "Access-Token": access_token,
            },
            validateStatus: _ => {
                return true;
            },
        },
    );

    return response.data;
}

// e.g. await getPaymentStatus("897c1868-f78d-4622-bce9-07f76aaac64a")
export async function getPaymentStatus(payment_id) {
    const access_token = await getAuth()
        .currentUser.getIdToken(true)
        .catch(_ => {
            return undefined;
        });
    if (!access_token) {
        return {
            ok: false,
            message: "User not logged in",
        };
    }

    const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/getPaymentStatus`,
        {
            id: payment_id,
        },
        {
            headers: {
                "Access-Token": access_token,
            },
            validateStatus: _ => {
                return true;
            },
        },
    );

    return response.data;
}

async function _addBankAccount(is_business, accountNumber, iban, routingNumber, billingDetails, bankAddress) {
    const access_token = await getAuth()
        .currentUser.getIdToken(true)
        .catch(_ => {
            return undefined;
        });
    if (!access_token) {
        return {
            ok: false,
            message: "User not logged in",
        };
    }

    const pld = {
        is_biz: is_business,
        accountNumber,
        billingDetails,
        bankAddress,
    };

    if (iban) {
        pld.iban = iban;
    } else {
        pld.iban = "";
    }

    if (routingNumber) {
        pld.routingNumber = routingNumber;
    }

    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/addBankAccount`, pld, {
        headers: {
            "Access-Token": access_token,
        },
        validateStatus: _ => {
            return true;
        },
    });

    return response.data;
}

// e.g. await addRegularUSBankAccount(
//   "11111111111",
//   "121000248",
//   "John Doe",
//   "Boston",
//   "US",
//   "Abc Xyz",
//   "MA",
//   "12345",
// )
export async function addRegularUSBankAccount(
    accountNumber,
    routingNumber,
    name,
    city,
    country,
    line1,
    district,
    postalCode,
) {
    return await _addBankAccount(
        false,
        accountNumber,
        undefined,
        routingNumber,
        {
            name,
            city,
            country,
            line1,
            district,
            postalCode,
        },
        {
            country,
        },
    );
}

// e.g. same parameters as above one
export async function addBusinessUSBankAccount(
    accountNumber,
    routingNumber,
    name,
    city,
    country,
    line1,
    district,
    postalCode,
) {
    return await _addBankAccount(
        true,
        accountNumber,
        undefined,
        routingNumber,
        {
            name,
            city,
            country,
            line1,
            district,
            postalCode,
        },
        {
            country,
        },
    );
}

// e.g
// await addRegularNonUSIBanSupportedBankAccount(
//   "11111111111",
//   "DE31100400480532013000",
//   "John Doe",
//   "Boston",
//   "DE", // Germany
//   "Abc Xyz",
//   "MA",
//   "12345",
//   "Munich",
// )
export async function addRegularNonUSIBanSupportedBankAccount(
    accountNumber,
    iban,
    name,
    city,
    country,
    line1,
    district,
    postalCode,
    bankAddress_city,
) {
    return await _addBankAccount(
        false,
        accountNumber,
        iban,
        undefined,
        {
            name,
            city,
            country,
            line1,
            district,
            postalCode,
        },
        {
            city: bankAddress_city,
            country,
        },
    );
}

// same parameters as addRegularNonUSIBanSupportedBankAccount
export async function addBusinessNonUSIBanSupportedBankAccount(
    accountNumber,
    iban,
    name,
    city,
    country,
    line1,
    district,
    postalCode,
    bankAddress_city,
) {
    return await _addBankAccount(
        true,
        accountNumber,
        iban,
        undefined,
        {
            name,
            city,
            country,
            line1,
            district,
            postalCode,
        },
        {
            city: bankAddress_city,
            country,
        },
    );
}

// e.g.
// await addRegularNonUSIBanNotSupportedBankAccount(
//   "11111111111",
//   "DE31100400480532013000", // <- this is Iban, despite the api says it's for non iban it still requires iban
//   "121000248",
//   "John Doe",
//   "Boston",
//   "DE", // Germany
//   "Abc Xyz",
//   "MA",
//   "12345",
//   "SOME BANK IN GERMANY", // <- the difference from IBAN supported function is that Non iban function requires name of the bank
//   "Munich",
// )
export async function addRegularNonUSIBanNotSupportedBankAccount(
    accountNumber,
    iban,
    routingNumber,
    name,
    city,
    country,
    line1,
    district,
    postalCode,
    bank_name,
    bankAddress_city,
) {
    return _addBankAccount(
        false,
        accountNumber,
        iban,
        routingNumber,
        {
            name,
            city,
            country,
            line1,
            district,
            postalCode,
        },
        {
            bankName: bank_name,
            city: bankAddress_city,
            country,
        },
    );
}

// same as above one
export async function addBusinessNonUSIBanNotSupportedBankAccount(
    accountNumber,
    iban,
    routingNumber,
    name,
    city,
    country,
    line1,
    district,
    postalCode,
    bank_name,
    bankAddress_city,
) {
    return _addBankAccount(
        true,
        accountNumber,
        iban,
        routingNumber,
        {
            name,
            city,
            country,
            line1,
            district,
            postalCode,
        },
        {
            bankName: bank_name,
            city: bankAddress_city,
            country,
        },
    );
}

async function _getWireInstructions(is_business, bank_account_id) {
    const access_token = await getAuth()
        .currentUser.getIdToken(true)
        .catch(_ => {
            return undefined;
        });
    if (!access_token) {
        return {
            ok: false,
            message: "User not logged in",
        };
    }

    const pld = {
        is_biz: is_business,
        bank_account_id,
    };

    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/getWireInstructions`, pld, {
        headers: {
            "Access-Token": access_token,
        },
        validateStatus: _ => {
            return true;
        },
    });

    return response.data;
}

export async function getRegularWireInstructions(bank_account_id) {
    return _getWireInstructions(false, bank_account_id);
}

export async function getBusinessWireInstructions(bank_account_id) {
    return _getWireInstructions(true, bank_account_id);
}

export async function getBankAccounts() {
    const access_token = await getAuth()
        .currentUser.getIdToken(true)
        .catch(_ => {
            return undefined;
        });
    if (!access_token) {
        return {
            ok: false,
            message: "User not logged in",
        };
    }

    const plz = {
        is_biz: true,
    };

    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/getBankAccounts`, plz, {
        headers: {
            "Access-Token": access_token,
        },
        validateStatus: _ => {
            return true;
        },
    });

    return response.data;
}