import React from 'react';
import { FilterFilled } from '@ant-design/icons';
import { Select } from 'antd';
import "../../App.css";

const FlowFilters = ({
    filterDisplayed,
    setFilterDisplayed,
    setColorsFilterDisplayed,
    setAddFormDisplayed,
    t1NamesArray,
    handleT1FilterChange,
    removeToolsFromCanvas,
    showVerifiedEdges,
    //showActiveEdges,
    handleCustomXFilter,
    handleCustomYFilter,
    handleCustomZFilter,
    xFilterName,
    yFilterName,
    zFilterName
}) => {
    // hide/ unhide menu
    const reveal = () => filterDisplayed ? setFilterDisplayed(false) : setFilterDisplayed(true);
    // button
    return (
        <div className='sidebar-trace-left-2'>
            <button
                onClick={() => {
                    setColorsFilterDisplayed(false)
                    setAddFormDisplayed(false);
                    reveal();
                }}
                style={{ display: removeToolsFromCanvas }}
                className="flow-button"
                id="add-button"
            >
                <FilterFilled style={{ fontSize: '16px' }} />
            </button>
            {filterDisplayed ?
                <FilterForm
                    t1NamesArray={t1NamesArray}
                    handleT1FilterChange={handleT1FilterChange}
                    showVerifiedEdges={showVerifiedEdges}
                    //showActiveEdges={showActiveEdges}
                    handleCustomXFilter={handleCustomXFilter}
                    handleCustomYFilter={handleCustomYFilter}
                    handleCustomZFilter={handleCustomZFilter}
                    xFilterName={xFilterName}
                    yFilterName={yFilterName}
                    zFilterName={zFilterName}
                /> : null}
        </div>
    );
};

const FilterForm = ({ t1NamesArray, handleT1FilterChange, showVerifiedEdges, handleCustomXFilter, handleCustomYFilter, handleCustomZFilter, xFilterName, yFilterName, zFilterName }) => {

    // handle submit
    function handleSubmit(event) {
        event.preventDefault();
    }

    return (
        <div>
            <form className='filter-utils' onSubmit={handleSubmit}>
                <h5 style={{ marginBottom: '6px' }}>Filter Options</h5>
                <div >
                    <div style={{ padding: '2px', color: '#cfd8dc' }}>Tier 1 Filters</div>
                    <Select
                        mode="multiple"
                        style={{
                            width: '225px',
                            marginBottom: '10px'
                        }}
                        defaultValue={t1NamesArray}
                        options={t1NamesArray}
                        placeholder="Visible T1 Suppliers"
                        onChange={handleT1FilterChange}
                    />
                </div>
                <div className='filter-button-container'>
                    <div >
                        <div style={{ paddingTop: '6px', color: '#cfd8dc' }}>Edge Filters</div>
                        <button
                            className='filter-menu-button-va'
                            onClick={() => showVerifiedEdges()}
                        >
                            Verified
                        </button>
                        {/* <button
                            className='filter-menu-button-va'
                            onClick={() => showActiveEdges()}
                        >
                            Active
                        </button> */}
                    </div>
                </div >
                <div className='filter-button-container'>
                    <div style={{ paddingTop: '6px', color: '#cfd8dc' }}>Custom Node Filters</div>
                    <button
                        className='filter-menu-button-co'
                        onClick={() => handleCustomXFilter()}
                    >
                        {xFilterName}
                    </button><button
                        className='filter-menu-button-ni'
                        onClick={() => handleCustomYFilter()}
                    >
                        {yFilterName}
                    </button><button
                        className='filter-menu-button-li'
                        onClick={() => handleCustomZFilter()}
                    >
                        {zFilterName}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default FlowFilters;