export const riskTreatmentAreas = [
    {
        value: "Company Governance",
        label: "Company Governance",
        children: [
            {
                value: "Management Practices",
                label: "Management Practices",
                children: [
                    {
                        value: "Grievence Mechanism",
                        label: "Grievence Mechanism"
                    },
                    {
                        value: "Legal Compliance",
                        label: "Legal Compliance"
                    },
                    {
                        value: "Due Diligence Management System",
                        label: "Due Diligence Management System"
                    },

                ]
            },
            {
                value: "Business Practices",
                label: "Business Practices",
                children: [
                    {
                        value: "Extortion",
                        label: "Extortion"
                    },
                    {
                        value: "Bribery and Facilitation",
                        label: "Bribery and Facilitation"
                    },
                    {
                        value: "Money Laundering",
                        label: "Money Laundering"
                    },
                ]
            },
            {
                value: "Production Improvement",
                label: "Production Improvement",
                children: [
                    {
                        value: "Equipment to Improve Production",
                        label: "Equipment to Improve Production"
                    },
                    {
                        value: "Training to Improve Production",
                        label: "Training to Improve Production"
                    },
                ]
            },
        ]
    },
    {
        value: "Human and Workers Rights",
        label: "Human and Workers Rights",
        children: [
            {
                value: "Serious Human Rights Violations",
                label: "Serious Human Rights Violations",
                children: [
                    {
                        value: "Child Labor",
                        label: "Child Labor"
                    },
                    {
                        value: "Forced Labor",
                        label: "Forced Labor"
                    },
                    {
                        value: "Disciplinary Practices and Violence",
                        label: "Disciplinary Practices and Violence"
                    },
                    {
                        value: "Other Gross Human Rights Abuses",
                        label: "Other Gross Human Rights Abuses"
                    },
                    {
                        value: "Women's Rights",
                        label: "Women's Rights"
                    },
                    {
                        value: "Discrimination and Diversity",
                        label: "Discrimination and Diversity"
                    },
                    {
                        value: "Gender Based Violence",
                        label: "Gender Based Violence"
                    }
                ]
            },
            {
                value: "Occupational Health & Safety",
                label: "Occupational Health & Safety",
                children: [
                    {
                        value: "Workplace Hazards & Machinery",
                        label: "Workplace Hazards & Machinery"
                    },
                    {
                        value: "Personal Protective Equipment",
                        label: "Personal Protective Equipment"
                    },
                    {
                        value: "Medical Care",
                        label: "Medical Care"
                    },
                    {
                        value: "Reducing Mercury use",
                        label: "Reducing Mercury use"
                    },
                    {
                        value: "Reducing Cyanide Use",
                        label: "Reducing Cyanide Use"
                    },
                    {
                        value: "Miner Exposure to Radioactive or Harmful Emissions",
                        label: "Miner Exposure to Radioactive or Harmful Emissions"
                    }
                ]
            },
        ]
    },
    {
        value: "Societal Welfare",
        label: "Societal Welfare",
        children: [
            {
                value: "Community Rights",
                label: "Community Rights",
                children: [
                    {
                        value: "EITI Compliant Payment of Taxes",
                        label: "EITI Compliant Payment of Taxes"
                    },
                    {
                        value: "Security Forces and Conflict",
                        label: "Security Forces and Conflict"
                    },
                    {
                        value: "Residential & Indigenous Rights",
                        label: "Residential & Indigenous Rights"
                    },
                    {
                        value: "Education",
                        label: "Education"
                    },
                    {
                        value: "Social Protection and Social Voice",
                        label: "Social Protection and Social Voice"
                    },
                    {
                        value: "Access to Social Services",
                        label: "Access to Social Services"
                    }
                ],
            },
            {
                value: "Economic Development",
                label: "Economic Development",
                children: [
                    {
                        value: "Economic Infrastructure",
                        label: "Economic Infrastructure"
                    },
                    {
                        value: "Financial Inclusion and Access to Finance",
                        label: "Financial Inclusion and Access to Finance"
                    },
                    {
                        value: "Lighting",
                        label: "Lighting"
                    },
                    {
                        value: "Living Standards and Wages",
                        label: "Living Standards and Wages"
                    },
                    {
                        value: "Offgrid Solar or PV",
                        label: "Offgrid Solar or PV"
                    },
                ]
            }
        ]
    },
    {
        value: "Natural resources",
        label: "Natural resources",
        children: [
            {
                value: "Land Use & Biodiversity",
                label: "Land Use & Biodiversity",
                children: [
                    {
                        value: "Legally Protected Areas",
                        label: "Legally Protected Areas"
                    },
                    {
                        value: "Conflict With Agriculture Activities",
                        label: "Conflict With Agriculture Activities"
                    },
                ],
            },
            {
                value: "Water Use",
                label: "Water Use",
                children: [
                    {
                        value: "Mine Waste and Wastewater",
                        label: "Mine Waste and Wastewater"
                    },
                    {
                        value: "Water Management",
                        label: "Water Management"
                    },
                ],
            },
        ]
    },
];