import { useState, useEffect } from "react";
import { Form, Input, Card, Row, Col, Modal, Select, Button, Checkbox, Spin, Typography, Popover, Cascader, Tag, Alert } from "antd";
import { DynamicFieldCertificates } from ".";
import { getFromIPFS, addToIPFS } from "../helpers/ipfs";
import { mineRegions } from "./helpers/mineRegions"
import { BigNumber, ethers } from "ethers";
import { v4 as uuidv4 } from 'uuid';
import { UploadOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

var QRCode = require('qrcode.react');

const DEBUG = true;

const { Option } = Select;
const { Text } = Typography;

export default function DueDiligenceForm({
    beneData,
    tx,
    writeContracts,
    address,
    userType,
    additionalRequirementsTrigger,
    createClaimBusinessActivityKYCTrigger,
    createClaimManagementKYCTrigger,
    createClaimResponsibleSupplyChainKYC,
    createClaimSignedAcknowledgementKYCTrigger
}) {

    const [form] = Form.useForm();

    // ===== form state =====
    const [qrShow, setQRShow] = useState('none');
    const [claimType, setClaimType] = useState("Commodity");
    const [certificationType, setCertificationType] = useState({});
    const [certificationNumber, setCertificationNumber] = useState({});
    const [OECDTrue, setOECDTrue] = useState(false);
    const [region, setRegion] = useState("");
    const [siteName, setSiteName] = useState("");
    const [mineSiteDirective, setMineSiteDirective] = useState();
    const [rule, setRule] = useState("");


    // ========== IPFS Claim Form/ Image Upload ==========
    // Add claim form to IPFS
    const [sending, setSending] = useState();
    const [ipfsHash, setIpfsHash] = useState();
    const [ipfsContents, setIpfsContents] = useState();
    const [selectedFile, setSelectedFile] = useState("");
    const [imageSending, setImageSending] = useState(); //
    const [ipfsImageHash, setIpfsImageHash] = useState() //
    const [buffer, setBuffer] = useState()
    const [mintModalVisible, setMintModalVisible] = useState(false);

    useEffect(() => {
        const getIPFSData = async (hashToGet) => {
            // getFromIPFS, returns metadata content
            const content = await getFromIPFS(hashToGet)
            if (DEBUG) console.log(content._bufs.toString())
            const startIndex = content._bufs.toString('utf8').indexOf("https://")
            const endIndex = content._bufs.toString('utf8').indexOf("Unix")
            if (DEBUG) console.log(content._bufs.toString('utf8').slice(startIndex, endIndex))
            return content
        }

        const asyncGetFile = async () => {
            let result = await getIPFSData(ipfsHash)
            setIpfsContents(result.toString())
        }

        if (ipfsHash) asyncGetFile();
    }, [ipfsHash])

    const uploadImageHandler = async (event) => {
        setSelectedFile(event.target.files[0]);
        const file = event.target.files[0];
        const reader = new window.FileReader()
        reader.readAsArrayBuffer(file)
        reader.onloadend = () => {
            setBuffer(Buffer(reader.result))
        }
    }

    // IPFS modal display
    let ipfsDisplay = ""
    if (ipfsHash) {
        if (!ipfsContents) {
            ipfsDisplay = (
                <Spin />
            )
        } else {
            ipfsDisplay = (
                <pre style={{ margin: 8, padding: 8, border: "1px solid #dddddd", backgroundColor: "#ededed" }}>
                    {ipfsContents}
                </pre>
            )
        }
    }

    // Image Upload Rules for popover on 'upload' image button
    const imageRules = (
        <div >
            <div >
                <Text strong style={{ fontSize: '12px' }}> - Export Certificate (Such as ICGLR Certificate)</Text>
            </div>
            <div >
                <Text strong style={{ fontSize: '12px' }}> - Completed Adaptive Resources Export Form</Text>
            </div>
            <div >
                <Text strong style={{ fontSize: '12px' }}> - Approved Form from CSO/Assurance Partner with Shipment and Risk Assessment Details</Text>
            </div>
        </div>
    );

    // ======================== Filters for onBeneCheck and onCoopCheck ========================
    const [beneList, setBeneList] = useState([]);
    const [prodId, setProdId] = useState();
    const [bene1TreatmentAreas, setBene1TreatmentAreas] = useState([]);
    const [beneName1, setBeneName1] = useState("");
    const [showCustomFormError, setShowCustomFormError] = useState("none")

    // Handle beneficiary/NGO check click event
    // NOTE!!! As the user selects each beneficiary in the form, this function looks for 
    // that specific beneficiary in the beneData array. then it returns an array of objects that 
    // represent the risk treatment areas for that specific beneficary from the beneData 'approved_risk_treatment_areas' array.
    // this returned object array is in the proper format to be used as a cascading drop down in the last three form components
    const onCheckBene = item => {
        if (DEBUG) console.log(item);

        // clear bene treatment areas
        setBene1TreatmentAreas([]);
        let bene1TreatmentAreasArray = [];

        // get record from beneData based on the address.
        let bene1 = beneData.filter(bene => (bene.value === item))
        if (DEBUG) console.log(bene1);

        // set bene name
        setBeneName1(bene1[0].label);
        // add to master bene list
        setBeneList(beneList => [bene1[0]]);

        // verify the length of bene1[0].approved_treatment_areas is gt 0,
        // then we know there are risk treatment areas (RTAs) to add to the new object for the user to select
        if (bene1[0].data.approved_risk_treatment_areas != undefined) {

            // iterate through approved_treatment_areas object to create approvals object to be passed to RTAs dropdown
            for (const key in bene1[0].data.approved_risk_treatment_areas) {
                if (DEBUG) console.log(`${key}: ${bene1[0].data.approved_risk_treatment_areas[key]}`);

                // split treatment area string into (category - issue - sub-issue) (splitArray[0] - splitArray[1] - splitArray[2])
                let splitArray = key.split(' - ');

                // filter splitArray[0] (category) against bene1TreatmentAreasArray[i].label (category). 
                const resArray = bene1TreatmentAreasArray.filter(element => element.label == splitArray[0]);
                // if resArray.length == 0 , then this category does not exist yet and must be added.
                if (resArray.length == 0) {
                    if (DEBUG) console.log("added!!!!", splitArray[0])
                    bene1TreatmentAreasArray.push({
                        value: " " + splitArray[0],
                        label: splitArray[0],
                        children: [
                            {
                                value: " " + splitArray[1],
                                label: splitArray[1],
                                children: [
                                    {
                                        value: " " + splitArray[2],
                                        label: splitArray[2],
                                    },
                                ]
                            },
                        ],
                    })
                }
                // else go through all issues to see if that issue exists yet. if it does, and add the sub issue to its children array.
                // if it does not exist in the RTAs array, then add the issue and sub issue. 
                else {
                    if (DEBUG) console.log("duplicate")
                    // iterate through bene1TreatmentArray looking for index of the duplicate RTA category
                    for (let i = 0; i < bene1TreatmentAreasArray.length; i++) {
                        // re-find the instance where the categories are the same and then check the issues to see if this issue exist yet.
                        if (bene1TreatmentAreasArray[i].label == splitArray[0]) {
                            // check if the sub-issue exists
                            let filterRes = bene1TreatmentAreasArray[i].children.filter((element) => element.label == splitArray[1])
                            // if it does not exist, add it
                            if (filterRes.length == 0) {
                                bene1TreatmentAreasArray[i].children.push(
                                    {
                                        value: " " + splitArray[1],
                                        label: splitArray[1],
                                        children: [
                                            {
                                                value: " " + splitArray[2],
                                                label: splitArray[2],
                                            },
                                        ]
                                    },
                                )
                            }
                            // if it does exist, only add sub issue to that specific issue.
                            else {
                                // iterate through array looking for an issue label that matched the issue we are trying to add
                                bene1TreatmentAreasArray[i].children.map((element, index) => {
                                    if (element.label == splitArray[1]) {
                                        // when found only add sub issue at the index the match was found at
                                        bene1TreatmentAreasArray[i].children[index].children.push(
                                            {
                                                value: " " + splitArray[2],
                                                label: splitArray[2],
                                            },
                                        )
                                    }
                                });
                            }
                        }
                    }
                };
            };
        }
        // set bene1 treatment areas in state
        if (DEBUG) console.log(bene1TreatmentAreasArray);
        setBene1TreatmentAreas(bene1TreatmentAreasArray);
    };

    // ============ Handle Risk treatment Area Change ============
    const handleMineSiteDirectiveChange = (value) => {
        setMineSiteDirective(value)
    }
    const handleMineRegionChange = (value) => {
        setRegion(value.toString())
    }
    const handleSiteNameChange = (value) => {
        setSiteName(value)
    }

    // ============ Handle Risk Assessment Completed ============
    const OECDTrueFalse = (checkedValue) => {
        setOECDTrue(checkedValue)
    }

    const yesOrNo = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No',
        },
    ];

    // ======= Beneficiary Dropdown tag =======
    // antd beneficiary tags for dropdown
    function tagRender(props) {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={'blue'}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    }

    // ========== Form Methods ==========
    // Handle cancel in mint modal
    const handleCancel = () => {
        setMintModalVisible(false);
    };

    // Handle Failed
    const onFinishFailed = async (errorInfo) => {
        console.log("Failed", form.getFieldsError)
    }

    // Handle Reset
    const onReset = () => {
        form.resetFields();
        setRule("");
        setShowCustomFormError("none");
        setBeneList([]);
        setBene1TreatmentAreas([]);
        setIpfsImageHash();
        setBeneName1("");
        setMineSiteDirective();
    };

    /////////////////////////////////////////////
    //////////// *** HANDLE SUBMIT ***///////////
    /////////////////////////////////////////////
    const handleCreateClaimSubmit = async () => {
        if (
            userType == 'auditor' ||
            userType == 'beneficiary_ngo' ||
            userType == 'buyer' ||
            additionalRequirementsTrigger === true ||
            additionalRequirementsTrigger === undefined ||
            createClaimBusinessActivityKYCTrigger === true ||
            createClaimBusinessActivityKYCTrigger === undefined ||
            createClaimManagementKYCTrigger === true ||
            createClaimManagementKYCTrigger === undefined ||
            createClaimResponsibleSupplyChainKYC === true ||
            createClaimResponsibleSupplyChainKYC === undefined ||
            createClaimSignedAcknowledgementKYCTrigger === true ||
            createClaimSignedAcknowledgementKYCTrigger === undefined
        ) {
            alert("User type not authorized.");
        } else {
            if (DEBUG) console.log("form values", form.getFieldsValue())
            if (DEBUG) console.log("UPLOADING...")
            setIpfsHash()
            setIpfsContents()
            setRule("")
            setShowCustomFormError("none");

            // Additional Input Validation:
            // 1. Format Certificated and if there are any selected, return. Otherwise state: "None" on claim 
            let certificatesFormatted = []
            Object.entries(certificationType).forEach(([key, value], index) => {
                certificatesFormatted.push(` ${value} ` + certificationNumber[index])
            })

            let certificatesFinal;
            if (certificatesFormatted.length > 0) {
                certificatesFinal = certificatesFormatted;
            } else {
                certificatesFinal = "None";
            }

            // 2. Image was uploaded and ipfsImageHash is a hash --> in the if statement below. 

            // 3. At least one directed purpose is selected and OECD annex II assessment passed
            if (DEBUG) console.log(beneList)
            if (DEBUG) console.log(mineSiteDirective)
            if (DEBUG) console.log(OECDTrue[0])
            if (DEBUG) console.log(ipfsImageHash)
            let totalDirectives = (mineSiteDirective.length) / 2
            if (DEBUG) console.log(totalDirectives)

            // 4. One or two beneficiaries selected?
            // Then set beneAddress1 / beneAddress2 based on the results. BeneAddress2 remains as 'None' if only on bene selected.
            let beneAddress1 = "N/A";
            let fomattedBeneName1 = beneName1;

            // set address
            beneAddress1 = beneList[0].value;
            // set name
            fomattedBeneName1 = beneName1;

            // 5. Generate timestamp when submitted. 
            var timestamp = new Date(Date.now())

            // 6. Generate product ID
            var productID = uuidv4();
            setProdId(productID);
            if (DEBUG) console.log(productID);

            // ---------------- CREATE IPFS DATA -----------------
            if (ipfsImageHash && totalDirectives >= 1 && OECDTrue[0] == true && address) {
                const result = await addToIPFS(
                    "\n" + " ADAPTIVE RESOURCES, Supply Chain Due Diligence Token " + "\n" +
                    "\n" + "      " + "Supply Chain: " + claimType + "\n" +
                    "\n" + "      " + "Token ID: " + productID + "\n" +
                    "\n" + "      " + "Token Creator: " + address + "\n" +
                    "\n" + "      " + "Risk Assessment of Mine Site Conducted: Yes" + "\n" +
                    "\n" + "      " + "Certification: " + certificatesFinal + "\n" +
                    "\n" + "      " + "Production Location: " + region + "\n" +
                    "\n" + "      " + "Timestamp: " + timestamp + "\n" +
                    "\n" + "      " + "Mine Site Image: " + "https://ipfs.io/ipfs/" + ipfsImageHash + "\n" +
                    "\n" + "      " + "Risk Treatment and/or Impact Areas: " +
                    "\n" + "          " + "- Site Name: " + siteName +
                    "\n" + "              " + "- Operator Beneficiary: " +
                    "\n" + "                  " + "- " + fomattedBeneName1 + ": " + mineSiteDirective +
                    "\n" + "      " + "Associated Addresses: " +
                    "\n" + "              " + fomattedBeneName1 + ": " + beneAddress1 +
                    "\n"
                )
                if (result && result.path) {
                    setIpfsHash(result.path);
                    if (DEBUG) console.log("UPLOADED");
                    setMintModalVisible(true);
                }
                setSending(false)
            } else {
                // error message
                if (totalDirectives == 0) {
                    setRule("Please select at least one 'Directed Purpose' for the beneficiary payout.");
                    setShowCustomFormError("block");
                    setSending(false);
                }
                else if (!address) {
                    setRule("Please connect your wallet before minting.");
                    setShowCustomFormError("block");
                    setSending(false);
                }
            }
        }
    }

    return (
        <div style={{ margin: "16px", borderStyle: 'solid', borderWidth: '2px', borderRadius: '18px', borderColor: '#B2B4B875', backgroundColor: '#B2B4B840' }}>
            <h1 style={{ color: "#203864", marginTop: '16px' }}>Due Diligence Token</h1>
            <Row justify="center">
                <div className="create-claim-form" style={{ margin: '16px', fontSize: '16px' }}>
                    For more information, please see the <a href="https://adaptive-resources.gitbook.io/adaptive-resources-docs/user-guides/creating-your-first-claim-token" target='blank'>Quick Start Guide</a>.
                </div>
            </Row>
            <Row justify="center">
                <Col xs={23} sm={23} md={22} lg={18} xl={16}>
                    <Card
                        hoverable={true}
                        style={{ background: '#EBEBEB99', borderRadius: '18px', marginBottom: '16px' }}
                    >
                        <Form
                            className="create-claim-form"
                            form={form}
                            layout="horizontal"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={handleCreateClaimSubmit}
                            onFinishFailed={onFinishFailed}
                            requiredMark={false}
                            labelAlign="right"
                            labelWrap
                            colon={false}
                        >
                            <Form.Item
                                name={['claim', 'commodity']}
                                label={'Commodity Type'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Select
                                    placeholder="Select"
                                    onChange={(value) => {
                                        setClaimType(value)
                                    }}>
                                    <Option value="Cobalt (Co)">Cobalt</Option>
                                    <Option value="Gold (Au)">Gold</Option>
                                    <Option value="Mica">Mica</Option>
                                    <Option value="Tantalum (Ta)">Tantalum (Coltan)</Option>
                                    <Option value="Tin (Sn)">Tin</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name={['riskAssessment']}
                                label="Risk Assessment Completed"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: /^[true]+$/,
                                        message: 'Risk assessment must be completed',
                                    },
                                ]}
                            >
                                <Checkbox.Group
                                    options={yesOrNo}
                                    onChange={OECDTrueFalse}
                                ></Checkbox.Group>
                            </Form.Item>

                            <Form.Item
                                name={['certification']}
                                label="Certificate or Scheme"
                            >
                                <DynamicFieldCertificates
                                    certificationType={certificationType}
                                    certificationNumber={certificationNumber}>
                                </DynamicFieldCertificates>
                            </Form.Item>

                            <Form.Item
                                name={['prodLocation']}
                                label="Production Location"
                                rules={[{ required: true }]}
                            >
                                <Cascader
                                    options={mineRegions}
                                    onChange={handleMineRegionChange}
                                    style={{ width: '100%' }}
                                    placeholder='Select'
                                />
                            </Form.Item>

                            <Form.Item
                                name={['mineSite']}
                                label="Site Name"
                                rules={[{ required: true }]}
                            >
                                <Input placeholder="Enter Local Site Name" onChange={(e) => handleSiteNameChange(e.target.value)} />
                            </Form.Item>

                            <Popover content={imageRules} title={<Text type="warning">Valid Pictures Include:</Text>}>
                                <Form.Item
                                    name={['uploadImage']}
                                    label="Minesite Image"
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        type="file"
                                        accept="image/*"
                                        onChange={uploadImageHandler}
                                        style={{ width: "100%" }}
                                        addonAfter={
                                            <Button type="primary" disabled={!selectedFile} onClick={async () => {
                                                if (DEBUG) console.log("UPLOADING...")
                                                setImageSending(true)
                                                setIpfsImageHash()
                                                try {
                                                    const result = await addToIPFS(buffer)
                                                    if (result && result.path) {
                                                        setIpfsImageHash(result.path)
                                                    }
                                                } catch (error) {
                                                    alert("Please retry image upload, an error occured. ", error)
                                                }
                                                setImageSending(false)
                                                if (DEBUG) console.log("...UPLOADED")
                                            }}>
                                                <UploadOutlined />
                                            </Button>
                                        }
                                    />
                                </Form.Item>
                            </Popover>

                            <Form.Item
                                name={['preview2']}
                                label="Image Preview"
                            >
                                <Row justify="center" align="middle">
                                    <Col xs={24} sm={24} md={22} lg={20} xl={16}>
                                        {ipfsImageHash ?
                                            (<img src={"https://adaptiveresources.infura-ipfs.io/ipfs/" + ipfsImageHash} style={{ width: "50%" }} />)
                                            :
                                            (imageSending ? (<Spin />) : (<Alert type="info" showIcon message="* Upload Image *" />))
                                        }
                                    </Col>
                                </Row>
                            </Form.Item>

                            <Form.Item
                                name={['riskBene']}
                                label="Risk Beneficiary"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a risk beneficiary',
                                    }
                                ]}
                            >
                                <Select
                                    showArrow
                                    tagRender={tagRender}
                                    options={beneData}
                                    onChange={onCheckBene}
                                    placeholder='Select'
                                />
                            </Form.Item>

                            <Form.Item
                                name={['riskTreatmentArea']}
                                label="Risk Treatment Area"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a risk treatment area',
                                    }
                                ]}
                            >
                                <Cascader
                                    options={bene1TreatmentAreas}
                                    onChange={handleMineSiteDirectiveChange}
                                    value={mineSiteDirective}
                                    placeholder="Select"
                                    style={{ fontSize: '14px' }}
                                />
                            </Form.Item>

                            <Alert style={{ marginBottom: '18px', display: showCustomFormError }} error={"Error"} description={rule} type="error" showIcon />

                            <Button
                                type="primary"
                                shape="round"
                                loading={sending}
                                htmlType="submit"
                                disabled={!ipfsImageHash || !mineSiteDirective}
                            >
                                Next
                            </Button>

                            <Button
                                shape="round"
                                htmlType="button"
                                onClick={onReset}
                                style={{ marginLeft: "12px" }}
                            >
                                Reset
                            </Button>
                        </Form>
                        <div style={{ display: qrShow, marginTop: '18px' }}>
                            <Row justify="center">
                                <Col flex={1}></Col>
                                <Col flex={1}>
                                    <Alert
                                        message={"Token Minted Successfully!"}
                                        description={"Scan QR code or click the link to view token information"}
                                        type="success"
                                        showIcon
                                    />
                                </Col>
                                <Col flex={1}></Col>
                            </Row>
                            <Row >
                                <Col flex={1}></Col>
                                <Col flex={1}>
                                    <Card style={{ borderRadius: '18px', marginTop: '18px' }}>
                                        <div style={{ marginTop: '6px' }}>
                                            <h4 >Token Metadata</h4>
                                            <Popover content="URL to IPFS data">
                                                <a href={"https://adaptiveresources.infura-ipfs.io/ipfs/" + ipfsHash}><p style={{ overflow: 'hidden' }}>{ipfsHash}</p></a>
                                            </Popover>
                                        </div>
                                        <h4 >Claim ID QR Code</h4>
                                        {prodId ? (
                                            <QRCode style={{ marginTop: '6px' }} value={prodId} />
                                        ) : ("")}
                                    </Card>
                                </Col>
                                <Col flex={1}></Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Modal
                open={mintModalVisible}
                onCancel={handleCancel}
                width='85%'
                footer={null}
                address={address}
            >
                <div style={{ fontSize: '75%' }}>
                    <h4 style={{ marginBottom: '12px' }}>Verify Token Information</h4>
                    <Row align="middle" justify="center">
                        {ipfsDisplay}
                    </Row>
                    <Row justify="center">
                        <Button
                            onClick={async () => {
                                // format beneList the second param is where payout percentages are defined
                                let finalBeneList;
                                if (beneList.length == 1) {
                                    // 70% to bene1
                                    finalBeneList = [
                                        [
                                            ethers.utils.getAddress(beneList[0].value),
                                            ethers.BigNumber.from(7000)
                                        ]
                                    ];

                                    if (DEBUG) console.log("Product Id: ", prodId);
                                    if (DEBUG) console.log("Commodity Type: ", claimType);
                                    if (DEBUG) console.log("Beneficiaries: ", finalBeneList);
                                    if (DEBUG) console.log("IPFS Metadata Hash: ", ipfsHash);

                                    // Mint the token
                                    try {
                                        const result = await tx(writeContracts.ARClaimToken.mintClaim(
                                            region,
                                            prodId,
                                            claimType,
                                            BigNumber.from(0),
                                            BigNumber.from(0),
                                            finalBeneList,
                                            ipfsHash
                                        ));
                                        if (result) {
                                            setMintModalVisible(false);
                                            setQRShow("block");
                                        }
                                    } catch (error) {
                                        alert(error);
                                    }
                                } else {
                                    alert("Please select only one Risk Beneficiary. Reset the form to try again.")
                                }
                            }}
                            disabled={!ipfsHash || !ipfsImageHash || !mineSiteDirective || !claimType || !region || !siteName}
                            size="large"
                            shape="round"
                            type="primary"
                            style={{ textAlign: 'center', background: "#ff7875", borderColor: "#bae7ff", marginTop: '12px' }}
                        >
                            Mint Token
                        </Button>
                    </Row>
                </div>
            </Modal >
        </div >
    );
}