export let countryColors = [
    {
        country: "W. Australia",
        color: "mediumaquamarine"
    },
    {
        country: "DRC",
        color: "mediumslateblue"
    },
    {
        country: "New Caledonia",
        color: "darkorchid"
    },
    {
        country: "Brazil",
        color: "cadetblue"
    },
    {
        country: "Mexico",
        color: "indianred"
    },
    {
        country: "Austrialia",
        color: "darkseagreen"
    },
    {
        country: "Taiwan",
        color: "pink"
    },
    {
        country: "China",
        color: "darksalmon"
    },
    {
        country: "Sweden",
        color: "khaki"
    },
    {
        country: "Canada",
        color: "grey"
    },
    {
        country: "United States",
        color: "navy"
    },
    {
        country: "Zimbabwe",
        color: "mediumpurple"
    },
    {
        country: "Malaysia",
        color: "tan"
    },
    {
        country: "Germany",
        color: "lightseagreen"
    },
    {
        country: "Spain",
        color: "darkorange"
    },
    {
        country: "Norway",
        color: "yellowgreen"
    },
    {
        country: "Uganda",
        color: "forestgreen"
    },
    {
        country: "Switzerland",
        color: "crimson"
    }
];

export let productColors = [
    {
        product: "Ore",
        color: "#708090"
    },
    {
        product: "Nickel Ore",
        color: "#fedead"
    },
    {
        product: "Cobalt-Copper Ore",
        color: "#cccccc"
    },
    {
        product: "Lithium Ore",
        color: "#ffc0bf"
    },
    {
        product: "D'ore",
        color: "#ff99cb"
    },
    {
        product: "Comex 100 OZ Gold Bar",
        color: "#BDB76B"
    },
    {
        product: "Jewelry",
        color: "#4B0082"
    },
    {
        product: "Gold Necklace",
        color: "#FFE4B5"
    },
    {
        product: "Crude Nickel Sulphate",
        color: "#ff99cb"
    },
    {
        product: "Crude Cobalt Hydroxide",
        color: "#ffbffe"
    },
    {
        product: "Crude Nickel Sulphate",
        color: "#cccc9a"
    },
    {
        product: "Nickel Sulphate",
        color: "#8ACFD0"
    },
    {
        product: "Nickel Hydroxide",
        color: "#c1feff"
    },
    {
        product: "Nickel Carbonate",
        color: "#b8bfbf"
    },
    {
        product: "Lithium Sulphate",
        color: "#6c78b9"
    },
    {
        product: "Lithium Hydroxide",
        color: "#57768B"
    },
    {
        product: "Lithium Carbonate",
        color: "#4c8cb8"
    },
    {
        product: "Cobalt Hydroxide",
        color: "#a46173"
    },
    {
        product: "Cobalt Carbonate",
        color: "#7e5273"
    },
    {
        product: "Nickel Cobalt Manganese Cathode",
        color: "#5664f3"
    },
    {
        product: "Lithium Iron Phosphate Cathode",
        color: "#512c8f"
    },
    {
        product: "Nickel Manganese Cobalt Cathode",
        color: "#81bafc"
    },
    {
        product: "Battery (NCM 881)",
        color: "#8a5cc0"
    },
    {
        product: "Battery (NCM 532)",
        color: "#9c5c9c"
    },
    {
        product: "Battery (NCA)",
        color: "#b344bc"
    },
    {
        product: "Electric Vehicle",
        color: "navy"
    },
    {
        product: "Quartz Ore",
        color: "#D8BFD8"
    },
    {
        product: "MGS",
        color: "#FFA07A"
    },
    {
        product: "Ingot",
        color: "#98FB98"
    },
    {
        product: "Cell Type",
        color: "#AFEEEE"
    },
    {
        product: "Wafer Type",
        color: "#B0E0E6"
    },
    {
        product: "Solar Charger",
        color: "#FFE4C4"
    },
    {
        product: "Solar Panel",
        color: "#BC8F8F"
    },
    {
        product: "Consumer Solar",
        color: "navy"
    },
];