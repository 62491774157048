import React, { useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Row, Drawer, Col, Card, Button } from "antd";
import { useCommodityPrices } from "../hooks";
import { DollarCircleFilled, QuestionOutlined, GlobalOutlined } from '@ant-design/icons';

export default function UtilitiesButtons({ mainnetProvider, collapsed }) {

  // get prices and format
  const prices = useCommodityPrices(mainnetProvider);
  const goldPrice = Math.round((parseInt(prices.goldPrice) / 10 ** 8) * 100) / 100;
  const silverPrice = Math.round((parseInt(prices.silverPrice) / 10 ** 8) * 100) / 100;
  const eurusdPrice = Math.round((parseInt(prices.eurusdPrice) / 10 ** 8) * 1000) / 1000;
  const lastUpdated = prices.lastUpdated;

  // Drawer components
  const [drawerVisible, setDrawerVisible] = useState(false);
  const showDrawer = () => {
    setDrawerVisible(true);
  };
  const onClose = () => {
    setDrawerVisible(false);
  };

  return (
    <Row className="bottom-btn-bar" style={{ left: collapsed ? (0) : (50) }}>
      <Col span={collapsed ? (14) : (8)}>
        <Link to="/">
          <Button
            shape="circle"
            icon={<GlobalOutlined />}
            style={{ margin: '4px' }}
          >
          </Button>
        </Link>
      </Col>

      <Col span={collapsed ? (14) : (8)}>
        <a href="https://adaptive-resources.gitbook.io/adaptive-resources-docs/" target='blank'>
          <Button
            shape="circle"
            icon={<QuestionOutlined />}
            style={{ margin: '4px' }}
          >
          </Button>
        </a>
      </Col>

      <Col span={collapsed ? (14) : (8)}>
        <Button
          onClick={() => { showDrawer() }}
          shape="circle"
          icon={<DollarCircleFilled />}
          style={{ margin: '4px' }}
        >
        </Button>
        <Drawer
          open={drawerVisible}
          onClose={onClose}
          placement={"bottom"}
          height={"225px"}
        >
          <div >
            <Row
              justify="center"
              align="middle"
              gutter={[36, 12]}
            >
              <Col >
                <Card className="price-feed">
                  <Row justify="center"><h4 >Gold Price</h4></Row>
                  <span >${goldPrice} / Oz</span>
                </Card>
              </Col>
              <Col >
                <Card className="price-feed">
                  <Row justify="center"><h4 >Silver Price</h4></Row>
                  <span >${silverPrice} / Oz</span>
                </Card>
              </Col>
              <Col >
                <Card className="price-feed">
                  <Row justify="center"><h4 >Exchange Rate</h4></Row>
                  <span >1 Euro = {eurusdPrice} US Dollar</span>
                </Card>
              </Col>
            </Row>
            <Row justify="center" style={{ marginTop: "12px" }}>
              <Col ><span style={{ fontSize: "12px" }}>{lastUpdated ? ("Last Updated: " + lastUpdated) : ("")}</span></Col>
            </Row>
          </div>
        </Drawer>
      </Col >
    </Row >
  );
}
