import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { auth } from "./Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getDatabase, ref } from "firebase/database";


function PrivateRouteAuditor({
    component: Component,
    userType,
    additionalRequirementsTrigger,
    ...rest
}) {

    const [user, loading, error] = useAuthState(auth);

    const dbRef = ref(getDatabase());

    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return <h1 >Loading...</h1>
        // verify user is logged in
        if (!user) navigate("/login");
        // verify user permissions and create claim KYC complete
        if (
            userType == 'creator' ||
            userType == 'beneficiary_ngo' ||
            userType == 'buyer' ||
            additionalRequirementsTrigger === true ||
            additionalRequirementsTrigger === null
        ) { navigate('/profile'); alert("User type not authorized.") };
    }, [user, loading]);

    return (
        (userType ? <Outlet /> : navigate("/login"))
    );
}

export default PrivateRouteAuditor;