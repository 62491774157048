import ipfsClient from "ipfs-http-client";
import BufferList from "bl";

const DEBUG = false;

// --------------------------------------- IPFS Helpers ---------------------------------------
// auth
const infuraAuth = 'Basic ' +
    Buffer.from(process.env.REACT_APP_INFURA_IPFS_PROJECT_ID + ':' + process.env.REACT_APP_INFURA_IPFS_PROJECT_SECRET).toString('base64');
// client
const ipfs = ipfsClient({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: infuraAuth,
    },
});

// get from network
export async function getFromIPFS(hashToGet) {
    if (DEBUG) console.log(hashToGet);
    for await (const file of ipfs.get(hashToGet)) {
        if (DEBUG) console.log(file.path)
        if (!file.content) continue;
        const content = new BufferList()
        for await (const chunk of file.content) {
            content.append(chunk)
        }
        return content
    }
}

// add file to network
export async function addToIPFS(fileToUpload) {
    const result = await ipfs.add(fileToUpload)
    return result
}