import { useState, useEffect } from "react";
import { Form, Input, Card, Row, Col, Modal, Select, Button, Checkbox, Spin, Typography, Popover, Cascader, Tag, Alert, InputNumber } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { DynamicFieldCertificates, DynamicFieldPurities } from ".";
import { getFromIPFS, addToIPFS } from "../helpers/ipfs";
import { mineRegions } from "./helpers/mineRegions"
import { useCommodityPrices } from "../hooks";
import { ethers } from "ethers";
import { v4 as uuidv4 } from 'uuid';
import 'antd/dist/antd.css';

var QRCode = require('qrcode.react');

const DEBUG = true;

const { Option } = Select;
const { Text } = Typography;

export default function CreateClaimForm({
    beneData,
    coopData,
    mainnetProvider,
    tx,
    writeContracts,
    address,
    userType,
    additionalRequirementsTrigger,
    createClaimBusinessActivityKYCTrigger,
    createClaimManagementKYCTrigger,
    createClaimResponsibleSupplyChainKYC,
    createClaimSignedAcknowledgementKYCTrigger
}) {

    const [form] = Form.useForm();

    // ===== form state =====
    const [qrShow, setQRShow] = useState('none');
    const [claimType, setClaimType] = useState("Commodity");
    const [mass, setMass] = useState();
    const [units, setUnits] = useState("");
    const [commodityPurity, setCommoditiyPurity] = useState();
    const [certificationType, setCertificationType] = useState({});
    const [certificationNumber, setCertificationNumber] = useState({});
    const [purityType, setPurityType] = useState({});
    const [purityPercent, setPurityPercent] = useState({});
    const [OECDTrue, setOECDTrue] = useState(false);
    const [region, setRegion] = useState("");
    const [mineSiteDirective, setMineSiteDirective] = useState("None");
    const [mineSiteDirective2, setMineSiteDirective2] = useState("None");
    const [csoDirective, setCsoDirective] = useState("");
    const [rule, setRule] = useState("");

    // ===== get commodity price info =====
    const prices = useCommodityPrices(mainnetProvider);
    const goldPrice = (parseInt(prices.goldPrice) / 10 ** 8) / 31;

    // display units of mass based on commodity selection
    function setMassUnits(value) {
        if (value === "Gold (Au)") {
            setUnits("grams");
        }
        if (value === "Tantalum (Ta)" || value === "Tin (Sn)" || value === "Cobalt (Co)" || value === "Mica") {
            setUnits("kilograms");
        }
    }

    // ========== IPFS Claim Form/ Image Upload ==========
    // Add claim form to IPFS
    const [sending, setSending] = useState();
    const [ipfsHash, setIpfsHash] = useState();
    const [ipfsContents, setIpfsContents] = useState();
    const [selectedFile, setSelectedFile] = useState("");
    const [imageSending, setImageSending] = useState();
    const [ipfsImageHash, setIpfsImageHash] = useState();
    const [buffer, setBuffer] = useState()
    const [selectedExportFile, setSelectedExportFile] = useState("");
    const [exportImageSending, setExportImageSending] = useState();
    const [ipfsExportImageHash, setIpfsExportImageHash] = useState();
    const [exportBuffer, setExportBuffer] = useState()
    const [mintModalVisible, setMintModalVisible] = useState(false);

    useEffect(() => {
        const getIPFSData = async (hashToGet) => {
            // getFromIPFS, returns metadata content
            const content = await getFromIPFS(hashToGet)
            if (DEBUG) console.log(content._bufs.toString())
            const startIndex = content._bufs.toString('utf8').indexOf("https://")
            const endIndex = content._bufs.toString('utf8').indexOf("Unix")
            if (DEBUG) console.log(content._bufs.toString('utf8').slice(startIndex, endIndex))
            return content
        }

        const asyncGetFile = async () => {
            let result = await getIPFSData(ipfsHash);
            setIpfsContents(result.toString());
        }

        if (ipfsHash) asyncGetFile();
    }, [ipfsHash])

    const uploadImageHandler = async (event) => {
        setSelectedFile(event.target.files[0]);

        const file = event.target.files[0];

        const reader = new window.FileReader()
        reader.readAsArrayBuffer(file)
        reader.onloadend = () => {
            setBuffer(Buffer(reader.result))
        }
    }

    const uploadExportImageHandler = async (event) => {
        setSelectedExportFile(event.target.files[0]);

        const file = event.target.files[0];

        const reader = new window.FileReader()
        reader.readAsArrayBuffer(file)
        reader.onloadend = () => {
            setExportBuffer(Buffer(reader.result))
        }
    }

    // IPFS modal display
    let ipfsDisplay = ""
    if (ipfsHash) {
        if (!ipfsContents) {
            ipfsDisplay = (
                <Spin />
            )
        } else {
            ipfsDisplay = (
                <pre style={{ margin: 8, padding: 8, border: "1px solid #dddddd", backgroundColor: "#ededed" }}>
                    {ipfsContents}
                </pre>
            )
        }
    }

    // Image Upload Rules for popover on 'upload' image button
    const imageRules = (
        <div >
            <div >
                <Text strong style={{ fontSize: '12px' }}> - Upload an image of the minerals to be exported in a sealed transport bag</Text>
            </div>
            <div >
                <Text strong style={{ fontSize: '12px' }}> - The image must include the barcode identifier</Text>
            </div>
        </div>
    );

    const exportImageRules = (
        <div >
            <div >
                <Text strong style={{ fontSize: '12px' }}> - Export Certificate (Such as ICGLR Certificate)</Text>
            </div>
            <div >
                <Text strong style={{ fontSize: '12px' }}> - Completed Adaptive Resources Export Form</Text>
            </div>
            <div >
                <Text strong style={{ fontSize: '12px' }}> - Approved Form from CSO/Assurance Partner with Shipment and Risk Assessment Details</Text>
            </div>
        </div>
    );

    // ======================== Filters for onBeneCheck and onCoopCheck ========================
    const [beneList, setBeneList] = useState([]);
    const [coopList, setCoopList] = useState([]);
    const [prodId, setProdId] = useState();
    const [bene1TreatmentAreas, setBene1TreatmentAreas] = useState([]);
    const [bene2TreatmentAreas, setBene2TreatmentAreas] = useState([]);
    const [coopTreatmentAreas, setCoopTreatmentAreas] = useState([]);
    const [beneName1, setBeneName1] = useState("");
    const [beneName2, setBeneName2] = useState("");
    const [coopName, setCoopName] = useState("");
    const [coopAddress, setCoopAddress] = useState("");
    const [displayTreatmentTwo, setDisplayTreatmentTwo] = useState("none");
    const [checkVal, setCheckVal] = useState();
    const [showCustomFormError, setShowCustomFormError] = useState("none")

    // Handle beneficiary/NGO check click event
    // NOTE!!! As the user selects each beneficiary in the form, this function looks for 
    // that specific beneficiary in the beneData array. then it returns an array of objects that 
    // represent the risk treatment areas for that specific beneficary from the beneData 'approved_risk_treatment_areas' array.
    // this returned object array is in the proper format to be used as a cascading drop down in the last three form components
    const onCheckBene = item => {
        if (DEBUG) console.log(item);

        // if it is the first selection
        if (item.length === 1) {
            let bene1TreatmentAreasArray = [];

            // get record from beneData based on the address.
            let bene1 = beneData.filter(bene => (bene.value === item[0]))
            if (DEBUG) console.log(bene1);

            // set check value for when we deternine the order if another selection is made by user
            setCheckVal(bene1[0]);
            // set bene name
            setBeneName1(bene1[0].label);
            // add to master bene list
            beneList.push(bene1[0]);

            // verify the length of bene1[0].approved_treatment_areas is gt 0,
            // then we know there are risk treatment areas (RTAs) to add to the new object for the user to select
            if (bene1[0].data.approved_risk_treatment_areas !== undefined) {

                // iterate through approved_treatment_areas object to create approvals object to be passed to RTAs dropdown
                for (const key in bene1[0].data.approved_risk_treatment_areas) {
                    if (DEBUG) console.log(`${key}: ${bene1[0].data.approved_risk_treatment_areas[key]}`);

                    // split treatment area string into (category - issue - sub-issue) (splitArray[0] - splitArray[1] - splitArray[2])
                    let splitArray = key.split(' - ');

                    // filter splitArray[0] (category) against bene1TreatmentAreasArray[i].label (category). 
                    const resArray = bene1TreatmentAreasArray.filter(element => element.label === splitArray[0]);
                    // if resArray.length == 0 , then this category does not exist yet and must be added.
                    if (resArray.length === 0) {
                        if (DEBUG) console.log("added!!!!", splitArray[0])
                        bene1TreatmentAreasArray.push({
                            value: " " + splitArray[0],
                            label: splitArray[0],
                            children: [
                                {
                                    value: " " + splitArray[1],
                                    label: splitArray[1],
                                    children: [
                                        {
                                            value: " " + splitArray[2],
                                            label: splitArray[2],
                                        },
                                    ]
                                },
                            ],
                        })
                    }
                    // else go through all issues to see if that issue exists yet. if it does, and add the sub issue to its children array.
                    // if it does not exist in the RTAs array, then add the issue and sub issue. 
                    else {
                        if (DEBUG) console.log("duplicate")
                        // iterate through bene1TreatmentArray looking for index of the duplicate RTA category
                        for (let i = 0; i < bene1TreatmentAreasArray.length; i++) {
                            // re-find the instance where the categories are the same and then check the issues to see if this issue exist yet.
                            if (bene1TreatmentAreasArray[i].label === splitArray[0]) {
                                // check if the sub-issue exists
                                let filterRes = bene1TreatmentAreasArray[i].children.filter((element) => element.label === splitArray[1])
                                // if it does not exist, add it
                                if (filterRes.length === 0) {
                                    bene1TreatmentAreasArray[i].children.push(
                                        {
                                            value: " " + splitArray[1],
                                            label: splitArray[1],
                                            children: [
                                                {
                                                    value: " " + splitArray[2],
                                                    label: splitArray[2],
                                                },
                                            ]
                                        },
                                    )
                                }
                                // if it does exist, only add sub issue to that specific issue.
                                else {
                                    // iterate through array looking for an issue label that matched the issue we are trying to add
                                    bene1TreatmentAreasArray[i].children.map((element, index) => {
                                        if (element.label === splitArray[1]) {
                                            // when found only add sub issue at the index the match was found at
                                            bene1TreatmentAreasArray[i].children[index].children.push(
                                                {
                                                    value: " " + splitArray[2],
                                                    label: splitArray[2],
                                                },
                                            )
                                        }
                                    });
                                }
                            }
                        }
                    };
                };
            }
            // set bene1 treatment areas in state
            if (DEBUG) console.log(bene1TreatmentAreasArray);
            setBene1TreatmentAreas(bene1TreatmentAreasArray);
            // make sure second treatment selection is still not showing
            setDisplayTreatmentTwo('none');
        };

        // if it is the second selection add second RTAs options and add input form item
        if (item.length === 2) {
            let bene2TreatmentAreasArray = [];

            // get second entry
            let position;
            let bene = beneData.filter(bene => (bene.value === item[1]))
            // check against the value already stored in checkVal from first selection
            if (checkVal === bene[0]) {
                position = 0;
                if (DEBUG) console.log(1)
            } else {
                position = 1;
            }
            // find given position
            let bene2 = beneData.filter(bene => (bene.value === item[position]))
            if (DEBUG) console.log(bene2);

            // set bene name
            setBeneName2(bene2[0].label);
            // add to master bene list
            beneList.push(bene2[0]);

            // verify the length of bene1[0].approved_treatment_areas is gt 0,
            // then we know there are risk treatment areas (RTAs) to add to the new object for the user to select
            if (bene2[0].data.approved_treatment_areas !== undefined) {

                // iterate through approved_treatment_areas object to create approvals object to be passed to RTAs dropdown
                for (const key in bene2[0].data.approved_treatment_areas) {
                    if (DEBUG) console.log(`${key}: ${bene2[0].data.approved_treatment_areas[key]}`);

                    // split treatment area string into (category - issue - sub-issue) (splitArray[0] - splitArray[1] - splitArray[2])
                    let splitArray = key.split(' - ');

                    // filter splitArray[0] (category) against bene2TreatmentAreasArray[i].label (category). 
                    const resArray = bene2TreatmentAreasArray.filter(element => element.label === splitArray[0]);
                    // if resArray.length === 0 , then this category does not exist yet and must be added.
                    if (resArray.length === 0) {
                        console.log("added!!!!", splitArray[0])
                        bene2TreatmentAreasArray.push({
                            value: " " + splitArray[0],
                            label: splitArray[0],
                            children: [
                                {
                                    value: " " + splitArray[1],
                                    label: splitArray[1],
                                    children: [
                                        {
                                            value: " " + splitArray[2],
                                            label: splitArray[2],
                                        },
                                    ]
                                },
                            ],
                        })
                    }
                    // else go through all issues to see if that issue exists yet. if it does, and add the sub issue to its children array.
                    // if it does not exist in the RTAs array, then add the issue and sub issue. 
                    else {
                        if (DEBUG) console.log("duplicate")
                        // iterate through bene1TreatmentArray looking for index of the duplicate RTA category
                        for (let i = 0; i < bene2TreatmentAreasArray.length; i++) {
                            // re-find the instance where the categories are the same and then check the issues to see if this issue exist yet.
                            if (bene2TreatmentAreasArray[i].label === splitArray[0]) {
                                // check if the sub-issue exists
                                let filterRes = bene2TreatmentAreasArray[i].children.filter((element) => element.label === splitArray[1])
                                // if it does not exist, add it
                                if (filterRes.length === 0) {
                                    bene2TreatmentAreasArray[i].children.push(
                                        {
                                            value: " " + splitArray[1],
                                            label: splitArray[1],
                                            children: [
                                                {
                                                    value: " " + splitArray[2],
                                                    label: splitArray[2],
                                                },
                                            ]
                                        },
                                    )
                                }
                                // if it does exist, only add sub issue to that specific issue.
                                else {
                                    // iterate through array looking for an issue label that matched the issue we are trying to add
                                    bene2TreatmentAreasArray[i].children.map((element, index) => {
                                        if (element.label == splitArray[1]) {
                                            // when found only add sub issue at the index the match was found at
                                            bene2TreatmentAreasArray[i].children[index].children.push(
                                                {
                                                    value: " " + splitArray[2],
                                                    label: splitArray[2],
                                                },
                                            )
                                        }
                                    });
                                }
                            }
                        }
                    };
                };
            }
            // set bene1 treatment areas in state
            if (DEBUG) console.log(bene2TreatmentAreasArray);
            setBene2TreatmentAreas(bene2TreatmentAreasArray);
            // unblock the div
            setDisplayTreatmentTwo('block');
        }
    };

    const onCheckCoop = item => {
        if (DEBUG) console.log(item)
        let coopTreatmentAreasArray = [];

        if (item.length === 1) {
            // get record
            let coop = coopData.filter(coop => (coop.value === item[0]))
            if (DEBUG) console.log(coop[0])
            // set name
            setCoopName(coop[0].label);
            setCoopAddress(coop[0].value);
            // add to bene list
            coopList.push(coop[0]);

            // verify the length of bene1[0].approved_risk_treatment_areas is gt 0,
            // then we know there are risk treatment areas (RTAs) to add to the new object for the user to select
            if (coop[0].data.approved_risk_treatment_areas != undefined) {

                // iterate through approved_treatment_areas object to create approvals object to be passed to RTAs dropdown
                for (const key in coop[0].data.approved_risk_treatment_areas) {
                    if (DEBUG) console.log(`${key}: ${coop[0].data.approved_risk_treatment_areas[key]}`);

                    // split treatment area string into (category - issue - sub-issue) (splitArray[0] - splitArray[1] - splitArray[2])
                    let splitArray = key.split(' - ');

                    // filter splitArray[0] (category) against coopTreatmentAreasArray[i].label (category). 
                    const resArray = coopTreatmentAreasArray.filter(element => element.label == splitArray[0]);
                    // if resArray.length == 0 , then this category does not exist yet and must be added.
                    if (resArray.length == 0) {
                        if (DEBUG) console.log("added!!!!", splitArray[0])
                        coopTreatmentAreasArray.push({
                            value: " " + splitArray[0],
                            label: splitArray[0],
                            children: [
                                {
                                    value: " " + splitArray[1],
                                    label: splitArray[1],
                                    children: [
                                        {
                                            value: " " + splitArray[2],
                                            label: splitArray[2],
                                        },
                                    ]
                                },
                            ],
                        })
                    }
                    // else go through all issues to see if that issue exists yet. if it does, and add the sub issue to its children array.
                    // if it does not exist in the RTAs array, then add the issue and sub issue. 
                    else {
                        if (DEBUG) console.log("duplicate")
                        // iterate through bene1TreatmentArray looking for index of the duplicate RTA category
                        for (let i = 0; i < coopTreatmentAreasArray.length; i++) {
                            // re-find the instance where the categories are the same and then check the issues to see if this issue exist yet.
                            if (coopTreatmentAreasArray[i].label == splitArray[0]) {
                                // check if the sub-issue exists
                                let filterRes = coopTreatmentAreasArray[i].children.filter((element) => element.label == splitArray[1])
                                // if it does not exist, add it
                                if (filterRes.length == 0) {
                                    coopTreatmentAreasArray[i].children.push(
                                        {
                                            value: " " + splitArray[1],
                                            label: splitArray[1],
                                            children: [
                                                {
                                                    value: " " + splitArray[2],
                                                    label: splitArray[2],
                                                },
                                            ]
                                        },
                                    )
                                }
                                // if it does exist, only add sub issue to that specific issue.
                                else {
                                    // iterate through array looking for an issue label that matched the issue we are trying to add
                                    coopTreatmentAreasArray[i].children.map((element, index) => {
                                        if (element.label == splitArray[1]) {
                                            // when found only add sub issue at the index the match was found at
                                            coopTreatmentAreasArray[i].children[index].children.push(
                                                {
                                                    value: " " + splitArray[2],
                                                    label: splitArray[2],
                                                },
                                            )
                                        }
                                    });
                                }
                            }
                        }
                    };
                };
            }
        }
        // set bene1 treatment areas in state
        if (DEBUG) console.log(coopTreatmentAreasArray);
        setCoopTreatmentAreas(coopTreatmentAreasArray);
    }

    // ============ Handle Risk treatment Area Change ============
    const handleMineSiteDirective1Change = (value) => {
        setMineSiteDirective(value)
    }
    const handleMineSiteDirective2Change = (value) => {
        setMineSiteDirective2(value)
    }
    const handleCsoDirectiveChange = (value) => {
        setCsoDirective(value)
    }
    const handleMineRegionChange = (value) => {
        setRegion(value.toString())
    }

    // ============ Handle Risk Assessment Completed ============
    const OECDTrueFalse = (checkedValue) => {
        setOECDTrue()
        setOECDTrue(checkedValue)
        if (DEBUG) console.log(checkedValue)
    }

    const yesOrNo = [
        {
            value: true,
            label: 'Yes',
        },
        {
            value: false,
            label: 'No',
        },
    ];

    // ======= Beneficiary Dropdown tag =======
    // antd beneficiary tags for dropdown
    function tagRender(props) {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={'blue'}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    }

    // ========== Form Methods ==========
    // Handle cancel in mint modal
    const handleCancel = () => {
        setMintModalVisible(false);
    };

    // Handle Failed
    const onFinishFailed = async (errorInfo) => {
        console.log("Failed", form.getFieldsError)
    }

    // Handle Reset
    const onReset = () => {
        form.resetFields();
        setRule("");
        setShowCustomFormError("none");
        setBeneList([]);
        setCoopList([]);
        setBene1TreatmentAreas([]);
        setIpfsImageHash();
        setBeneName1("");
        setBeneName2("");
        setCoopName("");
        setDisplayTreatmentTwo("none");
        setCertificationType({});
        setCertificationNumber({});
        setPurityType({});
        setPurityPercent({});
    };

    /////////////////////////////////////////////
    //////////// *** HANDLE SUBMIT ***///////////
    /////////////////////////////////////////////
    const handleCreateClaimSubmit = async () => {
        if (
            userType == 'auditor' ||
            userType == 'beneficiary_ngo' ||
            userType == 'buyer' ||
            additionalRequirementsTrigger === true ||
            additionalRequirementsTrigger === undefined ||
            createClaimBusinessActivityKYCTrigger === true ||
            createClaimBusinessActivityKYCTrigger === undefined ||
            createClaimManagementKYCTrigger === true ||
            createClaimManagementKYCTrigger === undefined ||
            createClaimResponsibleSupplyChainKYC === true ||
            createClaimResponsibleSupplyChainKYC === undefined ||
            createClaimSignedAcknowledgementKYCTrigger === true ||
            createClaimSignedAcknowledgementKYCTrigger === undefined
        ) {
            alert("User type not authorized.");
        } else {
            if (DEBUG) console.log("form values", form.getFieldsValue())
            if (DEBUG) console.log("UPLOADING...")
            setIpfsHash()
            setIpfsContents()
            setRule("")
            setShowCustomFormError("none");

            // Additional Input Validation:
            // 1. Correct amount of purities and sum is less than or equals 100.
            let puritiesFormatted = []
            // push the commodity purity first
            puritiesFormatted.push(` ${claimType} ` + commodityPurity + '%');
            // any additional purities
            if (DEBUG) console.log(purityType);
            if (DEBUG) console.log(purityPercent);
            const puritiesFor = Object.entries(purityType).forEach(([key, value], index) => {
                puritiesFormatted.push(` ${value} ` + purityPercent[index] + '%');
            })
            if (DEBUG) console.log('puritiesFormatted', puritiesFormatted)

            let purityLength = puritiesFormatted.length;
            let totalPercent = 0;
            const percent = Object.entries(purityPercent).forEach(([key, value], index) => {
                totalPercent = Number(totalPercent) + Number(purityPercent[index]);
            })
            totalPercent = Number(totalPercent) + Number(commodityPurity);
            if (DEBUG) console.log("🪨 Total Percent", totalPercent);

            // 2. Format Certificated and if there are any selected, return. Otherwise state: "None" on claim 
            let certificatesFormatted = []
            const certificatesFor = Object.entries(certificationType).forEach(([key, value], index) => {
                certificatesFormatted.push(` ${value} ` + certificationNumber[index])
            })

            let certificatesFinal;
            if (certificatesFormatted.length > 0) {
                certificatesFinal = certificatesFormatted;
            } else {
                certificatesFinal = "None";
            }

            // 3. Image was uploaded and ipfsImageHash is a hash --> in the if statement below. 

            // 4. At least one directed purpose is selected and OECD annex II assessment passed
            if (DEBUG) console.log(beneList)
            if (DEBUG) console.log(mineSiteDirective)
            if (DEBUG) console.log(mineSiteDirective2)
            if (DEBUG) console.log(csoDirective)
            if (DEBUG) console.log(OECDTrue[0])
            if (DEBUG) console.log(purityLength)
            if (DEBUG) console.log(totalPercent)
            if (DEBUG) console.log(ipfsImageHash)
            let totalMinesiteDirectives = mineSiteDirective.length + mineSiteDirective2.length;
            let totalCSODirectives = csoDirective.length;
            let totalDirectives = (totalMinesiteDirectives + totalCSODirectives) / 2
            if (DEBUG) console.log(totalDirectives)

            // 5. One or two beneficiaries selected?
            // Then set beneAddress1 / beneAddress2 based on the results. BeneAddress2 remains as 'None' if only on bene selected.
            let beneAddress1 = "N/A";
            let beneAddress2 = "N/A"
            let fomattedBeneName1 = beneName1;
            let fomattedBeneName2 = beneName2;

            if (beneName2 != "" && beneName2 != "None") {
                // 2 beneficiary selected
                // set addressess
                beneAddress1 = beneList[0].value;
                beneAddress2 = beneList[1].value;
                // set names
                fomattedBeneName1 = beneName1;
                fomattedBeneName2 = beneName2;
            } else {
                // 1 beneficiary selected
                beneAddress1 = beneList[0].value;
                // set names
                fomattedBeneName1 = beneName1;
                fomattedBeneName2 = "None";
            }

            // 6. Generate timestamp when submitted. 
            var timestamp = new Date(Date.now())

            // 7. Generate product ID
            var productID = uuidv4();
            setProdId(productID);
            console.log(productID);

            // ---------------- CREATE IPFS DATA -----------------
            if (purityLength >= 1 && totalPercent <= 100 && ipfsImageHash && ipfsExportImageHash && totalDirectives >= 2 && OECDTrue[0] == true && address) {
                const result = await addToIPFS(
                    "\n" + " ADAPTIVE RESOURCES, Responsible Sourcing Claim Token " + "\n" +
                    "\n" + "      " + "Supply Chain: " + claimType + "\n" +
                    "\n" + "      " + "Claim ID: " + productID + "\n" +
                    "\n" + "      " + "Mass (" + units + "): " + mass + "\n" +
                    "\n" + "      " + "Purity: " + puritiesFormatted + "\n" +
                    "\n" + "      " + "Exporter/ Claim Creator: " + address + "\n" +
                    "\n" + "      " + "Risk Assessment of Mine Site Conducted: Yes" + "\n" +
                    "\n" + "      " + "Certification: " + certificatesFinal + "\n" +
                    "\n" + "      " + "Production Location: " + region + "\n" +
                    "\n" + "      " + "Timestamp: " + timestamp + "\n" +
                    "\n" + "      " + "Product Image: " + "https://ipfs.io/ipfs/" + ipfsImageHash + "\n" +
                    "\n" + "      " + "Export Documentation: " + "https://ipfs.io/ipfs/" + ipfsExportImageHash + "\n" +
                    "\n" + "      " + "Risk Treatment and/or Impact Areas: " +
                    "\n" + "          " + '- Risk Management Beneficiary: ' +
                    "\n" + "              " + "* " + fomattedBeneName1 + ": " + mineSiteDirective +
                    "\n" + "          " + '- Risk Management Beneficiary: ' +
                    "\n" + "              " + "* " + fomattedBeneName2 + ": " + mineSiteDirective2 +
                    "\n" + "          " + '- Cooperative: ' +
                    "\n" + "              " + "* " + coopList[0].label + ": " + csoDirective +
                    "\n" + "      " + "Associated Addresses: " +
                    "\n" + "              " + fomattedBeneName1 + ": " + beneAddress1 +
                    "\n" + "              " + fomattedBeneName2 + ": " + beneAddress2 +
                    "\n" + "              " + coopName + ": " + coopAddress +
                    "\n"
                )
                if (result && result.path) {
                    setIpfsHash(result.path);
                    if (DEBUG) console.log("UPLOADED");
                    setMintModalVisible(true);
                }
                setSending(false)
            } else {
                // error message
                if (purityLength < 2) {
                    setRule("Must enter at least two purities entries before submitting.");
                    setShowCustomFormError("block");
                    setSending(false);
                }
                else if (totalPercent > 100) {
                    setRule("Purities total more than 100%.");
                    setShowCustomFormError("block");
                    setSending(false);
                }
                else if (totalDirectives < 3) {
                    setRule("Please select at least one 'Directed Purpose' for the beneficiary payout.");
                    setShowCustomFormError("block");
                    setSending(false);
                }
                else if (!address) {
                    setRule("Please connect your wallet before minting.");
                    setShowCustomFormError("block");
                    setSending(false);
                }
                else {
                    setRule("Please complete the entire form.");
                    setShowCustomFormError("block");
                    setSending(false);
                }
            }
        }
    }

    return (
        <div style={{ margin: "16px", borderStyle: 'solid', borderWidth: '2px', borderRadius: '18px', borderColor: '#B2B4B875', backgroundColor: '#B2B4B840' }}>
            <h1 style={{ color: "#203864", marginTop: '16px' }}>Commodity Token</h1>
            <Row justify="center">
                <div className="create-claim-form" style={{ margin: '16px', fontSize: '16px' }}>
                    For more information, please see the <a href="https://adaptive-resources.gitbook.io/adaptive-resources-docs/user-guides/creating-your-first-claim-token" target='blank'>Quick Start Guide</a>.
                </div>
            </Row>
            <Row justify="center">
                <Col xs={23} sm={23} md={22} lg={18} xl={16}>
                    <Card
                        hoverable={true}
                        style={{ background: '#EBEBEB99', borderRadius: '18px', marginBottom: '16px', cursor: 'default' }}
                    >
                        <Form
                            className="create-claim-form"
                            form={form}
                            layout="horizontal"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={handleCreateClaimSubmit}
                            onFinishFailed={onFinishFailed}
                            requiredMark={false}
                            labelAlign="right"
                            labelWrap
                            colon={false}
                        >
                            <Form.Item
                                name={['claim', 'commodity']}
                                label={'Commodity Type'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Select
                                    placeholder="Select"
                                    onChange={(value) => {
                                        setClaimType(value)
                                        setMassUnits(value)
                                    }}>
                                    <Option value="Cobalt (Co)">Cobalt</Option>
                                    <Option value="Gold (Au)">Gold</Option>
                                    <Option value="Mica">Mica</Option>
                                    <Option value="Tantalum (Ta)">Tantalum (Coltan)</Option>
                                    <Option value="Tin (Sn)">Tin</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name={['weight']}
                                label="Weight"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: /^[.0-9]+$/,
                                        message: 'Not a valid input.',
                                    },
                                ]}
                            >
                                <Input onChange={(e) => {
                                    setMass(e.target.value)
                                }}
                                    style={{ width: '100%' }}
                                    placeholder={units ? units : "Metric Weight"}
                                />
                            </Form.Item>

                            <Form.Item
                                name={['purity']}
                                label={claimType + " Purity"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: /^[.0-9]+$/,
                                        message: 'Not a valid input.',
                                    },
                                ]}
                            >
                                <InputNumber
                                    onChange={(value) => {
                                        setCommoditiyPurity(value)
                                    }}
                                    style={{ width: '100%' }}
                                    min={0.01}
                                    max={99.99}
                                    placeholder="Percent Purity (%)"
                                />
                            </Form.Item>

                            <Form.Item
                                name={['addPurities']}
                                label="Addtional Purities"
                            >
                                <DynamicFieldPurities
                                    claimType={claimType}
                                    purityType={purityType}
                                    purityPercent={purityPercent}
                                >
                                </DynamicFieldPurities>
                            </Form.Item>

                            <Form.Item
                                name={['riskAssessment']}
                                label="Risk Assessment Completed"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: /^[true]+$/,
                                        message: 'Risk assessment must be completed',
                                    },
                                ]}
                            >
                                <Checkbox.Group
                                    options={yesOrNo}
                                    onChange={OECDTrueFalse}
                                ></Checkbox.Group>
                            </Form.Item>

                            <Form.Item
                                name={['certification']}
                                label="Certificate or Scheme"
                            >
                                <DynamicFieldCertificates
                                    certificationType={certificationType}
                                    certificationNumber={certificationNumber}>
                                </DynamicFieldCertificates>
                            </Form.Item>

                            <Form.Item
                                name={['prodLocation']}
                                label="Production Location"
                                rules={[{ required: true }]}
                            >
                                <Cascader
                                    options={mineRegions}
                                    onChange={handleMineRegionChange}
                                    style={{ width: '100%' }}
                                    placeholder='Select'
                                />
                            </Form.Item>

                            <Popover content={imageRules} title={<Text type="warning">Upload Guidelines:</Text>}>
                                <Form.Item
                                    name={['uploadImage']}
                                    label="Claim Image"
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        type="file"
                                        accept="image/*"
                                        onChange={uploadImageHandler}
                                        addonAfter={
                                            <Button
                                                type="primary"
                                                disabled={!selectedFile}
                                                onClick={async () => {
                                                    if (DEBUG) console.log("UPLOADING...")
                                                    setImageSending(true)
                                                    setIpfsImageHash()
                                                    try {
                                                        const result = await addToIPFS(buffer)
                                                        if (result && result.path) {
                                                            setIpfsImageHash(result.path)
                                                        }
                                                    } catch (error) {
                                                        alert("Please retry image upload, an error occured. ", error)
                                                    }
                                                    setImageSending(false)
                                                    if (DEBUG) console.log("...UPLOADED")
                                                }}
                                            >
                                                <UploadOutlined />
                                            </Button>
                                        }
                                    />
                                </Form.Item>
                            </Popover>

                            <Form.Item
                                name={['preview1']}
                                label=" "
                            >
                                <Row justify="center" align="middle">
                                    <Col xs={24} sm={24} md={22} lg={20} xl={16}>
                                        {ipfsImageHash ?
                                            (<img src={"https://adaptiveresources.infura-ipfs.io/ipfs/" + ipfsImageHash} style={{ width: "50%" }} />)
                                            :
                                            (imageSending ? (<Spin />) : (<Alert type="info" showIcon message="Upload Claim Image" />))
                                        }
                                    </Col>
                                </Row>
                            </Form.Item>

                            <Popover content={exportImageRules} title={<Text type="warning">Valid Documents Include:</Text>}>
                                <Form.Item
                                    name={['exportDoc']}
                                    label="Export Documentation"
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        type="file"
                                        accept="image/*"
                                        onChange={uploadExportImageHandler}
                                        addonAfter={
                                            <Button type="primary" disabled={!selectedExportFile} onClick={async () => {
                                                if (DEBUG) console.log("UPLOADING...")
                                                setExportImageSending(true)
                                                setIpfsExportImageHash()
                                                try {
                                                    const result = await addToIPFS(exportBuffer)
                                                    if (result && result.path) {
                                                        setIpfsExportImageHash(result.path)
                                                    }
                                                } catch (error) {
                                                    alert("Please retry image upload, an error occured. ", error)
                                                }
                                                setExportImageSending(false)
                                                if (DEBUG) console.log("...UPLOADED")
                                            }}>
                                                <UploadOutlined />
                                            </Button>
                                        }
                                    />
                                </Form.Item>
                            </Popover>

                            <Form.Item
                                name={['preview2']}
                                label=" "
                            >
                                <Row justify="center" align="middle">
                                    <Col xs={24} sm={24} md={22} lg={20} xl={16}>
                                        {ipfsExportImageHash ?
                                            (<img src={"https://adaptiveresources.infura-ipfs.io/ipfs/" + ipfsExportImageHash} style={{ width: "50%" }} />)
                                            :
                                            (exportImageSending ? (<Spin />) : (<Alert type="info" showIcon message="Upload Export Document" />))
                                        }
                                    </Col>
                                </Row>
                            </Form.Item>

                            <Form.Item
                                name={['riskBenes']}
                                label="Risk Beneficiaries"
                                rules={[
                                    {
                                        validator(_, value) {
                                            if (value.length <= 2) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Please select only two Risk Beneficiaries'));
                                        },
                                    },
                                    {
                                        required: true,
                                        message: 'Please select up to two Risk Beneficiaries',
                                    }
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    showArrow
                                    tagRender={tagRender}
                                    options={beneData}
                                    onChange={onCheckBene}
                                    placeholder='Select'
                                />
                            </Form.Item>

                            <Form.Item
                                name={['coop']}
                                label="Cooperative"
                                rules={[
                                    {
                                        validator(_, value) {
                                            if (value.length <= 1) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Please select only one Cooperative'));
                                        },
                                    },
                                    {
                                        required: true,
                                        message: 'Please select at least one Cooperative',
                                    }
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    showArrow
                                    tagRender={tagRender}
                                    options={coopData}
                                    onChange={onCheckCoop}
                                    placeholder='Select'
                                />
                            </Form.Item>

                            <Form.Item
                                name={['riskTreatmentAreas']}
                                label="Risk Treatment Areas"
                                required
                            >
                                <div style={{ margin: '4px' }}>
                                    <Row align="middle">
                                        {"Risk Beneficiary One: "}
                                        <Row ><span style={{ fontWeight: 'bold', marginLeft: '3px' }}>{" " + beneName1}</span></Row>
                                        <Cascader
                                            options={bene1TreatmentAreas}
                                            onChange={handleMineSiteDirective1Change}
                                            placeholder="Select"
                                            style={{ fontSize: '14px' }}
                                        />
                                    </Row>
                                    <div style={{ display: displayTreatmentTwo }}>
                                        <Row align="middle">
                                            {"Risk Beneficiary Two: "}
                                            <Row ><span style={{ fontWeight: 'bold', marginLeft: '3px' }}>{beneName2}</span></Row>
                                            <Cascader
                                                options={bene2TreatmentAreas}
                                                onChange={handleMineSiteDirective2Change}
                                                placeholder="Select"
                                            />
                                        </Row>
                                    </div>
                                    <Row align="middle">
                                        Cooperative:
                                        <Row ><span style={{ fontWeight: 'bold', marginLeft: '3px' }}>{coopName}</span></Row>
                                        <Cascader
                                            options={coopTreatmentAreas}
                                            onChange={handleCsoDirectiveChange}
                                            placeholder="Select"
                                        />
                                    </Row>
                                </div >
                            </Form.Item>

                            <Alert style={{ marginBottom: '18px', display: showCustomFormError }} error={"Error"} description={rule} type="error" showIcon />

                            <Button
                                type="primary"
                                shape="round"
                                loading={sending}
                                htmlType="submit"
                                disabled={!mass || !commodityPurity || !ipfsImageHash || !mineSiteDirective}
                            >
                                Next
                            </Button>

                            <Button
                                shape="round"
                                htmlType="button"
                                onClick={onReset}
                                style={{ marginLeft: "12px" }}
                            >
                                Reset
                            </Button>
                        </Form>
                        <div style={{ display: qrShow, marginTop: '18px' }}>
                            <Row justify="center">
                                <Col flex={1}></Col>
                                <Col flex={1}>
                                    <Alert
                                        message={"Token Minted Successfully!"}
                                        description={"Scan QR code or click the link to view token information"}
                                        type="success"
                                        showIcon
                                    />
                                </Col>
                                <Col flex={1}></Col>
                            </Row>
                            <Row >
                                <Col flex={1}></Col>
                                <Col flex={1}>
                                    <Card style={{ borderRadius: '18px', marginTop: '18px' }}>
                                        <h4 style={{ marginTop: '6px' }}>Token Metadata</h4>
                                        <Popover content="URL to IPFS data">
                                            <a href={"https://adaptiveresources.infura-ipfs.io/ipfs/" + ipfsHash}><p style={{ overflow: 'hidden' }}>{ipfsHash}</p></a>
                                        </Popover>
                                        <h4 >Claim ID QR Code</h4>
                                        {prodId ? (
                                            <QRCode style={{ marginTop: '6px' }} value={prodId} />
                                        ) : ("")}
                                    </Card>
                                </Col>
                                <Col flex={1}></Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Modal
                open={mintModalVisible}
                onCancel={handleCancel}
                width='85%'
                footer={null}
                address={address}
            >
                <div style={{ fontSize: '75%' }}>
                    <h4 style={{ marginBottom: '12px' }}>Verify Token Information</h4>
                    <Row align="middle" justify="center">
                        {ipfsDisplay}
                    </Row>
                    <Row justify="center">
                        <Button
                            onClick={async () => {
                                // format beneList and then add coop data to end of array
                                // as well as Adaptive's 10%
                                // the second param is where payout percentages are defined
                                let finalBeneList;
                                if (beneList.length > 1) {
                                    // 15% to bene1, 15% to bene2
                                    finalBeneList = [
                                        [
                                            ethers.utils.getAddress(beneList[0].value),
                                            ethers.BigNumber.from(1500)
                                        ],
                                        [
                                            ethers.utils.getAddress(beneList[1].value),
                                            ethers.BigNumber.from(1500)
                                        ]
                                    ];
                                } else {
                                    // 30% to bene1
                                    finalBeneList = [
                                        [
                                            ethers.utils.getAddress(beneList[0].value),
                                            ethers.BigNumber.from(3000)
                                        ]
                                    ];
                                }
                                // Coop always gets 30%
                                finalBeneList.push(
                                    [
                                        ethers.utils.getAddress(coopList[0].value),
                                        ethers.BigNumber.from(3000)
                                    ]
                                );
                                // Adaptive wallet always gets 10%, to pay for claim due diligence overhead costs
                                finalBeneList.push(
                                    [
                                        "0x49646DDF38a8D8D82DEA3b31C4C5c143876827Ac",
                                        ethers.BigNumber.from(1000)
                                    ]
                                );

                                // Get prices, gold price comes from Chainlink oracle
                                let price;
                                if (claimType == "Gold (Au)") {
                                    price = goldPrice; // USD/g
                                }
                                // For simplicity, all commodities except gold and silver will have the same base price as Tantalum.
                                if (claimType == "Tantalum (Ta)" || claimType == "Cobalt (Co)" || claimType == "Tin (Sn)" || claimType == "Mica") {
                                    price = 246.32; // USD/Kg 
                                }

                                if (DEBUG) console.log("Mass: ", mass);
                                let massMultiplied = mass * 100;
                                if (DEBUG) console.log("Mass multiplied: ", massMultiplied);
                                if (DEBUG) console.log("Commodity Purity: ", commodityPurity); // as a percentage
                                let commodityPurityMultiplied = commodityPurity * 100;
                                if (DEBUG) console.log("Price: ", price);

                                let calcClaimValue = (mass * (commodityPurity / 100) * price);
                                if (DEBUG) console.log("Calculated Claim Value in USD: ", calcClaimValue);

                                if (DEBUG) console.log("Product Id: ", prodId);
                                if (DEBUG) console.log("Commodity Type: ", claimType);
                                if (DEBUG) console.log("BENE LIST: ", finalBeneList);
                                if (DEBUG) console.log("IPFS Hash: ", ipfsHash);

                                if (calcClaimValue > 10) {
                                    // Mint the claim
                                    try {
                                        const result = await tx(writeContracts.ARClaimToken.mintClaim(
                                            region,
                                            prodId,
                                            claimType,
                                            massMultiplied,
                                            commodityPurityMultiplied,
                                            finalBeneList,
                                            ipfsHash
                                        ));
                                        if (result) {
                                            setMintModalVisible(false);
                                            setQRShow("block");
                                        }
                                    } catch (error) {
                                        alert(error);
                                    }
                                } else {
                                    alert("Estimted claim value must be greater than $10 USD. This claim is valued at $" + calcClaimValue.toFixed(2) + " USD.");
                                    setMintModalVisible(false);
                                }
                            }}
                            disabled={!ipfsHash || !mass || !commodityPurity || !ipfsImageHash || !mineSiteDirective || !claimType}
                            size="large"
                            shape="round"
                            type="primary"
                            style={{ textAlign: 'center', background: "#ff7875", borderColor: "#bae7ff", marginTop: '12px' }}
                        >
                            Mint Token
                        </Button>
                    </Row>
                </div>
            </Modal>
        </div >
    );
}