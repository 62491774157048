import { getApp, initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail, signInWithCustomToken, signOut } from "firebase/auth";
import { getDatabase, ref, update } from "firebase/database";
import { signIn, signUp } from "./ApiWrapper.js";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSENGER_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMNT_ID,
};

// init firebase services
let app;
try {
    app = getApp();
} catch (e) {
    app = initializeApp(firebaseConfig);
}

// init auth
const auth = getAuth(app);

// init firestore db
const db = getFirestore(app);

// ------ AUTHENTICATION ------

// Login handler
const logInWithEmailAndPassword = async (email, password) => {
    try {
        const data = await signIn(email, password);
        if (data.ok && data.token) {
            await signInWithCustomToken(auth, data.token);
        } else {
            if (data.message) {
                console.error(data.message);
                alert(data.message);
            }
        }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

// Register user in firebase auth handler
// FOR PRODUCTION PRE-SETUP USER LOGIN in FIREBASE DASHBOARD
const registerWithEmailAndPassword = async (email, password) => {
    try {
        const data = await signUp(email, password);
        if (data.ok && data.token) {
            await signInWithCustomToken(auth, data.token);
        } else {
            if (data.message) {
                console.error(data.message);
                alert(data.message);
            }
        }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

// Password reset with user email
const sendPasswordReset = async email => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

// Logout handler
const logout = () => {
    signOut(auth);
};

// Create user data in realtime database
// AFTER A USER IS SETUP, THEY NEED TO PROVIDE THIS INFO TO BE STORED IN REALTIME DB
// username can be the same as contactName
// this function does not update the 'otherAddr' in firebase
async function updateBasicUserData(
    uid,
    primaryContactName,
    contactPhoneNum,
    businessName,
    businessAddr,
    otherOfficeAddr = '', //optional
    website,
) {
    // get realtime db
    const db = getDatabase(app);
    // add user to db
    try {
        await update(ref(db, "users/" + uid), {
            primaryContactName: primaryContactName,
            contactPhoneNum: contactPhoneNum,
            businessName: businessName,
            businessAddr: businessAddr,
            otherOfficeAddress: otherOfficeAddr,
            website: website,
        });

        // return true if successful
        return true;
    } catch (error) {
        console.error(error);
        return false;
    };
}
async function updateEmailSettings(uid, email) {
    // get realtime db
    const db = getDatabase(app);
    // add user to db
    await update(ref(db, "users/" + uid), {
        emailNotifications: email,
    });
}



/* Circle user account ids */
async function updateCreditCardId(uid, ccId) {
    // get realtime db
    const db = getDatabase(app);
    // add user to db
    await update(ref(db, "users/" + uid), {
        userCircleCCId: ccId,
    });
}
async function updateUSBankId(uid, acctId) {
    // get realtime db
    const db = getDatabase(app);
    // add user to db
    await update(ref(db, "users/" + uid), {
        userCircleUSBankID: acctId,
    });
}
async function updateIBANBankId(uid, acctId) {
    // get realtime db
    const db = getDatabase(app);
    // add user to db
    await update(ref(db, "users/" + uid), {
        userCircleIBANBankID: acctId,
    });
}
async function updateNonIBANBankId(uid, acctId) {
    // get realtime db
    const db = getDatabase(app);
    // add user to db
    await update(ref(db, "users/" + uid), {
        userCircleNonIBANBankID: acctId,
    });
}
async function updateWalletId(uid, entityId, walletId) {
    // get realtime db
    const db = getDatabase(app);
    // add user to db
    await update(ref(db, "users/" + uid), {
        userCircleEntityId: entityId,
        userCircleWalletId: walletId,
    });
}

// ------- EXPORTS -------

export {
    auth,
    db,
    updateUSBankId,
    updateIBANBankId,
    updateNonIBANBankId,
    updateCreditCardId,
    updateWalletId,
    updateBasicUserData,
    updateEmailSettings,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
};