export const riskTreatmentRequirements = {
    "Management Practices - Grievence Mechanism": "The AMP has designated a point of contact for complaints.\n" + "The AMP has established clear procedures to address complaints and grievances (i.e. a grievance mechanism is in place).",
    "Management Practices - Legal Compliance": "The AMP has decision-making structures and mechanisms in place.\n" + "The AMP complies with legal requirements beyond rights related to mineral extraction.\n" + "The AMP must be legitimate.",
    "Management Practices - Due Diligence Management System": "The AMP declares that it is committed to support the Minamata Convention on Mercury and to 'reduce, and where feasible eliminate, the use of mercury', as required by the Convention.",
    "Business Practices - Extortion": "It is reasonable to believe that the AMP undertakes all reasonable efforts to avoid production at its mine site and its internal supply chain from being subjected to illegal taxation or extortion of money or minerals by non-state armed groups.",
    "Business Practices - Bribery and Facilitation": "It is reasonable to believe that the AMP undertakes all reasonable efforts to avoid offering, promising, giving, accepting or demanding any bribes to misrepresent taxes, fees and royalties paid to governments for the purposes of mineral extraction, trade, handling, transport and export." + "It is reasonable to believe that the AMP undertakes all reasonable efforts to resist bribery to conceal or disguise the origin of minerals.",
    "Business Practices - Money Laundering": "It is reasonable to believe that the AMP supports all efforts or takes all viable steps to contribute to the effective elimination of money laundering, where a reasonable risk of such practice from or connected to its operations or products is identified",
    "Production Improvement - Equipment to Improve Production": "Investments in equipment are planned and/or made.",
    "Production Improvement - Training to Improve Production": "Training is planned and/or provided.",
    "Serious Human Rights Violations - Child Labor": "Worst forms of child labour are not tolerated in the production process.\n" + "The AMP takes steps towards eradicating all worst forms of child labour directly or indirectly related to mining, among persons under the age of 18.\n" + "The AMP takes steps towards eradicating all child labour of persons under the age of 15.",
    "Serious Human Rights Violations - Forced Labor": "The AMP is not linked to any forms of forced or compulsory labour.",
    "Serious Human Rights Violations - Disciplinary Practices and Violence": "The AMP is not linked to committing any forms of torture or cruel, inhuman, and degrading treatment.",
    "Serious Human Rights Violations - Other Gross Human Rights Abuses": "It is reasonable to believe that the AMP is not linked to any other gross human rights abuses, such as widespread sexual violence",
    "Serious Human Rights Violations - Women's Rights": "The AMP takes steps to protect women or any individual in situation of vulnerability, against sexual violence and harassment at the workplace.\n" + "The AMP takes steps to respect the rights of women, in particular towards reducing any gender-based restrictions of access to mineral resources.",
    "Serious Human Rights Violations - Discrimination and Diversity": "The AMP does not base its decisions on criteria classified as discriminatory in the Universal Declaration of Human Rights.",
    "Serious Human Rights Violations - Gender Based Violence": "Support to programmes designed to prevent and eliminate all forms of gender-based violence. This encompasses a broad range of forms of physical, psychological, domestic and sexual violence.",
    "Occupational Health & Safety - Workplace Hazards & Machinery": "The AMP makes basic mining safety rules mandatory for its members.",
    "Occupational Health & Safety - Personal Protective Equipment": "Members of the AMP use personal protective equipment (PPE) at work.",
    "Occupational Health & Safety - Medical Care": "The AMP procures first aid and basic health services for its members.",
    "Occupational Health & Safety - Reducing Mercury use": "The AMP takes steps towards elimination of whole ore amalgamation.\n" + "The AMP takes steps towards elimination of open burning of amalgam or processed amalgam.\n" + "The AMP takes steps towards elimination of amalgam burning in residential areas.",
    "Occupational Health & Safety - Reducing Cyanide Use": "The AMP takes steps towards elimination of the practice of cyanide leaching in sediments, ore, or tailings to which mercury had been added, without first removing the mercury.",
    "Occupational Health & Safety - Miner Exposure to Radioactive or Harmful Emissions": "The mining site does not contain or is not located in a high-risk area for exposure to radioactive or harmful emissions.",
    "Community Rights - EITI Compliant Payment of Taxes": "It is reasonable to believe that the AMP pays to the government all taxes, fees, and royalties related to mineral extraction, trade, and export.\n" + "It is reasonable to believe that the AMP is committed to disclose – if requested – payments of taxes, fees, and royalties in accordance with the principles set forth under the Extractive Industry Transparency Initiative (EITI). Applicable only if the AMP is located in a country where EITI is collecting information from ASM.",
    "Community Rights - Security Forces and Conflict": "It is reasonable to believe that the AMP is not linked to committing war crimes or other serious violations of international humanitarian law, crimes against humanity, or genocide.\n" + "It is reasonable to believe that the AMP’s mine site and transportation routes are not illegally controlled by non-state armed groups.\n" + "It is reasonable to believe that the AMP undertakes best possible efforts to eliminate direct or indirect support to public or private security forces that illegally tax, extort, or control its mine site, internal supply chain, or point(s) of sale.\n" + "It is reasonable to believe that the AMP is supportive or seeks the support of public or private security forces if their presence is required to maintain the rule of law, including safeguarding human rights, providing security to mine workers, equipment, and facilities, and protecting the mine site or transportation routes from interference with legitimate extraction and trade.\n" + "It is reasonable to believe that the AMP does not knowingly hire individuals or units of security forces that are known to have been responsible for gross human rights abuses.\n" + "It is reasonable to believe that the AMP supports all efforts or takes all viable steps to ensure that payments to public security forces for the provision of security are as transparent, proportional, and accountable as possible.\n" + "It is reasonable to believe that the AMP supports all efforts or takes all viable steps to minimize adverse impacts associated with the presence of public or private security forces on their mine site(s).",
    "Community Rights - Residential & Indigenous Rights": "The AMP takes steps towards being accepted and/or integrated into existing communities.",
    "Community Rights - Education": "The AMP takes steps towards supporting the education of children in the communities where it operates.",
    "Community Rights - Social Protection and Social Voice": "The AMP takes steps towards supporting the social protection of its members.",
    "Community Rights - Access to Social Services": "The AMP takes steps towards supporting the access to social services of its members.",
    "Economic Development - Economic Infrastructure": "The AMP takes steps towards supporting the development of economic infrastructure in the communities where it operates.",
    "Economic Development - Financial Inclusion and Access to Finance": "The AMP takes steps towards supporting the financial inclusion of its members.",
    "Economic Development - Lighting": "The AMP takes steps towards supporting the access to electrical lighting for member households.",
    "Economic Development - Living Standards and Wages": "The AMP takes steps towards supporting the improvement of living standards and wages of its members.",
    "Economic Development - Offgrid Solar or PV": "The AMP takes steps towards supporting the access to off-grid solar or PV for member households.",
    "Land Use & Biodiversity - Legally Protected Areas": "The AMP operates in close coordination with and in support of Protected Area Authorities.",
    "Land Use & Biodiversity - Conflict With Agriculture Activities": "The AMP uses mining land in coordination with local inhabitants who require the same resource for agriculture, fishing, use of forest products, eco-tourism, or animal husbandry.",
    "Water Use - Mine Waste and Wastewater": "The AMP avoids serious contamination of water bodies with suspended solids and/or chemicals and fuel residues that put the livelihoods of other water users at risk.",
    "Water Use - Water Management": "The AMP uses water resources and water bodies in coordination with other water users.",
}

