import React, { useState, useRef } from "react";
import { Row, Col, Table, Popover, Button, Tag, Input, Space, Card, Tooltip } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Address } from "../components"
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import "../App.css"


export default function BeneficiaryTable({ beneficiaryData, mainnetProvider, blockExplorer }) {

    /**
     * Helper functions
     */
    function getIcons(record) {
        let icons = [];
        for (let approved in record.approved_risk_treatment_areas) {
            const category = approved.split(' - ');
            if (category[1] == "Community Rights" && !icons.some(element => element.icon == "/icon1.png")) {
                icons.push({ icon: "/icon1.png", description: "Community Rights" });
            }
            else if (category[1] == "Economic Development" && !icons.some(element => element.icon == "/icon2.png")) {
                icons.push({ icon: "/icon2.png", description: "Economic Development" });
            }
            else if (category[1] == "Land Use and Biodiversity" && !icons.some(element => element.icon == "/icon3.png")) {
                icons.push({ icon: "/icon3.png", description: "Land Use and Biodiversity" });
            }
            else if (category[1] == "Water Use" && !icons.some(element => element.icon == "/icon4.png")) {
                icons.push({ icon: "/icon4.png", description: "Water Use" });
            }
            else if (category[1] == "Management Practices" && !icons.some(element => element.icon == "/icon6.png")) {
                icons.push({ icon: "/icon6.png", description: "Management Practices" });
            }
            else if (category[1] == "Production Improvement" && !icons.some(element => element.icon == "/icon8.png")) {
                icons.push({ icon: "/icon8.png", description: "Production Improvement" });
            }
            else if (category[1] == "Occupational Health & Safety" && !icons.some(element => element.icon == "/icon5.png")) {
                icons.push({ icon: "/icon5.png", description: "Occupational Health & Safety" });
            }
            else if (category[1] == "Serious Human Rights Abuses" && !icons.some(element => element.icon == "/icon7.png")) {
                icons.push({ icon: "/icon7.png", description: "Serious Human Rights Abuses" });
            }
        }
        return icons.map((icon, i) => <Popover content={icon.description}><span ><img style={{ width: "2.75vw", hieght: "2.75vw" }} src={icon.icon} key={i} /></span></Popover>);
    }

    function getColors(text) {
        if (text === 'NGO') {
            return "purple";
        }
        if (text == "COOP") {
            return "blue";
        }
        if (text == "Exporter") {
            return "green";
        }
        if (text == "For Profit") {
            return "yellow";
        }
        else {
            return "grey";
        }
    }

    /**
     * Table search and filter functions
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    /**
     * Column definitions
     */
    const columns = [
        {
            title: 'Adaptive ID',
            dataIndex: 'id',
            key: 'adaptive_id',
            width: 'calc(5% - 8px)',
            ellipsis: true,
            sorter: (a, b) => a.id.localeCompare(b.id),
            ...getColumnSearchProps('adaptive_id'),
            render: (text) => <Row id={text} justify="left" align="middle"><span className="bene-table">{text}</span></Row>
        },
        {
            title: 'Name',
            dataIndex: ['data', 'name'],
            key: 'name',
            width: '35%',
            ellipsis: true,
            sorter: (a, b) => a.data.name.localeCompare(b.data.name),
            ...getColumnSearchProps('name'),
            render: (text, record) => (
                <Row justify="left" align="middle">
                    <Tooltip placement="topLeft" title={text} >
                        <span className="bene-table" style={{ fontWeight: 'bold' }}>
                            {text} {record.data.verified ? <CheckCircleOutlined style={{ color: 'blue' }} /> : ""}
                        </span>
                    </Tooltip>
                </Row>
            ),
        },
        {
            title: 'Facility Type',
            dataIndex: ['data', 'facility_type'],
            key: 'facility_type',
            width: 'calc(10% - 8px)',
            filters: [
                {
                    text: 'NGO',
                    value: 'NGO',
                },
                {
                    text: 'For Profit',
                    value: 'For Profit',
                },
            ],
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.data.facility_type.startsWith(value),
            ellipsis: true,
            render: (text) => (
                <Row justify="center" align="middle">
                    <div className="bene-table">
                        <Tag color={getColors(text)}>{text}</Tag>
                    </div>
                </Row>
            )
        },
        {
            title: 'Commodity',
            dataIndex: ['data', 'commodity'],
            key: 'commodity',
            width: 'calc(10% - 8px)',
            ellipsis: true,
            render: (text) => <Row justify="center" align="middle"><span className="bene-table">{text}</span></Row>
        },
        {
            title: "Country",
            dataIndex: ['data', "country"],
            key: "country",
            width: 'calc(10% - 8px)',
            ellipsis: true,
            filters: [
                {
                    text: 'Uganda',
                    value: 'Uganda',
                },
                {
                    text: 'Rwanda',
                    value: 'Rwanda',
                },
                {
                    text: 'DRC',
                    value: 'DRC',
                },
                {
                    text: 'Indonesia',
                    value: 'Indonesia',
                },
            ],
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => record.data.country.startsWith(value),
            render: text => <Row justify="center" align="middle"><span className="bene-table">{text}</span></Row>,
        },
        {
            title: 'Address',
            dataIndex: ['data', 'eth_address'],
            key: 'eth_address',
            width: 'calc(10% - 8px)',
            ...getColumnSearchProps('eth_address'),
            ellipsis: true,
            render: (text, record) => (
                <Row justify="center" align="middle">
                    {record.data.eth_address !== "" ? (
                        <Address
                            address={record.data.eth_address}
                            ensProvider={mainnetProvider}
                            blockExplorer={blockExplorer}
                            fontSize={14}
                            blockie={false}
                        />
                    ) : (<span className="bene-table">N/A</span>)}
                </Row>
            ),
        }
    ];

    const expandedRowRender = (record) => {
        const columns = [
            {
                title: 'Rationale for Impact Investment',
                dataIndex: 'data',
                key: 'description',
                width: '60%',
                render: (text, record) => (
                    <p style={{ fontSize: '96%' }}>{record.description}</p>
                )
            },
            {
                title: 'Impact Areas',
                dataIndex: 'data',
                key: 'approved_risk_treatment_areas',
                width: '20%',
                ellipsis: true,
                render: (text, record) => (
                    <Row justify="center" align="top">
                        <span >
                            {record.approved_risk_treatment_areas.length > 0 ?
                                (
                                    record.approved_risk_treatment_areas
                                ) : (
                                    <p style={{ fontSize: '99%' }}>Not Approved</p>
                                )}
                        </span>
                    </Row>
                ),
            },
            {
                title: 'Additional Info',
                dataIndex: 'data',
                key: 'website',
                width: '20%',
                ellipsis: true,
                render: () => (
                    <div >
                        <Row justify="left" align="top">
                            <p style={{ fontSize: '99%' }}>Verified: {record.kyc_verified === true ? <span> <CheckCircleOutlined style={{ color: 'blue' }} /> </span> : <CloseCircleOutlined />}</p>
                        </Row>
                        <Row justify="left" align="top">
                            {record.website ?
                                (<p style={{ fontSize: '98%' }}> Link: {" "}
                                    <a
                                        href={record.website}
                                        target="blank"
                                    >
                                        Website
                                    </a></p>
                                ) : ("")}
                        </Row>
                    </div>
                )
            }
        ];
        const data = [];
        data.push({
            key: '1',
            description: record.data.description,
            approved_risk_treatment_areas: getIcons(record.data),
            kyc_verified: record.data.kyc_verified,
            website: record.data.website,
        });

        return <Table bordered columns={columns} dataSource={data} pagination={false} />;
    };


    return (
        <div style={{ minHeight: '100vh', marginLeft: '12px', marginRight: '12px', marginBottom: '36px' }}>
            <Row justify="left" style={{ marginLeft: '2vw' }}>
                <h1 style={{ color: "#203864", marginTop: '30px' }}>Beneficiary Accounts</h1>
            </Row>
            <Row justify="left" style={{ marginBottom: '15px', marginLeft: '2vw' }}>
                <Col>
                    <p style={{ fontSize: '99%', textAlign: 'left' }}>
                        Expand rows to view additional details about Adaptive Beneficiary organizations.
                    </p>
                </Col>
            </Row>
            <Row justify='center'>
                <Col span={24}>
                    <Card hoverable={true} bodyStyle={{ padding: "0" }}>
                        < Table
                            bordered
                            dataSource={beneficiaryData}
                            size="small"
                            columns={columns}
                            pagination={false}
                            scroll={{ x: '1250px' }}
                            expandable={{
                                expandedRowRender,
                                defaultExpandedRowKeys: ['0'],
                                rowExpandable: record => record.data.description != "",
                                columnWidth: '50px',
                            }}
                            rowKey={"id"}
                        />
                    </Card>
                </Col>
            </Row>
        </div >
    );
}