import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import 'mapbox-gl/dist/mapbox-gl.css';
import './index.css';
import App from './App';

let subgraphUri = "http://localhost:8000/subgraphs/name/scaffold-eth/claim-token"

const client = new ApolloClient({
  uri: subgraphUri,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
