import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Account from "./Account";
import Balance from "./Balance";
import { getAuth } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import "./Profile.css";
import { db, updateEmailSettings } from "./auth/Firebase";
import { Card, Button, Col, Row, Checkbox, Modal, Divider } from "antd";
import { HolderOutlined } from "@ant-design/icons";
import LogoutAuth from "./auth/LogoutAuth";
import FiatPaymentsDashboard from "./payments/FiatPaymentsDashboard";
import AccountSetupStatus from "./onboarding/AccountSetupStatus";
import TransactionHistoryTable from "./payments/TransactionHistoryTable";
import USDCBalance from "./USDCBalance";

export default function Profile({
  userCards,
  bankAccts,
  userType,
  primaryContactName,
  emailNotifications,
  setEmailNotifications,
  dataView,
  additionalRequirementsTrigger,
  createClaimBusinessActivityKYCTrigger,
  createClaimManagementKYCTrigger,
  createClaimResponsibleSupplyChainKYC,
  createClaimSignedAcknowledgementKYCTrigger,
  beneficiaryNGOKYCTrigger,
  buyerKYCTrigger,
  address,
  localProvider,
  mainnetProvider,
  price,
  web3Modal,
  loadWeb3Modal,
  blockExplorer,
  logoutOfWeb3Modal,
}) {
  const auth = getAuth();

  const navigate = useNavigate();

  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) return <h2 >Loading...</h2>;
    if (!user) return <Link to="/login"></Link>;
  }, [user, loading]);

  /* Modal for selecting bank account type */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBankModalOpen, setIsBankModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showBankModal = () => {
    setIsBankModalOpen(true);
  };

  const handleBankCancel = () => {
    setIsBankModalOpen(false);
  };

  return (
    <div className="container">
      <div style={{ margin: '30px' }}>
        <Row align="middle" style={{ marginBottom: '30px' }}>
          <Col xs={6} sm={6} md={5} lg={4} xl={2}>
            <Button
              className="map-btn-profile"
              onClick={() => {
                navigate("/your-tokens")
              }}
              shape="round"
              size="large"
            >
              <HolderOutlined style={{ fontSize: '18px' }} />Your Tokens
            </Button>
          </Col>
          <Col xs={9} sm={12} md={14} lg={16} xl={20}></Col>
          <Col xs={9} sm={6} md={5} lg={4} xl={2}>
            <LogoutAuth />
          </Col>
        </Row>

        <Row justify='center' style={{ marginBottom: '1%' }} gutter={[12, 12]}>
          <Col xs={23} sm={23} md={8} lg={8} xl={8}>
            <Card style={{ borderRadius: '12px', height: '204px' }} size='small'
              title={
                <p className="user-info-title">Account Info</p>
              }
              extra={
                <div className="user-info">
                  <span >
                    <Link to="/update-user-profile">
                      Edit
                    </Link>
                  </span>
                </div>
              }
            >
              <Row style={{ marginBottom: '4px' }}>
                {user ?
                  (<span><span className="account-info">User: </span><span className="user-info">{primaryContactName}</span></span>)
                  : ("")}
              </Row >
              <Row style={{ marginBottom: '4px' }}>
                {user ?
                  (<span > <span className="account-info">Email: </span><span className="user-info">{user.email}</span></span>)
                  : ("")}
              </Row>
              <Row style={{ marginBottom: '4px' }}>
                {db ?
                  (<div > <span className="account-info">Auth level: </span><span className="user-info">{userType}</span></div>)
                  : ("")}
              </Row >
              <Row style={{ marginBottom: '4px' }}>
                <div>
                  {emailNotifications == true || emailNotifications == false ?
                    <span className="account-info">
                      Email notifications? {" "}
                      <Checkbox defaultChecked={emailNotifications} onChange={() => {
                        setEmailNotifications(emailNotifications => !emailNotifications)
                        updateEmailSettings(user.uid, !emailNotifications)
                      }} />
                    </span>
                    :
                    ""
                  }
                </div>
              </Row>
              <Row >
                {dataView !== undefined ?
                  (<div > <span className="account-info">App View: </span><span className="user-info">{dataView}</span></div>)
                  : ("")}
              </Row>
            </Card >
          </Col >
          <Col xs={23} sm={23} md={8} lg={8} xl={8}>
            <Card style={{ borderRadius: '12px', height: '204px' }} size='small'
              title={userType !== "auditor" && userType !== null ?
                <p className="user-info-title">Payment Options</p>
                :
                <p className="user-info-title">Connect Wallet</p>
              }
              extra={
                <div className="user-info">
                  <span >
                    <a href="https://adaptive-resources.gitbook.io/adaptive-resources-docs/user-guides/buy-claim-tokens/buying-using-fiat" target='blank'>
                      Docs
                    </a>
                  </span>
                </div>
              }
            >
              <div>
                {address ? (
                  <Row justify="center" align={'middle'} style={{ paddingBottom: '12px' }}>
                    <span style={{ fontSize: '18px' }}>
                      <Row align={'middle'}>
                        <span style={{ fontWeight: 'bold', paddingRight: '8px' }}>ETH</span><Balance address={address} provider={localProvider} price={price} />
                      </Row>
                      <Row align={'middle'}>
                        <span style={{ fontWeight: 'bold', paddingRight: '8px' }}>USDC</span><USDCBalance address={address} provider={localProvider} pollTime={50000} />
                      </Row>
                    </span>
                  </Row>
                ) : (
                  <Row justify="center">
                    <FiatPaymentsDashboard
                      userCards={userCards}
                      bankAccts={bankAccts}
                      userType={userType}
                      showModal={showModal}
                      showBankModal={showBankModal}
                    />
                  </Row>
                )}
                <div >
                  <Account
                    address={address}
                    localProvider={localProvider}
                    mainnetProvider={mainnetProvider}
                    price={price}
                    web3Modal={web3Modal}
                    loadWeb3Modal={loadWeb3Modal}
                    logoutOfWeb3Modal={logoutOfWeb3Modal}
                    blockExplorer={blockExplorer}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={23} sm={23} md={8} lg={8} xl={8}>
            <Card style={{ borderRadius: '12px', height: '204px' }} size='small'
              title={
                <p className="user-info-title">Account Status</p>
              }
              extra={
                <div className="user-info">
                  <span >
                    <a href="https://adaptive-resources.gitbook.io/adaptive-resources-docs/" target='blank'>
                      Info
                    </a>
                  </span>
                </div>
              }
            >
              <AccountSetupStatus
                userType={userType}
                /*additional-info*/
                additionalRequirementsTrigger={additionalRequirementsTrigger}
                /*creator*/
                createClaimBusinessActivityKYCTrigger={createClaimBusinessActivityKYCTrigger}
                createClaimManagementKYCTrigger={createClaimManagementKYCTrigger}
                createClaimResponsibleSupplyChainKYC={createClaimResponsibleSupplyChainKYC}
                createClaimSignedAcknowledgementKYCTrigger={createClaimSignedAcknowledgementKYCTrigger}
                /*beneficiary_ngo*/
                beneficiaryNGOKYCTrigger={beneficiaryNGOKYCTrigger}
                /*buyer*/
                buyerKYCTrigger={buyerKYCTrigger}
              />
            </Card>
          </Col>
        </Row >

        <Row justify="center" style={{ paddingTop: '1%' }}>
          <Col span={24}>
            <div style={{ textAlign: 'left', marginBottom: '18px' }}><h4 >Transaction History</h4></div>
            <TransactionHistoryTable />
          </Col>
        </Row>

        <Modal title="" open={isModalOpen} onCancel={handleCancel} footer={null}>
          <h3 >Select Bank Account Type</h3>
          <Divider />
          {/* BINUSU BANK ACCOUNTS ARE NOT SUPPORTED
            <Card className="signup-bank-button" onClick={() => { navigate("/binusu-user-signup") }}><h4 className="bank-btn-text">African Bank Account - East Africa</h4></Card>
          */}
          <Card className="signup-bank-button" onClick={() => { navigate("/circle-user-usbank-signup") }}><h4 className="bank-btn-text">US Bank Account</h4></Card>
          <Card className="signup-bank-button" onClick={() => { navigate("/circle-user-non-us-iban-signup") }}><h4 className="bank-btn-text">NON US Bank Account - IBAN Supported</h4></Card>
          <Card className="signup-bank-button" onClick={() => { navigate("/circle-user-non-us-non-iban-signup") }}><h4 className="bank-btn-text">NON US Bank Account - IBAN Not Supported</h4></Card>
        </Modal>

        <Modal title="" open={isBankModalOpen} onCancel={handleBankCancel} footer={null}>
          <h3 >Account Funding</h3>
          <Divider />
          <Card className="signup-bank-button" onClick={() => { navigate("/binusu-user-signup") }}><h4 className="bank-btn-text">Withdraw USDC</h4></Card>
          <Card className="signup-bank-button" onClick={() => { navigate("/circle-user-usbank-signup") }}><h4 className="bank-btn-text">Buy USDC</h4></Card>
          <Card className="signup-bank-button" onClick={() => { navigate("/circle-user-non-us-iban-signup") }}><h4 className="bank-btn-text">Recieve USDC</h4></Card>
        </Modal>
      </div >
    </div >
  );
};