import { Col, Row, Button } from 'antd';
import React, { useState } from 'react';

import { DueDiligenceForm, PreFinanceForm } from '../components';

export default function CreateTokens({
    beneData,
    coopData,
    mainnetProvider,
    tx,
    writeContracts,
    address,
    userType,
    additionalRequirementsTrigger,
    createClaimBusinessActivityKYCTrigger,
    createClaimManagementKYCTrigger,
    createClaimResponsibleSupplyChainKYC,
    createClaimSignedAcknowledgementKYCTrigger,
}) {
    const [form, setForm] = useState(false);

    const handleButtonClick = (newForm) => {
        setForm(newForm);
    };

    return (
        <div style={{ minHeight: '100vh' }}>
            <Row justify="center" style={{ marginTop: '24px' }}>
                <Col
                    xs={20}
                    sm={14}
                    md={10}
                    lg={8}
                    xl={6}
                    style={{
                        backgroundColor: '#24458350',
                        padding: '10px',
                        borderRadius: '12px',
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        borderColor: '#24458350',
                    }}
                >
                    <p style={{ fontSize: '18px', marginBottom: '8px', fontWeight: 'bold' }}>Select Token Builder</p>
                    <Button.Group>
                        <Button
                            disabled={form ? true : false}
                            type='primary'
                            onClick={() => handleButtonClick(true)}>
                            Due Diligence
                        </Button>
                        <Button
                            disabled={form ? false : true}
                            type='primary'
                            onClick={() => handleButtonClick(false)}>
                            Pre-Finance
                        </Button>
                    </Button.Group>
                </Col>
            </Row>
            <Row justify="center">
                <Col xs={24} sm={24} md={22} lg={20} xl={20}>
                    {/**create claim token form*/}
                    <div style={{ display: form ? "block" : "none" }} >
                        <DueDiligenceForm
                            beneData={beneData}
                            mainnetProvider={mainnetProvider}
                            tx={tx}
                            writeContracts={writeContracts}
                            address={address}
                            userType={userType}
                            additionalRequirementsTrigger={additionalRequirementsTrigger}
                            createClaimBusinessActivityKYCTrigger={createClaimBusinessActivityKYCTrigger}
                            createClaimManagementKYCTrigger={createClaimManagementKYCTrigger}
                            createClaimResponsibleSupplyChainKYC={createClaimResponsibleSupplyChainKYC}
                            createClaimSignedAcknowledgementKYCTrigger={createClaimSignedAcknowledgementKYCTrigger}
                        />
                    </div>
                    <div style={{ display: form ? "none" : "block" }} >
                        <PreFinanceForm
                            beneData={beneData}
                            mainnetProvider={mainnetProvider}
                            tx={tx}
                            writeContracts={writeContracts}
                            address={address}
                            userType={userType}
                            additionalRequirementsTrigger={additionalRequirementsTrigger}
                            createClaimBusinessActivityKYCTrigger={createClaimBusinessActivityKYCTrigger}
                            createClaimManagementKYCTrigger={createClaimManagementKYCTrigger}
                            createClaimResponsibleSupplyChainKYC={createClaimResponsibleSupplyChainKYC}
                            createClaimSignedAcknowledgementKYCTrigger={createClaimSignedAcknowledgementKYCTrigger}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

