import React, { useState } from 'react';
import { CheckCircleOutlined } from "@ant-design/icons"
import './../Profile.css';
import { Table, Row } from 'antd';

const DEBUG = false;

export default function TransactionHistoryTable(status) {

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            ellipsis: true,
            render: text => <Row justify="left" align="middle"><p style={{ fontWeight: 'bold' }}>{text}</p></Row>,
            //responsive: ['xs'],
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            ellipsis: true,
            render: (cooperative) => (
                <span>{cooperative}</span>
            ),
        },
        {
            title: "Type",
            dataIndex: "paymentType",
            key: "payment-type",
            ellipsis: true,
            render: text => <Row justify="center" align="middle"><p >{text}</p></Row>,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'payment-description',
            ellipsis: true,
            render: text => <Row justify="center" align="middle"><p >{text}</p></Row>,
            //responsive: ['xs'],
        },
        {
            title: 'Status',
            key: 'status',
            ellipsis: true,
            render: (status) => (<h6 >{status}</h6>),
        }
    ];

    return (
        <div>
            <Table className="ant-table-affix-wrapper" columns={columns} />
        </div>
    )
}
