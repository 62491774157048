import { Button, Form, InputNumber, Select, Row } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

export default function DynamicFieldPurities({ purityType, purityPercent, claimType }) {

  const { Option } = Select;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8, pull: 2 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  return (
    <div >
      <Form.List
        name="purities"
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? '' : ''}
                key={field.key}
              >
                <Row align="center">
                  <Select
                    placeholder="Select Mineral"
                    style={{ width: '70%' }}
                    onChange={(value) => {
                      purityType[index] = value;
                    }}>
                    <Option value="Gold (Au)" >Gold (Au)</Option>
                    <Option value="Silver (Ag)" >Silver (Ag)</Option>
                    <Option value="Boron aluminide (Bal)" >Boron aluminide (Bal)</Option>
                    <Option value="Cadmium (Cd)" >Cadmium (Cd)</Option>
                    <Option value="Copper (Cu)" >Copper (Cu)</Option>
                    <Option value="Iron (Fe)" >Iron (Fe)</Option>
                    <Option value="Iron oxide (Fe203)" >Iron oxide (Fe203)</Option>
                    <Option value="Manganese (Mn)" >Manganese (Mn)</Option>
                    <Option value="Niobium (Nb)" >Niobium (Nb)</Option>
                    <Option value="Lead (Pb)" >Lead (Pb)</Option>
                    <Option value="Platinum (Pt)" >Platinum (Pt)</Option>
                    <Option value="Tin (Sn) " >Tin (Sn) </Option>
                    <Option value="Tin oxide (SnO2)" >Tin oxide (SnO2)</Option>
                    <Option value="Tantalum (Ta)" >Tantalum (Ta)</Option>
                    <Option value="Tantalum oxide (Ta205)" >Tantalum oxide (Ta205)</Option>
                    <Option value="Titanium (Ti)" >Titanium (Ti)</Option>
                    <Option value="Thorium dioxide (ThO2)" >Thorium dioxide (ThO2)</Option>
                    <Option value="Tungsten (W)">Tungsten (W)</Option>
                    <Option value="Tungsten trioxide (WO3)" >Tungsten trioxide (WO3)</Option>
                    <Option value="Uranium oxide (U3O8)" >Uranium oxide (U3O8)</Option>
                    <Option value="Zirconium (Zr)" >Zirconium (Zr)</Option>
                  </Select>

                  <InputNumber
                    onChange={(value) => {
                      purityPercent[index] = value;
                    }}
                    style={{ width: '20%' }}

                    min={0.01}
                    max={99.99}
                    placeholder="(%)"
                  />
                  {
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() =>
                        remove(field.name)
                      }
                      style={{ marginLeft: '6px', marginTop: '8px' }}
                    />
                  }
                </Row>
              </Form.Item>
            ))}
            <Button
              type="dashed"
              onClick={() => add()}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
          </>
        )}
      </Form.List>
    </div >
  );
}