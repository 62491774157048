import { Button, Form, Input, Select, Row } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

export default function DynamicFieldCertificates({ certificationType, certificationNumber }) {

  const { Option } = Select;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  return (
    <div >
      <Form.List
        name="certificates"
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? '' : ''}
                required={false}
                key={field.key}
              >
                <Row align="center">
                  <Select
                    placeholder="Select Certificate"
                    style={{ width: '60%' }}
                    onChange={(value) => {
                      certificationType[index] = value
                    }}>
                    <Option value="ICGLR Certificate" >ICGLR Certificate</Option>
                    <Option value="CRAFT Scheme" >CRAFT Scheme</Option>
                    <Option value="Fairmined" >Fairmined</Option>
                    <Option value="Fairtrade" >Fairtrade</Option>
                    <Option value="BSP" >BSP</Option>
                    <Option value="iTSCi" >iTSCi</Option>
                    <Option value="EGC" >EGC</Option>
                    <Option value="ASM Cobalt Framework" >ASM Cobalt Framework</Option>
                    <Option value="Certified Trading Chains" >Certified Trading Chains</Option>
                  </Select>

                  <Input onChange={(e) => {
                    certificationNumber[index] = e.target.value
                  }}
                    style={{ width: '30%' }}
                    placeholder="Identifier (#)"
                  />
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() =>
                        remove(field.name)
                      }
                      style={{ marginLeft: '6px', marginTop: '8px' }}
                    />
                  ) : null}
                </Row>
              </Form.Item>
            ))}

            <Button
              type="dashed"
              onClick={() => add()}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
          </>
        )}
      </Form.List>
    </div>
  );
}