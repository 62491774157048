import React from 'react';
import { Input, Select, Row, Alert, Col } from 'antd';
import { FormatPainterFilled, ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';
import "../../App.css";

const DEBUG = false;

const ColorFilters = ({
    colorsFilterDisplayed,
    setColorsFilterDisplayed,
    removeToolsFromCanvas,
    setAddFormDisplayed,
    setFilterDisplayed,
    setT0Color,
    t0Color,
    setTMaxDepthColor,
    tMaxDepthColor,
    countryFilter,
    filterByCountry,
    setSelectedCountry,
    selectedCountry,
    targetCountryColor,
    setTargetCountryColor,
    productFilter,
    filterByProduct,
    selectedProduct,
    setSelectedProduct,
    targetProductColor,
    setTargetProductColor,
    getElements,
    getLayoutedElements,
    currentDirection,
    setNodes,
    setEdges,
    hierarchyData,
    changeFilterColor
}) => {
    // hide/ unhide menu
    const reveal = () => colorsFilterDisplayed ? setColorsFilterDisplayed(false) : setColorsFilterDisplayed(true);
    // button
    return (
        <div className='sidebar-trace-left-3'>
            <button
                onClick={() => {
                    setAddFormDisplayed(false)
                    setFilterDisplayed(false);
                    reveal();
                }}
                style={{ display: removeToolsFromCanvas }}
                className="flow-button"
                id="add-button"
            >
                <FormatPainterFilled style={{ fontSize: '16px' }} />
            </button>
            {colorsFilterDisplayed ?
                <ColorsForm
                    setT0Color={setT0Color}
                    t0Color={t0Color}
                    setTMaxDepthColor={setTMaxDepthColor}
                    tMaxDepthColor={tMaxDepthColor}
                    countryFilter={countryFilter}
                    filterByCountry={filterByCountry}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    targetCountryColor={targetCountryColor}
                    setTargetCountryColor={setTargetCountryColor}
                    productFilter={productFilter}
                    filterByProduct={filterByProduct}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    targetProductColor={targetProductColor}
                    setTargetProductColor={setTargetProductColor}
                    getElements={getElements}
                    getLayoutedElements={getLayoutedElements}
                    currentDirection={currentDirection}
                    setNodes={setNodes}
                    setEdges={setEdges}
                    hierarchyData={hierarchyData}
                    changeFilterColor={changeFilterColor}
                /> : null
            }
        </div>
    );
};


const ColorsForm = ({
    setT0Color,
    t0Color,
    setTMaxDepthColor,
    tMaxDepthColor,
    countryFilter,
    filterByCountry,
    selectedCountry,
    setSelectedCountry,
    targetCountryColor,
    setTargetCountryColor,
    productFilter,
    filterByProduct,
    selectedProduct,
    setSelectedProduct,
    targetProductColor,
    setTargetProductColor,
    getElements,
    getLayoutedElements,
    currentDirection,
    setNodes,
    setEdges,
    hierarchyData,
}) => {

    // handle submit
    function handleSubmit(event) {
        event.preventDefault();

        const nextElements = getElements()

        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            nextElements.nodes,
            nextElements.edges,
            currentDirection
        );

        setNodes([...layoutedNodes]);
        setEdges([...layoutedEdges]);
    }

    // create countriesList of countries in .csv file.
    const children = hierarchyData ? (hierarchyData.descendants()) : ([]);
    let countriesList = [];
    for (let i = 0; i < children.length; i++) {
        if (countriesList.findIndex(option => option.label == children[i].data["country"]) == -1) {
            countriesList.push({ label: children[i].data["country"], value: children[i].data["country"] });
        }
    }
    // create optionsList of countries in .csv file.
    let productsList = [];
    for (let i = 0; i < children.length; i++) {
        if (productsList.findIndex(option => option.label == children[i].data["product"]) == -1) {
            productsList.push({ label: children[i].data["product"], value: children[i].data["product"] });
        }
    }

    const handleCountryChange = (value) => {
        if (DEBUG) console.log(`selected ${value}`);
        setSelectedCountry(value);
    };

    const handleProductChange = (value) => {
        if (DEBUG) console.log(`selected ${value}`);
        setSelectedProduct(value);
    };

    return (
        <div className='color-utils-form'>
            <div>
                <h5 style={{ marginBottom: '12px' }}>Color Options</h5>
            </div>
            <div className='tier-colors-container'>
                <div style={{ padding: '4px', color: '#cfd8dc' }}>Color Gradient</div>
                <Row align='middle' justify='center'>
                    <Input
                        className='tier-colors-form-input'
                        id="name"
                        type='color'
                        value={t0Color}
                        onChange={(e) => setT0Color(e.target.value)}
                    />
                    <span ><ArrowRightOutlined /></span>
                    <Input
                        className='tier-colors-form-input'
                        id="name"
                        type='color'
                        value={tMaxDepthColor}
                        onChange={(e) => setTMaxDepthColor(e.target.value)}
                    />
                    <button className='submit-color-tier-form-button' onClick={handleSubmit}><CheckOutlined /></button>
                </Row>
            </div>
            <div className='tier-colors-container'>
                <div style={{ padding: '0px', color: '#cfd8dc' }}>Filters</div>
                <button
                    className='filter-menu-button-country'
                    onClick={(e) => countryFilter(e.target.value)}
                    disabled={filterByProduct ? true : false}
                >
                    Country
                </button>
                <button
                    className='filter-menu-button-prod'
                    onClick={(e) => productFilter(e.target.value)}
                    disabled={filterByCountry ? true : false}
                >
                    Product
                </button>

                <div style={{ backgroundColor: '#2d335355', borderRadius: '8px', margin: '4px', padding: '4px' }}>
                    <Row justify='center' align='middle' style={{ marginTop: '8px' }}>
                        {!filterByCountry && !filterByProduct ? <Alert description="Select Filter" type='info' showIcon /> : ""}
                    </Row>
                    <Row justify='center' align='middle' style={{ marginBottom: '8px' }}>
                        <div style={{ display: filterByCountry ? 'block' : 'none', width: '100%' }}>
                            <Select
                                style={{
                                    width: '70%',
                                    background: '#fff'
                                }}
                                size={'small'}
                                value={selectedCountry}
                                options={countriesList}
                                placeholder="Country"
                                onChange={handleCountryChange}
                                bordered={false}
                                disabled={filterByCountry ? false : true}
                            />
                            <Input
                                style={{
                                    width: '25%',
                                    padding: '1px',
                                    marginLeft: '4px'
                                }}
                                size={'small'}
                                id="colorInput"
                                type='color'
                                value={targetCountryColor}
                                onChange={(e) => { setTargetCountryColor(e.target.value) }}
                            />
                        </div>
                    </Row>
                    <Row justify='center' align='middle' style={{ marginBottom: '8px' }}>
                        <div style={{ display: filterByProduct ? 'block' : 'none', width: '100%' }}>
                            <Select
                                style={{
                                    width: '70%',
                                    background: '#fff'
                                }}
                                size={'small'}
                                value={selectedProduct}
                                options={productsList}
                                placeholder="Product"
                                onChange={handleProductChange}
                                bordered={false}
                            />
                            <Input
                                style={{
                                    width: '25%',
                                    padding: '1px',
                                    marginLeft: '4px'
                                }}
                                size={'small'}
                                id="colorInput"
                                type='color'
                                value={targetProductColor}
                                onChange={(e) => { setTargetProductColor(e.target.value) }}
                            />
                        </div>
                    </Row>
                </div>
            </div>
        </div >
    );
};

export default ColorFilters;