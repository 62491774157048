// psuedo data for the dashboard components
export const dataFake = [
    {
        name: 'Jan 9',
        tokens: 13,
    },
    {
        name: 'Jan 16',
        tokens: 3,
    },
    {
        name: 'Jan 23',
        tokens: 10,
    },
    {
        name: 'Jan 30',
        tokens: 5,
    },
    {
        name: 'Feb 6',
        tokens: 8,
    },
    {
        name: 'Feb 13',
        tokens: 15,
    },
    {
        name: 'Feb 20',
        tokens: 9,
    },
    {
        name: 'Feb 27',
        tokens: 7,
    },
    {
        name: 'Mar 6',
        tokens: 10,
    },
    {
        name: 'Mar 13',
        tokens: 3,
    },
    {
        name: 'Mar 20',
        tokens: 4,
    },
    {
        name: 'Mar 27',
        tokens: 6,
    },
    {
        name: 'Apr 3',
        tokens: 5,
    },
    {
        name: 'Apr 10',
        tokens: 3,
    },
    {
        name: 'Apr 17',
        tokens: 4,
    },
    {
        name: 'Apr 24',
        tokens: 7,
    },
    {
        name: 'Mar 1',
        tokens: 3,
    },
    {
        name: 'Mar 8',
        tokens: 8,
    },
];

export const dataFake2 = [
    {
        name: 'Jan 9',
        USD: 400,
    },
    {
        name: 'Jan 16',
        USD: 300,
    },
    {
        name: 'Jan 23',
        USD: 1000,
    },
    {
        name: 'Jan 30',
        USD: 900,
    },
    {
        name: 'Feb 6',
        USD: 1400,
    },
    {
        name: 'Feb 13',
        USD: 1500,
    },
    {
        name: 'Feb 20',
        USD: 600,
    },
    {
        name: 'Feb 27',
        USD: 700,
    },
    {
        name: 'Mar 6',
        USD: 1000,
    },
    {
        name: 'Mar 13',
        USD: 1500,
    },
    {
        name: 'Mar 20',
        USD: 1600,
    },
    {
        name: 'Mar 27',
        USD: 1400,
    },
    {
        name: 'Apr 3',
        USD: 1100,
    },
    {
        name: 'Apr 10',
        USD: 700,
    },
    {
        name: 'Apr 17',
        USD: 700,
    },
    {
        name: 'Apr 24',
        USD: 800,
    },
    {
        name: 'Mar 1',
        USD: 900,
    },
    {
        name: 'Mar 8',
        USD: 800,
    },
];

export const dataBeneFake = [
    {
        name: '0x7382',
        tokens: 4,
    },
    {
        name: 'DevSol',
        tokens: 6,
    },
    {
        name: 'Mubende Human Rights',
        tokens: 8,
    },
    {
        name: 'Uganda Red Cross',
        tokens: 7,
    },
    {
        name: 'Land and Equity Movement in Uganda',
        tokens: 10,
    },
    {
        name: 'Uganda Law Council',
        tokens: 14,
    },
    {
        name: '0x6748',
        tokens: 4,
    },
    {
        name: '0x5526',
        tokens: 4,
    },
    {
        name: '0x1094',
        tokens: 4,
    },
    {
        name: '0x2384',
        tokens: 2,
    },
];

export const payoutStructure = [
    {
        name: 'Seller',
        tokens: 30,
        description: "Goes to support supply chain due diligence costs and to encourage sales of tokens multiple times. These are the approved supply chain actors including, Exporters, Refiners, or Manufacturers. These actors can only sell a token one time each per batch."
    },
    {
        name: 'Protocol',
        tokens: 10,
        description: "Goes to aid support system costs and operational overhead for the protocol implementation."
    },
    {
        name: 'Beneficiary 1',
        tokens: 15,
        description: "Goes to selected risk management beneficiaries to support designated risk areas."
    },
    {
        name: 'Beneficiary 2',
        tokens: 15,
        description: "Goes to selected risk management beneficiaries to support designated risk areas."
    },
    {
        name: 'Cooperative',
        tokens: 30,
        description: "Goes to support the highest priority site level risks or for improved wages for workers."
    },
];

export const dataFakeBeneficiaries = [
    {
        name: 'Jan 9',
        USD: 2130,
    },
    {
        name: 'Jan 16',
        USD: 309,
    },
    {
        name: 'Jan 23',
        USD: 150,
    },
    {
        name: 'Jan 30',
        USD: 500,
    },
    {
        name: 'Feb 6',
        USD: 1400,
    },
    {
        name: 'Feb 13',
        USD: 1684,
    },
    {
        name: 'Feb 20',
        USD: 1395,
    },
    {
        name: 'Feb 27',
        USD: 532,
    },
    {
        name: 'Mar 6',
        USD: 964,
    },
    {
        name: 'Mar 13',
        USD: 1206,
    },
    {
        name: 'Mar 20',
        USD: 1984,
    },
    {
        name: 'Mar 27',
        USD: 1854,
    },
    {
        name: 'Apr 3',
        USD: 954,
    },
    {
        name: 'Apr 10',
        USD: 1165,
    },
    {
        name: 'Apr 17',
        USD: 260,
    },
    {
        name: 'Apr 24',
        USD: 1095,
    },
    {
        name: 'Mar 1',
        USD: 1254,
    },
    {
        name: 'Mar 8',
        USD: 387,
    },
];

export const SBGMineData = [
    {
        key: '1',
        mineName: 'East Africa Mine 1',
        location: 'East Africa',
        status: 'Active',
        lastUpdated: '2023-05-12',
    },
    {
        key: '2',
        mineName: 'East Africa Mine 2',
        location: 'East Africa',
        status: 'Inactive',
        lastUpdated: '2023-04-30',
    },
    {
        key: '3',
        mineName: 'East Africa Mine 3',
        location: 'East Africa',
        status: 'Exploration Permit',
        lastUpdated: '2023-05-01',
    },
    {
        key: '4',
        mineName: 'East Africa Mine 4',
        location: 'East Africa',
        status: 'Active',
        lastUpdated: '2023-05-12',
    },
    {
        key: '5',
        mineName: 'East Africa Mine 5',
        location: 'East Africa',
        status: 'Inactive',
        lastUpdated: '2023-04-30',
    },
];

export default { dataFake, dataFake2, dataBeneFake, payoutStructure, dataFakeBeneficiaries, SBGMineData };