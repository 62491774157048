import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import "./UpdateProfile.css";
import { auth, updateBasicUserData } from "./Firebase";
import { Button, Input, Row, Col, Form, Card } from "antd";
import { getDatabase, ref, child, get } from "firebase/database";

const DEBUG = false;

function UpdateUserProfile({ }) {
    const [form] = Form.useForm();

    const dbRef = ref(getDatabase());

    const [user, loading, error] = useAuthState(auth);

    const [uid, setUID] = useState("");
    const [primaryContactName, setPrimaryContactName] = useState("");
    const [contactPhoneNum, setContactPhoneNum] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [businessAddr, setBusinessAddr] = useState("");
    const [otherOfficeAddr, setOtherOfficeAddr] = useState("");
    const [website, setWebsite] = useState("");

    useEffect(() => {
        if (loading) return;

        get(child(dbRef, `users/${user.uid}`)).then((snapshot) => {
            if (snapshot.exists()) {
                setUID(user.uid)
                setPrimaryContactName(snapshot.val().primaryContactName);
                setContactPhoneNum(snapshot.val().contactPhoneNum);
                setBusinessName(snapshot.val().businessName);
                setBusinessAddr(snapshot.val().businessAddr);
                setOtherOfficeAddr(snapshot.val().otherOfficeAddr);
                setWebsite(snapshot.val().website);
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

    }, []);

    const onFinish = async (values) => {
        if (DEBUG) console.log('Success:', values);
        const res = await updateBasicUserData(
            uid,
            primaryContactName,
            contactPhoneNum,
            businessName,
            businessAddr,
            otherOfficeAddr,
            website,
        )
        if (res === true) {
            alert("User data updated successfully!");
        } else {
            alert("Error updating user data!");
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="createuser">
            <h1 style={{ color: "#203864", margin: '30px' }}>Update Account Info</h1>
            <Row justify="center">
                <Col xs={23} sm={23} md={22} lg={18} xl={14}>
                    <Card
                        hoverable={true}
                        // cannot click card body
                        style={{ background: '#EBEBEB99', borderRadius: '18px', marginBottom: '16px', cursor: 'default' }}
                    >
                        <Form
                            form={form}
                            className="create-claim-form"
                            name="update-profile"
                            layout="horizontal"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            labelWrap
                            fields={[
                                {
                                    name: ['username'],
                                    value: primaryContactName,
                                },
                                {
                                    name: ['phone'],
                                    value: contactPhoneNum,
                                },
                                {
                                    name: ['businessName'],
                                    value: businessName,
                                },
                                {
                                    name: ['registeredAddr'],
                                    value: businessAddr,
                                },
                                {
                                    name: ['otherAddr'],
                                    value: otherOfficeAddr,
                                },
                                {
                                    name: ['website'],
                                    value: website,
                                },
                            ]}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            colon={false}
                        >
                            <Form.Item
                                name="username"
                                label="Primary Contact Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    onChange={(e) => setPrimaryContactName(e.target.value)}
                                    placeholder="Primary Contact Name"
                                />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                label="Primary Phone Number"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your phone number!',
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    value={contactPhoneNum}
                                    onChange={(e) => setContactPhoneNum(e.target.value)}
                                    placeholder="Phone Number"
                                />
                            </Form.Item>
                            <Form.Item
                                name="businessName"
                                label="Business Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your business name!',
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    value={businessName}
                                    onChange={(e) => setBusinessName(e.target.value)}
                                    placeholder="Business Name"
                                />
                            </Form.Item>
                            <Form.Item
                                name="registeredAddr"
                                label="Registered Business Address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your registered business address!',
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    value={businessAddr}
                                    onChange={(e) => setBusinessAddr(e.target.value)}
                                    placeholder="Registered/Business Address"
                                />
                            </Form.Item>
                            <Form.Item
                                name="otherAddr"
                                label="Other Office and/or Plant Addresses"
                            >
                                <Input
                                    type="text"
                                    value={otherOfficeAddr}
                                    onChange={(e) => setOtherOfficeAddr(e.target.value)}
                                    placeholder="Address(es) of Other Offices and Plants"
                                />
                            </Form.Item>
                            <Form.Item
                                name="website"
                                label="Company Website URL"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your webiste url!',
                                    },
                                ]}
                                initialValue={website}
                            >
                                <Input
                                    type="text"
                                    value={website}
                                    onChange={(e) => setWebsite(e.target.value)}
                                    placeholder="New Website URL"
                                />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{ span: 24 }}
                            >
                                {user ?
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        shape='round'
                                        size="large"
                                        style={{ fontSize: '16px' }}
                                    >
                                        Save
                                    </Button>
                                    : ""
                                }
                            </Form.Item>
                        </Form>
                        <Row justify="center">
                            <Link to="/profile">Back to Profile</Link>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div >
    );
}
export default UpdateUserProfile;