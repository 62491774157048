import React from "react";
import { Button } from "antd";
import { logout } from "./Firebase";


const LogoutAuth = () => {
  return (
    <Button
      className="logout-btn-profile"
      shape="round"
      size="large"
      onClick={() => { logout() }}
    >
      Sign Out
    </Button>
  );
};

export default LogoutAuth;