import React from 'react';
import { Handle } from 'react-flow-renderer';
import '../../App.css'

const CustomInput = ({ data, isConnectable, sourcePosition }) => {
    return (
        <div className='node-input'>
            <div >
                <h3 style={{ margin: 3, textAlign: 'center', color: "#fff", fontSize: 24, marginBottom: 12, marginTop: 14, marginRight: 6, marginLeft: 6 }}>
                    {data.label}
                </h3>
            </div>
            <Handle
                type="source"
                position={sourcePosition}
                id="b"
                style={{ background: '#B2B4B8', width: '12px', height: '12px' }}
                isConnectable={isConnectable}
            />
        </div>
    );
};

export default CustomInput;