import { getApp, initializeApp } from "firebase/app";
import { getStorage, uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSENGER_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMNT_ID,
};

let storage;
// init firebase services
let app;
try {
    app = getApp();
} catch (e) {
    app = initializeApp(firebaseConfig);
}


// init storage reference
storage = getStorage(app);
const storageRef = ref(storage);

// ------ STORAGE ------

// add to storage
export const uploadFile = async (file, path) => {
    const fileRef = ref(storageRef, path);
    await uploadBytesResumable(fileRef, file);
};

// get file url
export const getFileUrl = async path => {
    try {
        const fileRef = ref(storageRef, path);
        const url = await getDownloadURL(fileRef);
        return url;
    } catch (e) {
        console.log(e);
        return null;
    }
};