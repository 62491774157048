import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Row, Button, Alert, Spin } from 'antd';
import './../Profile.css';

/**
 * Dashboard for tracking which fiat payment methods are linked/ iniitated
 * to a specific users Circle account or Binusu account.
*/

export default function FiatPaymentsDashboard({
    userCards,
    bankAccts,
    userType,
    showModal,
    showBankModal,
}) {

    const navigate = useNavigate();

    /* 
       LINK Credit Card & create Circle wallet if not already created 
       NOTE: Only available for buyers and admins
    */
    const walletSignUp =
        <Row justify='center' style={{ marginBottom: '0px' }}>
            <Link
                to="/circle-user-cc-signup"
            >
                <Alert
                    message={<span >Link credit card</span>}
                    type="info"
                    showIcon
                    className='usdc-alert'
                />
            </Link>
        </Row>

    /* 
       LINK bank account & create Circle wallet if not already created
       NOTE: Displays all Circle and Binusu bank link options for beneficiaries, claim creators, buyers, and admins
       If a user chooses the wrong bank account type, it will be rejected upon creation at Circle or Binusu, and the user will be notified by Adaptive
       to try again.
    */
    const bankSignUp =
        <Row justify='center' style={{ marginBottom: '20px' }}>
            <Button
                onClick={() => showModal()}
                style={{ borderWidth: '0px' }}
            >
                <Alert
                    message={<span >Link bank account</span>}
                    type="info"
                    showIcon
                    className='usdc-alert'
                />
            </Button>
        </Row>

    /*
       Dashboard 
       If the user has set up a credit credit card or bank account show if either is verified if not, show sign up options
    */
    const dashboard =
        <div >
            {/* credit card sign up */}
            {userCards ? (
                userCards.data ?
                    (
                        <div >
                            <Row justify="center" style={{ marginBottom: '6px' }}>
                                <Alert
                                    message={
                                        <span >Card verified</span>
                                    }
                                    type="success"
                                    showIcon
                                    style={{ borderRadius: '8px' }}
                                    onClick={() => { navigate("/circle-user-cc-signup") }}
                                />
                            </Row>
                        </div>
                    )
                    :
                    (walletSignUp)

            ) : (<div><Spin /></div>)}
            {/* bank wire sign up */}
            {bankAccts ? (
                bankAccts.data ?
                    (
                        <div >
                            <Row justify="center" style={{ marginBottom: '6px' }}>
                                <Alert
                                    message={
                                        <span >Bank verified</span>
                                    }
                                    type="success"
                                    showIcon
                                    style={{ borderRadius: '8px' }}
                                    onClick={() => showBankModal()}
                                />
                            </Row>
                        </div>
                    )
                    :
                    (bankSignUp)
            ) : (<div><Spin /></div>)}
        </div>

    /* 
       AUDITOR Dashboard 
       Auditors do not need to link a credit card or bank account. They only use web3 wallets to sign claim attestations.   
    */
    const notAuthorized =
        <Row justify="center" style={{ marginBottom: '6px' }}>
            {/* <Alert
                message={<span style={{ fontWeight: 'bold' }}>User type is not authorized</span>}
                type="info"
                showIcon
                style={{ borderRadius: '8px' }}
            /> */}
        </Row>



    /*
        Render dashboard based on user type
    */
    if (userType == "admin" || userType == "buyer" || userType == "beneficiary_ngo" || userType == "creator") {
        return (dashboard)
    } else {
        return (notAuthorized)
    }
}
