import React, { useEffect, useState } from "react";
import { Button, Card, Row, Form, Input, Col } from "antd";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, sendPasswordReset } from "./Firebase";
import "./Reset.css";


function Reset() {

    const [form] = Form.useForm();
    // Form Layout
    const layout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 24
        }
    };

    const [email, setEmail] = useState("");

    const [user, loading, error] = useAuthState(auth);

    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return <h1 >Loading...</h1>;
        if (user) navigate("/profile");
    }, [user, loading]);


    const handleSubmit = async () => {
        await sendPasswordReset(email);
    }

    const onFailed = async (errorInfo) => {
        console.log("Failed", form.getFieldsError);
        console.log(errorInfo);
    }

    return (
        <div className="login__container">
            <Row justify="center">
                <Col xs={23} sm={22} md={12} lg={10} xl={10}>
                    <Card style={{ borderRadius: '24px' }}>

                        <Form
                            form={form}
                            {...layout}
                            onFinish={handleSubmit}
                            onFinishFailed={onFailed}
                            requiredMark={false}
                            colon={false}
                        >
                            <h3 style={{ marginBottom: '18px' }}>Reset Password</h3>
                            <p >Enter your email and we will send you a link to get back into your account.</p>
                            <Form.Item
                                name={['email']}
                                label={'Email'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Input
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="E-mail Address"
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button
                                    size="large"
                                    shape="round"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Reset
                                </Button>
                            </Form.Item>
                            <div >
                                <Link to={"/profile"}>
                                    Back to Login Page
                                </Link>
                            </div>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div >
    );
}
export default Reset;