import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Input, Row } from 'antd';

const FlowUtils = ({
    newNode,
    addFormDisplayed,
    setAddFormDisplayed,
    parent,
    removeToolsFromCanvas,
    setFilterDisplayed,
    setColorsFilterDisplayed
}) => {
    const reveal = () => addFormDisplayed ? setAddFormDisplayed(false) : setAddFormDisplayed(true);

    return (
        <div className='sidebar-trace-left'>
            <button
                className="flow-button"
                style={{ display: removeToolsFromCanvas }}
                onClick={() => {
                    setColorsFilterDisplayed(false)
                    setFilterDisplayed(false);
                    reveal();
                }}
            >
                <PlusOutlined style={{ fontSize: '18px' }} />
            </button>
            {addFormDisplayed ? <AddNodeForm newNode={newNode} parent={parent} /> : null}
        </div>
    );
};

const AddNodeForm = ({ newNode, parent }) => {
    const [name, setName] = useState('');
    const [product, setProduct] = useState('');
    const [country, setCountry] = useState('');
    const [verified, setVerified] = useState(false);
    //const [active, setActive] = useState(false);
    const [parentName, setParentName] = useState('');

    useEffect(() => {
        if (parent) {
            setParentName(parent.data["name"])
        }
    });

    const handleCheckedVerified = (verified) => {
        setVerified(verified);
    };

    // NO LONGER USED
    // const handleCheckedActive = (active) => {
    //     setActive(active);
    // };

    function handleSubmit(event) {

        event.preventDefault();

        const value = {
            name: name,
            product: product,
            country: country,
            verified: (verified ? "Yes" : "No"),
            // active: (active ? "Yes" : "No") // no longer used
        }

        try {
            newNode(value, parent);
            setName('');
            setCountry('');
            setVerified(false);
            //setActive(false); // no longer used
            setProduct('');
            setParentName('');
        } catch {
            window.alert("Select a parent node");
        }
    }

    return (
        <div>
            <form className='filter-form' onSubmit={handleSubmit}>
                <div>
                    <h5 style={{ marginBottom: '3px' }}>Add Node</h5>
                    <p style={{ paddingTop: 10 }}>{parentName ? ("Parent: " + parentName) : ("Select parent node")}</p>
                </div>
                <div>
                    <Input
                        className="add-node-form-input"
                        id="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                    />
                </div>
                <div>
                    <Input
                        className="add-node-form-input"
                        id="product"
                        type="text"
                        value={product}
                        onChange={(e) => setProduct(e.target.value)}
                        placeholder="Product"
                    />
                </div>
                <div>
                    <Input
                        className="add-node-form-input"
                        id="country"
                        type="text"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        placeholder="Location"
                    />
                </div>
                <div>
                    <Row align='bottom' justify='center'>
                        <span htmlFor="verified" style={{ marginRight: '3px' }}>Verified </span>
                        <input
                            className="add-node-form-input"
                            id="verified"
                            type="checkbox"
                            value={verified}
                            onChange={(e) => handleCheckedVerified(e.target.value)}
                        />

                        {/* <span htmlFor="active" style={{ marginLeft: '16px', marginRight: '3px' }}>Active </span>
                        <input
                            className="add-node-form-input"
                            id="active"
                            type="checkbox"
                            value={active}
                            onChange={(e) => handleCheckedActive(e.target.value)}
                        /> */}
                    </Row>
                </div>
                <div>
                    <input type="submit" className='add-node-form-button' />
                </div>
            </form>
        </div>
    );

};

export default FlowUtils;