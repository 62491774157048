import { Alert, Button } from "antd";
import React from "react";
import { NETWORK } from "../constants";

function NetworkDisplay({ NETWORKCHECK, localChainId, selectedChainId, targetNetwork, logoutOfWeb3Modal }) {
    let networkDisplay = "";
    if (NETWORKCHECK && localChainId && selectedChainId && localChainId !== selectedChainId) {
        const networkSelected = NETWORK(selectedChainId);
        const networkLocal = NETWORK(localChainId);
        if (selectedChainId === 1337 && localChainId === 31337) {
            networkDisplay = (
                <div style={{ zIndex: 2, position: "absolute", right: 0, top: 60, padding: 16 }}>
                    <Alert
                        message="⚠️ Wrong Network ID"
                        description={
                            <div>
                                You have <b>chain id 1337</b> for localhost and you need to change it to <b>31337</b> to work with
                                HardHat.
                                <div>(MetaMask -&gt; Settings -&gt; Networks -&gt; Chain ID -&gt; 31337)</div>
                            </div>
                        }
                        type="error"
                        closable={false}
                    />
                </div>
            );
        } else {
            const showLogout = networkSelected && networkSelected.name !== "localhost";
            networkDisplay = (
                <div style={{ zIndex: 2, position: "static", top: 10, margin: 'auto' }}>
                    <Alert
                        message="⚠️ Wrong Network!"
                        description={
                            <div>
                                You have <b>{networkSelected && networkSelected.name}</b> selected. Please switch to {" "}
                                <Button
                                    onClick={async () => {
                                        const ethereum = window.ethereum;
                                        const data = [
                                            {
                                                chainId: "0x" + targetNetwork.chainId.toString(16),
                                                chainName: targetNetwork.name,
                                                nativeCurrency: targetNetwork.nativeCurrency,
                                                rpcUrls: [targetNetwork.rpcUrl],
                                                blockExplorerUrls: [targetNetwork.blockExplorer],
                                            },
                                        ];
                                        console.log("data", data);

                                        let switchTx;
                                        // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
                                        try {
                                            switchTx = await ethereum.request({
                                                method: "wallet_switchEthereumChain",
                                                params: [{ chainId: data[0].chainId }],
                                            });
                                        } catch (switchError) {
                                            // not checking specific error code, because maybe we're not using MetaMask
                                            try {
                                                switchTx = await ethereum.request({
                                                    method: "wallet_addEthereumChain",
                                                    params: data,
                                                });
                                            } catch (addError) {
                                                // handle "add" error
                                            }
                                        }

                                        if (switchTx) {
                                            console.log(switchTx);
                                        }
                                    }}
                                    size="small"
                                >
                                    <b>{networkLocal && networkLocal.name}</b>
                                </Button>
                            </div>
                        }
                        type="error"
                        closable={false}
                    />
                </div>
            );
        }
    } else {
        networkDisplay = (
            <div
                style={{ zIndex: -1, position: "absolute", right: 154, top: 28, padding: 16, color: targetNetwork.color }}
            ></div>
        );
    }

    return networkDisplay;
}

export default NetworkDisplay;