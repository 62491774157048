import {
    AppstoreOutlined,
    TeamOutlined,
    ApartmentOutlined,
    LineChartOutlined,
    IssuesCloseOutlined,
} from '@ant-design/icons';

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

export const menuItems = [
    getItem('Marketplace', 'sub1', <AppstoreOutlined />, [
        getItem('Commodity', '/commodity-market'),
        getItem('Due Diligence', '/due-diligence-market'),
        getItem('Create', '/create'),
        getItem('Validate', '/validate'),
    ]),
    getItem('Value Chain', 'sub2', <TeamOutlined />, [
        getItem('Beneficiaries', '/beneficiaries'),
        getItem('Suppliers', '/suppliers'),
    ]),
    getItem('Risk & Impact', 'sub4', <IssuesCloseOutlined />, [
        getItem('Action Plan', '/cap-report'),
        getItem('Edit Plan', '/cap-edit'),
    ]),
    // getItem('Dashboards', 'sub5', <LineChartOutlined />, [
    //     getItem('Beneficiaries', '/dashboardbeneficiaries'),
    //     getItem('Claims', '/dashboardclaims'),
    // ]),
    getItem('Dashboard', '/dashboard', <LineChartOutlined />),
    getItem('Source Flow', '/trace', <ApartmentOutlined />),
];