import React, { useRef, useEffect, useState } from 'react';
import "./Mapbox.css";
import { Link } from 'react-router-dom';
import { Col, Row, Card, Tooltip, Carousel } from 'antd'
import { SelectOutlined, CloseCircleOutlined } from '@ant-design/icons';
import RiskAssessmentIndicator from './RiskAssessmentIndicator';
import mapboxgl, { NavigationControl } from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import AdaptiveDataAllGold from './mapbox/AdaptiveDataAllGold.geojson';
import AdaptiveDataDRCGold from './mapbox/AdaptiveDataDRCGold.geojson';
import AdaptiveDataAllTin from './mapbox/AdaptiveDataAllTin.geojson';
import AdaptiveDataAllRareEarth from './mapbox/AdaptiveDataAllRareEarth.geojson';
import AdaptiveDataAllGraphite from './mapbox/AdaptiveDataAllGraphite.geojson';
import GoldMineSiteIcon from './mapbox/GoldMineSiteIcon.png';
import TinMineSiteIcon from './mapbox/TinMineSiteIcon.png';
import RareEarthMineSiteIcon from './mapbox/RareEarthMineSiteIcon.png';
import GraphiteMineSiteIcon from './mapbox/GraphiteMineSiteIcon.png';
import SunCalc from 'suncalc';
import { getFileUrl } from './auth/FirebaseStorage';
import { ref, listAll, getStorage, getDownloadURL } from 'firebase/storage';

const DEBUG = false;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

// gold icon
let goldImg = new Image(165, 165)
goldImg.src = GoldMineSiteIcon;
// tin icon
let tinImg = new Image(165, 165)
tinImg.src = TinMineSiteIcon;
// rare earth icon
let rareEarthImg = new Image(165, 165)
rareEarthImg.src = RareEarthMineSiteIcon;
// graphite icon
let graphiteImg = new Image(165, 165)
graphiteImg.src = GraphiteMineSiteIcon;

// carousel style
const contentStyle = {
    margin: 0,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: 'none',
};

export default function AdaptiveMapBox({ collapsed, dataView }) {
    // state
    const mapContainerRef = useRef(null);
    const [siteNameClicked, setSiteNameClicked] = useState("Select a Location");
    const [riskAssessmentPDF, setRiskAssessmentPDF] = useState();
    const [impactAreas, setImpactAreas] = useState();
    const [riskData, setRiskData] = useState();
    const [siteClicked, setSiteClicked] = useState("none");
    const [images, setImages] = useState([]);

    // mapbox style / view settings and data
    let styleId;
    let center;
    let zoom;
    let goldData;
    let tinData;
    let rareEarthData;
    let graphiteData;

    // only East Africa, not DRC
    if (dataView == "east-africa") {
        styleId = "mapbox://styles/adaptiveresources/clnsyfdg100cz01p7092p0ckf"; // east africa
        center = [34.690, -3.387];
        zoom = 4.75;
        goldData = AdaptiveDataAllGold;
        tinData = AdaptiveDataAllTin;
        rareEarthData = AdaptiveDataAllRareEarth;
        graphiteData = AdaptiveDataAllGraphite;
    }
    // only DRC
    else if (dataView == "DRC") {
        styleId = "mapbox://styles/adaptiveresources/clnt008ba00d401qm9ozqgbyc"; // drc only
        center = [16.823, -3.199];
        zoom = 4.04;
        goldData = AdaptiveDataDRCGold;
        tinData = null;
        rareEarthData = null;
        graphiteData = null;
    }
    // all countries
    else {
        styleId = "mapbox://styles/adaptiveresources/clnszz7n500do01p3g91md9ko"; // all countries - default
        center = [22.5, -3.551];
        zoom = 4.41;
        goldData = AdaptiveDataAllGold;
        tinData = AdaptiveDataAllTin;
        rareEarthData = AdaptiveDataAllRareEarth;
        graphiteData = AdaptiveDataAllGraphite;
    }

    // initialize mapbox when component mounts
    useEffect(() => {
        // mapbox popup offsets
        const markerHeight = 35;
        const popupOffsets = {
            'top': [0, markerHeight],
            'bottom': [0, -markerHeight],
        };

        // does the browser support mapbox?
        if (!mapboxgl.supported()) {
            alert('This browser does not support Mapbox GL');
        }
        // initialize map
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            // See style options here: https://docs.mapbox.com/api/maps/#styles
            style: styleId,
            center: center,
            zoom: zoom,
        });

        // get real-time sun/sky coloring and position
        function getSunPosition() {
            const center = map.getCenter();
            const sunPos = SunCalc.getPosition(
                Date.now(),
                center.lat,
                center.lng
            );
            if (DEBUG) console.log("SUN POS: ", sunPos)
            const sunAzimuth = 180 + (sunPos.azimuth * 180) / Math.PI;
            const sunAltitude = 90 - (sunPos.altitude * 180) / Math.PI;
            if (DEBUG) console.log("COORDINATES: ", sunAzimuth, sunAltitude)
            return [sunAzimuth, sunAltitude];
        }

        // MAP LOAD
        map.on('load', () => {
            if (DEBUG) console.log("MAPBOX: LOAD BEGINNNING");
            // add 3D terrain
            map.addSource('mapbox-dem', {
                'type': 'raster-dem',
                'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                'tileSize': 512,
                'maxzoom': 14
            });

            map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

            // Sky layer
            map.addLayer({
                'id': 'sky',
                'type': 'sky',
                'paint': {
                    'sky-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        0,
                        5,
                        0.3,
                        8,
                        1
                    ],
                    // set up the sky layer for atmospheric scattering
                    'sky-type': 'atmosphere',
                    // explicitly set the position of the sun rather than allowing the sun to be attached to the main light source
                    'sky-atmosphere-sun': getSunPosition(),
                    // set the intensity of the sun as a light source (0-100 with higher values corresponding to brighter skies)
                    'sky-atmosphere-sun-intensity': 5
                }
            });

            /**
             * 
             * GOLD Minesite icons and clusterdata layer
             * 
            */
            map.addSource('gold-points-data', {
                type: 'geojson',
                data: goldData,
                cluster: true,
            });

            // GOLD CLUSTERS
            map.addLayer({
                id: 'g-points-cluster',
                source: 'gold-points-data',
                type: 'symbol',
            });

            map.addImage('gold', goldImg, { pixelRatio: 2 });

            map.addLayer({
                id: 'clustersGold',
                type: 'symbol',
                source: 'gold-points-data',
                filter: ['has', 'point_count'],
                layout: {
                    // full list of icons here: https://labs.mapbox.com/maki-icons
                    'icon-image': 'gold',
                    'icon-size': .6,
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 13
                }
            });

            map.addLayer({
                id: 'unclustered-g-points-layer',
                type: 'symbol',
                source: 'gold-points-data',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    // full list of icons here: https://labs.mapbox.com/maki-icons
                    'icon-image': 'gold',
                    'icon-size': .6,
                    'icon-padding': 0,
                    'icon-allow-overlap': true
                }
            });

            /**
             * 
             * TIN Minesite icons and data layer
             * 
            */
            map.addSource('tin-points-data', {
                type: 'geojson',
                data: tinData,
                cluster: true,
            });

            // TIN CLUSTERS
            map.addLayer({
                id: 't-points-cluster',
                source: 'tin-points-data',
                type: 'symbol',
            });

            map.addImage('tin', tinImg, { pixelRatio: 2 });

            map.addLayer({
                id: 'clustersTin',
                type: 'symbol',
                source: 'tin-points-data',
                filter: ['has', 'point_count'],
                layout: {
                    // full list of icons here: https://labs.mapbox.com/maki-icons
                    'icon-image': 'tin',
                    'icon-size': .6,
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 13
                }
            });

            map.addLayer({
                id: 'unclustered-t-points-layer',
                type: 'symbol',
                source: 'tin-points-data',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    // full list of icons here: https://labs.mapbox.com/maki-icons
                    'icon-image': 'tin',
                    'icon-size': .6,
                    'icon-padding': 0,
                    'icon-allow-overlap': true
                }
            });

            /**
             * 
             * Rare Earth Minesite icons and clusterdata layer
             * 
            */
            map.addSource('rare-earth-points-data', {
                type: 'geojson',
                data: rareEarthData,
                cluster: true,
            });

            // RARE EARTH CLUSTERS
            map.addLayer({
                id: 're-points-cluster',
                source: 'rare-earth-points-data',
                type: 'symbol',
            });

            map.addImage('rare-earth', rareEarthImg, { pixelRatio: 2 });

            map.addLayer({
                id: 'clustersRareEarth',
                type: 'symbol',
                source: 'rare-earth-points-data',
                filter: ['has', 'point_count'],
                layout: {
                    // full list of icons here: https://labs.mapbox.com/maki-icons
                    'icon-image': 'rare-earth',
                    'icon-size': .6,
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 13
                }
            });

            map.addLayer({
                id: 'unclustered-re-points-layer',
                type: 'symbol',
                source: 'rare-earth-points-data',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    // full list of icons here: https://labs.mapbox.com/maki-icons
                    'icon-image': 'rare-earth',
                    'icon-size': .6,
                    'icon-padding': 0,
                    'icon-allow-overlap': true
                }
            });

            /**
             * 
             * Graphite Minesite icons and clusterdata layer
             * 
            */
            map.addSource('graphite-points-data', {
                type: 'geojson',
                data: graphiteData,
                cluster: true,
            });

            // RARE EARTH CLUSTERS
            map.addLayer({
                id: 'graphite-points-cluster',
                source: 'graphite-points-data',
                type: 'symbol',
            });

            map.addImage('graphite', graphiteImg, { pixelRatio: 2 });

            map.addLayer({
                id: 'clustersGraphite',
                type: 'symbol',
                source: 'graphite-points-data',
                filter: ['has', 'point_count'],
                layout: {
                    // full list of icons here: https://labs.mapbox.com/maki-icons
                    'icon-image': 'graphite',
                    'icon-size': .6,
                    'icon-padding': 0,
                    'icon-allow-overlap': true,
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 13
                }
            });

            map.addLayer({
                id: 'unclustered-graphite-points-layer',
                type: 'symbol',
                source: 'graphite-points-data',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    // full list of icons here: https://labs.mapbox.com/maki-icons
                    'icon-image': 'graphite',
                    'icon-size': .6,
                    'icon-padding': 0,
                    'icon-allow-overlap': true
                }
            });

            // toggle slideout panel on map load
            const elem = document.getElementById('left');
            elem.classList.toggle('collapsed');

            if (DEBUG) console.log("MAPBOX: LOAD END");
        });

        /**
         * 
         * inspect a GOLD cluster on click
         * 
        */
        map.on('click', 'clustersGold', (e) => {

            const features = map.queryRenderedFeatures(e.point, {
                layers: ['clustersGold']
            });

            const clusterId = features[0].properties.cluster_id;
            map.getSource('gold-points-data').getClusterExpansionZoom(
                clusterId,
                (err, zoom) => {
                    if (err) return;

                    map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom
                    });
                }
            );
        });

        // When a click event occurs in the GOLD feature layer
        // Set location data. (location population, mpi stats, description, etc...).
        map.on('click', 'unclustered-g-points-layer', async (e) => {
            // Get feature properties
            const name = e.features[0].properties.name;
            setSiteNameClicked(name);

            // Get a reference to the storage service
            const storage = getStorage();
            // Create a reference to the folder you want to get files from
            const folderRef = ref(storage, 'Mine Site Images/' + name + '/');
            // clear the images array
            setImages([]);
            listAll(folderRef)
                .then((res) => {
                    const promises = res.items.map((itemRef) => {
                        const pathReference = ref(storage, itemRef.fullPath);
                        return getDownloadURL(pathReference);
                    });

                    Promise.all(promises)
                        .then((urls) => {
                            setImages(urls);
                        })
                        .catch((error) => {
                            console.log('Error getting download urls:', error);
                        });
                })
                .catch((error) => {
                    console.log('Error getting files:', error);
                });

            const coordinates = e.features[0].geometry.coordinates.slice();
            const population = (Math.round(e.features[0].properties.population_2020));
            const populationDensity = (Math.round(e.features[0].properties.pop_density * 100) / 100);
            const riskData = e.features[0].properties;
            const prov = e.features[0].properties.province;
            const commodity = e.features[0].properties.commodity;
            const country = e.features[0].properties.country;
            const town = e.features[0].properties.parish;
            const coords = e.features[0].properties.coords;
            let operators = e.features[0].properties.operator;
            operators = operators.split(",");
            let adaptive_id = e.features[0].properties.adaptive_id;
            adaptive_id = adaptive_id.split(",");
            const mineTitle = e.features[0].properties.mine_title;
            const priority_impact_areas = e.features[0].properties.impact_areas;


            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // if there is a adaptive id then show link to it
            var links = "<ul>";
            console.log(adaptive_id[0])
            if (e.features[0].properties.adaptive_id.length > 0) {
                for (var i = 0; i < adaptive_id.length; i++) {
                    links = links + "<li key=" + adaptive_id[i] + "><a href='supplier-profile/" + adaptive_id[i] + "' style='color:#3f51b5'>" + operators[i] + "</a>" + "</li>";
                }
                links = links + "</ul>";
            } else {
                links = "";
            }

            // mapbox popup logic for gold sites
            new mapboxgl.Popup({ offset: popupOffsets, className: 'mapbox-popup-content' })
                .setLngLat(coordinates)
                .setHTML(
                    "<div ><h6 ><strong >" + name +
                    ",</strong> <strong>" + country +
                    "</strong></h6></div><h5 >" + commodity +
                    "</h5>" +
                    "<ul ><li key='prov'>Province/ District: " + prov +
                    "</li><li key='town'>Town: " + town +
                    "</li><li key='oper'>Operator: " + links +
                    "</li><li key='coord'>Coordinates: " + coords +
                    "</li><li key='minetitle'>Mine Title: " + mineTitle +
                    "</li><li key='pdensity'>Population Density: " + populationDensity + " people/km\u00B2" +
                    "</li><li key='pop'>Total Population: " + population +
                    "</li></ul>"
                )
                .addTo(map);

            // Using easeTo options.
            map.easeTo({
                center: e.features[0].geometry.coordinates,
                zoom: 13.5,
                pitch: 60,
                curve: 2,
                duration: 1000,
                easing(t) {
                    return t;
                }
            });

            // Set data
            if (DEBUG) console.log(e.features[0].properties);

            setRiskData(riskData);
            setImpactAreas(priority_impact_areas);
            setSiteClicked("block");
            downloadAssessment(operators[0]);
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'clustersGold', (e) => {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'clustersGold', () => {
            map.getCanvas().style.cursor = '';
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'unclustered-g-points-layer', (e) => {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'unclustered-g-points-layer', () => {
            map.getCanvas().style.cursor = '';
        });

        /** 
         * 
         * inspect a TIN cluster on click
         * 
        */
        map.on('click', 'clustersTin', (e) => {

            const features = map.queryRenderedFeatures(e.point, {
                layers: ['clustersTin']
            });

            const clusterId = features[0].properties.cluster_id;
            map.getSource('tin-points-data').getClusterExpansionZoom(
                clusterId,
                (err, zoom) => {
                    if (err) return;

                    map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom
                    });
                }
            );
        });

        // When a click event occurs in the TIN feature layer
        // Set location data. (location population, mpi stats, description, etc...).
        map.on('click', 'unclustered-t-points-layer', (e) => {
            // Get feature properties
            const name = e.features[0].properties.name;

            // Get a reference to the storage service
            const storage = getStorage();
            // Create a reference to the folder you want to get files from
            const folderRef = ref(storage, 'Mine Site Images/' + name + '/');
            // clear the images array
            setImages([]);
            const pics = [];
            listAll(folderRef)
                .then((res) => {
                    res.items.forEach((itemRef) => {
                        // get download url based on the file path
                        const pathReference = ref(storage, itemRef.fullPath);
                        getDownloadURL(pathReference).then((url) => {
                            pics.push(url);
                            setImages(pics);
                        }).catch((error) => {
                            console.log('Error getting download url:', error);
                        });
                    });
                })
                .catch((error) => {
                    console.log('Error getting files:', error);
                });

            const coordinates = e.features[0].geometry.coordinates.slice();
            const population = (Math.round(e.features[0].properties.population_2020));
            const populationDensity = (Math.round(e.features[0].properties.pop_density * 100) / 100);
            const riskData = e.features[0].properties;
            const prov = e.features[0].properties.province;
            const commodity = e.features[0].properties.commodity;
            const country = e.features[0].properties.country;
            const town = e.features[0].properties.parish;
            const coords = e.features[0].properties.coords;
            const operator = e.features[0].properties.operator;
            const mineTitle = e.features[0].properties.mine_title;

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            new mapboxgl.Popup({ offset: popupOffsets, className: 'mapbox-popup-content' })
                .setLngLat(coordinates)
                .setHTML(`
                    <div>
                        <strong>${name},</strong> <strong>${country}</strong>
                    </div>
                    <p><strong>${commodity}</strong></p>
                    <ul>
                        <li key="prov">Province/District: ${prov}</li>
                        <li key="town">Town: ${town}</li>
                        <li key="oper">Operator: ${operator}</li>
                        <li key="coord">Coordinates: ${coords}</li>
                        <li key="minetitle">Mine Title: ${mineTitle}</li>
                        <li key="pdensity">Population Density: ${populationDensity} people/km²</li>
                        <li key="pop">Total Population: ${population}</li>
                    </ul>
                `)
                .addTo(map)


            // Using easeTo options.
            map.easeTo({
                center: e.features[0].geometry.coordinates,
                zoom: 13.5,
                pitch: 75,
                curve: 2,
                duration: 3000,
                easing(t) {
                    return t;
                }
            });

            // Set data
            if (DEBUG) console.log(e.features[0].properties)
            setSiteNameClicked(name);
            setRiskData(riskData);
            setSiteClicked("block");
            downloadAssessment(operator);
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'clustersTin', (e) => {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'clustersTin', () => {
            map.getCanvas().style.cursor = '';
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'unclustered-t-points-layer', (e) => {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'unclustered-t-points-layer', () => {
            map.getCanvas().style.cursor = '';
        });

        /** 
         * 
         * inspect a RARE EARTH cluster on click
         * 
        */
        map.on('click', 'clustersRareEarth', (e) => {

            const features = map.queryRenderedFeatures(e.point, {
                layers: ['clustersTin']
            });

            const clusterId = features[0].properties.cluster_id;
            map.getSource('tin-points-data').getClusterExpansionZoom(
                clusterId,
                (err, zoom) => {
                    if (err) return;

                    map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom
                    });
                }
            );
        });

        // When a click event occurs in the TIN feature layer
        // Set location data. (location population, mpi stats, description, etc...).
        map.on('click', 'unclustered-re-points-layer', (e) => {
            // Get feature properties
            const name = e.features[0].properties.name;

            // Get a reference to the storage service
            const storage = getStorage();
            // Create a reference to the folder you want to get files from
            const folderRef = ref(storage, 'Mine Site Images/' + name + '/');
            // clear the images array
            setImages([]);
            const pics = [];
            listAll(folderRef)
                .then((res) => {
                    res.items.forEach((itemRef) => {
                        // get download url based on the file path
                        const pathReference = ref(storage, itemRef.fullPath);
                        getDownloadURL(pathReference).then((url) => {
                            pics.push(url);
                            setImages(pics);
                        }).catch((error) => {
                            console.log('Error getting download url:', error);
                        });
                    });
                })
                .catch((error) => {
                    console.log('Error getting files:', error);
                });

            const coordinates = e.features[0].geometry.coordinates.slice();
            const population = (Math.round(e.features[0].properties.population_2020));
            const populationDensity = (Math.round(e.features[0].properties.pop_density * 100) / 100);
            const riskData = e.features[0].properties;
            const prov = e.features[0].properties.province;
            const commodity = e.features[0].properties.commodity;
            const country = e.features[0].properties.country;
            const town = e.features[0].properties.parish;
            const coords = e.features[0].properties.coords;
            const operator = e.features[0].properties.operator;
            const mineTitle = e.features[0].properties.mine_title;

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            new mapboxgl.Popup({ offset: popupOffsets, className: 'mapbox-popup-content' })
                .setLngLat(coordinates)
                .setHTML(`
                    <div>
                        <strong>${name},</strong> <strong>${country}</strong>
                    </div>
                    <p><strong>${commodity}</strong></p>
                    <ul>
                        <li key="prov">Province/District: ${prov}</li>
                        <li key="town">Town: ${town}</li>
                        <li key="oper">Operator: ${operator}</li>
                        <li key="coord">Coordinates: ${coords}</li>
                        <li key="minetitle">Mine Title: ${mineTitle}</li>
                        <li key="pdensity">Population Density: ${populationDensity} people/km²</li>
                        <li key="pop">Total Population: ${population}</li>
                    </ul>
                `)
                .addTo(map)


            // Using easeTo options.
            map.easeTo({
                center: e.features[0].geometry.coordinates,
                zoom: 13.5,
                pitch: 75,
                curve: 2,
                duration: 3000,
                easing(t) {
                    return t;
                }
            });

            // Set data
            if (DEBUG) console.log(e.features[0].properties)
            setSiteNameClicked(name);
            setRiskData(riskData);
            setSiteClicked("block");
            downloadAssessment(operator);
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'clustersRareEarth', (e) => {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'clustersRareEarth', () => {
            map.getCanvas().style.cursor = '';
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'unclustered-re-points-layer', (e) => {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'unclustered-re-points-layer', () => {
            map.getCanvas().style.cursor = '';
        });

        /** 
         * 
         * inspect a GRAPHITE cluster on click
         * 
        */
        map.on('click', 'clustersGraphite', (e) => {

            const features = map.queryRenderedFeatures(e.point, {
                layers: ['clustersTin']
            });

            const clusterId = features[0].properties.cluster_id;
            map.getSource('tin-points-data').getClusterExpansionZoom(
                clusterId,
                (err, zoom) => {
                    if (err) return;

                    map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom
                    });
                }
            );
        });

        // When a click event occurs in the TIN feature layer
        // Set location data. (location population, mpi stats, description, etc...).
        map.on('click', 'unclustered-graphite-points-layer', (e) => {
            // Get feature properties
            const name = e.features[0].properties.name;

            // Get a reference to the storage service
            const storage = getStorage();
            // Create a reference to the folder you want to get files from
            const folderRef = ref(storage, 'Mine Site Images/' + name + '/');
            // clear the images array
            setImages([]);
            const pics = [];
            listAll(folderRef)
                .then((res) => {
                    res.items.forEach((itemRef) => {
                        // get download url based on the file path
                        const pathReference = ref(storage, itemRef.fullPath);
                        getDownloadURL(pathReference).then((url) => {
                            pics.push(url);
                            setImages(pics);
                        }).catch((error) => {
                            console.log('Error getting download url:', error);
                        });
                    });
                })
                .catch((error) => {
                    console.log('Error getting files:', error);
                });

            const coordinates = e.features[0].geometry.coordinates.slice();
            const population = (Math.round(e.features[0].properties.population_2020));
            const populationDensity = (Math.round(e.features[0].properties.pop_density * 100) / 100);
            const riskData = e.features[0].properties;
            const prov = e.features[0].properties.province;
            const commodity = e.features[0].properties.commodity;
            const country = e.features[0].properties.country;
            const town = e.features[0].properties.parish;
            const coords = e.features[0].properties.coords;
            const operator = e.features[0].properties.operator;
            const mineTitle = e.features[0].properties.mine_title;

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            new mapboxgl.Popup({ offset: popupOffsets, className: 'mapbox-popup-content' })
                .setLngLat(coordinates)
                .setHTML(`
                    <div>
                        <strong>${name},</strong> <strong>${country}</strong>
                    </div>
                    <p><strong>${commodity}</strong></p>
                    <ul>
                        <li key="prov">Province/District: ${prov}</li>
                        <li key="town">Town: ${town}</li>
                        <li key="oper">Operator: ${operator}</li>
                        <li key="coord">Coordinates: ${coords}</li>
                        <li key="minetitle">Mine Title: ${mineTitle}</li>
                        <li key="pdensity">Population Density: ${populationDensity} people/km²</li>
                        <li key="pop">Total Population: ${population}</li>
                    </ul>
                `)
                .addTo(map)


            // Using easeTo options.
            map.easeTo({
                center: e.features[0].geometry.coordinates,
                zoom: 13.5,
                pitch: 75,
                curve: 2,
                duration: 3000,
                easing(t) {
                    return t;
                }
            });

            // Set data
            if (DEBUG) console.log(e.features[0].properties)
            setSiteNameClicked(name);
            setRiskData(riskData);
            setSiteClicked("block");
            downloadAssessment(operator);
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'clustersGraphite', (e) => {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'clustersGraphite', () => {
            map.getCanvas().style.cursor = '';
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'unclustered-graphite-points-layer', (e) => {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'unclustered-graphite-points-layer', () => {
            map.getCanvas().style.cursor = '';
        });

        // reset camera position on double click
        map.on('dblclick', (e) => {
            map.easeTo({
                center: center,
                zoom: zoom,
                pitch: 0,
                bearing: 0,
                curve: 2,
                duration: 1150,
                easing(t) {
                    return t;
                }
            });
        });
        map.doubleClickZoom.disable(); // needed to prevent the default double click mapbox action

        // add navigation control (the +/- zoom buttons)
        map.addControl(new NavigationControl(), 'bottom-right');

        map.resize();

        // clean up on unmount
        return () => map.remove();
    }, []);

    // average of array of values
    const average = arr => (arr.reduce((p, c) => p + c)) / arr.length;

    // green to red color scale, green is closer to zero
    function getGreenToRed(perc) {
        var r, g, b = 0;
        if (perc > 0 && perc < 50) {
            r = 255;
            g = Math.round(5.1 * perc);
        }
        else {
            g = 255;
            r = Math.round(510 - 5.10 * perc);
        }
        var h = r * 0x10000 + g * 0x100 + b * 0x1;
        return '#' + ('000000' + h.toString(16)).slice(-6) + '80';
    }

    // returns very low, low, medium, high, very high for education indicator
    function getEduIndicator(average) {
        if (DEBUG) console.log("Edu: ", average);
        if (average >= 50) return { status: "Very High", statusColor: "#f4433690" };
        if (average >= 40 && average < 50) return { status: "High", statusColor: "#ff690090" };
        if (average >= 25 && average < 40) return { status: "Medium", statusColor: "#fcb90090" };
        if (average >= 10 && average < 25) return { status: "Low", statusColor: "#ffeb3b90" };
        if (average < 10) return { status: "Very Low", statusColor: "#00d08490" };
    }

    // returns very low, low, medium, high, very high for health indicator
    function getHealthIndicator(average) {
        if (DEBUG) console.log("Health", average);
        if (average >= 20) return { status: "Very High", statusColor: "#f4433690" };
        if (average >= 10 && average < 20) return { status: "High", statusColor: "#ff690090" };
        if (average >= 5 && average < 10) return { status: "Medium", statusColor: "#fcb90090" };
        if (average >= 3 && average < 5) return { status: "Low", statusColor: "#ffeb3b90" };
        if (average < 3) return { status: "Very Low", statusColor: "#00d08490" };
    }

    // returns very low, low, medium, high, very high for living standards indicator
    function getLivingStandardsIndicator(average) {
        if (DEBUG) console.log("Living Standard", average);
        if (average >= 85) return { status: "Very High", statusColor: "#f4433690" };
        if (average >= 70 && average < 85) return { status: "High", statusColor: "#ff690090" };
        if (average >= 45 && average < 70) return { status: "Medium", statusColor: "#fcb90090" };
        if (average >= 25 && average < 45) return { status: "Low", statusColor: "#ffeb3b90" };
        if (average < 25) return { status: "Very Low", statusColor: "#00d08490" };
    }

    function getPriorityImpactAreas(priority_impact_areas) {
        priority_impact_areas = priority_impact_areas.split(",");
        let icons = [];

        for (let i = 0; i < priority_impact_areas.length; i++) {
            if (priority_impact_areas[i] === "Community Rights" && !icons.some(element => element.icon === "/icon1.png")) {
                icons.push({ icon: "/icon1.png", description: "Community Rights" });
            }
            else if (priority_impact_areas[i] === "Economic Development" && !icons.some(element => element.icon === "/icon2.png")) {
                icons.push({ icon: "/icon2.png", description: "Economic Development" });
            }
            else if (priority_impact_areas[i] === "Land Use and Biodiversity" && !icons.some(element => element.icon === "/icon3.png")) {
                icons.push({ icon: "/icon3.png", description: "Land Use and Biodiversity" });
            }
            else if (priority_impact_areas[i] === "Water Use" && !icons.some(element => element.icon === "/icon4.png")) {
                icons.push({ icon: "/icon4.png", description: "Water Use" });
            }
            else if (priority_impact_areas[i] === "Management Practices" && !icons.some(element => element.icon === "/icon6.png")) {
                icons.push({ icon: "/icon6.png", description: "Management Practices" });
            }
            else if (priority_impact_areas[i] === "Production Improvement" && !icons.some(element => element.icon === "/icon8.png")) {
                icons.push({ icon: "/icon8.png", description: "Production Improvement" });
            }
            else if (priority_impact_areas[i] === "Occupational Health & Safety" && !icons.some(element => element.icon === "/icon5.png")) {
                icons.push({ icon: "/icon5.png", description: "Occupational Health & Safety" });
            }
            else if (priority_impact_areas[i] === "Serious Human Rights Abuses" && !icons.some(element => element.icon === "/icon7.png")) {
                icons.push({ icon: "/icon7.png", description: "Serious Human Rights Abuses" });
            }
        }

        return icons.map((icon, j) => <Tooltip title={<span>{icon.description}</span>} key={icon.description}><span><img style={{ width: "3.5vw", hieght: "3.5vw" }} src={icon.icon} key={j} alt='RTA icon' /></span></Tooltip>);
    }

    // download risk assessment data
    async function downloadAssessment(operator) {
        let file = await getFileUrl("Risk Assessments/" + operator + "/report.pdf")
        setRiskAssessmentPDF(file);
    }

    const display = (siteClicked === "none") ? (
        <div id="mapbox" ref={mapContainerRef} >
            <div id="left" className="sidebar flex-center left collapsed">
                <div className="sidebar-content rounded-rect flex-center">
                    {/* Landing Page Content */}
                    <Col >
                        <div style={{ display: !siteClicked }}>
                            <div >
                                <img src='./Brand_Images/Adaptive_Logo_Slash_DarkGray.png' alt="adaptive_logo" width={"105px"} />
                            </div>
                            <div className='sidebar-title'>
                                <h2 style={{ margin: "28px", lineHeight: '42px', color: '#686767' }}>Click a Mine Site to View Responsible Sourcing Info</h2>
                            </div>
                        </div>
                    </Col>

                </div>
                <div className="align-toggle-center" style={{ position: 'absolute', width: '95%', top: '50vh' }}>
                    <div className="sidebar-toggle rounded-rect left" onClick={() => {
                        const elem = document.getElementById('left');
                        elem.classList.toggle('collapsed');
                    }}>
                        &rarr;
                    </div>
                </div>

            </div>
        </div>
    ) : (
        <div id="mapbox" ref={mapContainerRef}>
            <div id="left" className="sidebar flex-center left collapsed">
                <div className="sidebar-content rounded-rect">
                    {/* Slide panel content */}
                    <Row justify='center'>
                        <Col span={24}>
                            <div style={{ width: '100%', marginBottom: '6px' }}>
                                {images.length > 0 ? (
                                    <Carousel >
                                        {images.map((image, index) => {
                                            return (
                                                <div key={index}>
                                                    <h3 style={contentStyle}><img src={image} style={{ height: '28vh', width: '100%', objectFit: 'cover' }} alt='site_image' /></h3>
                                                </div>
                                            )
                                        })}
                                    </Carousel>
                                ) : (
                                    <div>
                                        <h3 style={contentStyle}>
                                            <img src='https://adaptiveresources.infura-ipfs.io/ipfs/QmTxkcN897hdiRYyCAFW57gzLpfbbqLaq9hyYvwdjD7Xgw' style={{ height: '28vh', width: '100%' }} alt='image_not_available' />
                                        </h3>
                                    </div>
                                )}
                            </div>
                            <div className='sidebar-title'>
                                {siteNameClicked}
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ margin: "3px", position: "relative" }} justify='center'>
                        <Card className='sidebar-content-card-large' size='small' bordered={false} bodyStyle={{ padding: '1px' }}>
                            <Row justify='center'>
                                <Col span={24}>
                                    <span className='sidebar-subtitles' style={{ marginBottom: '4px', marginRight: '12px' }}>Multidimensional Poverty Indicators </span>
                                    <span
                                        style={{
                                            padding: '1px',
                                            backgroundColor: getGreenToRed(Math.round(riskData.avg_mpi * 100) > 0 ? Math.round(riskData.avg_mpi * 100) : "#abb8c3"),
                                            borderRadius: '6px'
                                        }}>
                                        <span className='sidebar-subtitles'>{"Avg:"}</span>{" "}{riskData ? Math.round(riskData.avg_mpi * 100) : ""}
                                    </span>
                                </Col>
                            </Row>
                            <Row justify='center'>
                                <Col span={13}>
                                    <Card className='sidebar-content-card-large' size='small' bordered={false} style={{ marginBottom: '1px' }} bodyStyle={{ padding: '4px' }}>
                                        <div className='sidebar-sub-subtitles' >
                                            <Row >
                                                <Col span={20} style={{ textAlign: 'left' }}>
                                                    {"Education"}
                                                </Col>
                                                <Col span={2}>
                                                    <svg height="11" width="30">
                                                        <circle cx="15" cy="5.5" r="5.5"
                                                            fill={(
                                                                Math.round(average([riskData.years_of_schooling, riskData.child_school_attendance])) > 0 ?
                                                                    getEduIndicator(Math.round(average([riskData.years_of_schooling, riskData.child_school_attendance]))).statusColor
                                                                    :
                                                                    "#abb8c3"
                                                            )}
                                                        />
                                                    </svg>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Col span={24} style={{ textAlign: "left" }}>
                                            <div >
                                                <Tooltip overlayStyle={{ borderRadius: '12px', fontSize: '12px' }} placement="right" title={<span >Proportion of households that has at least one member 15+ that has less than five years of schooling.</span>} trigger="hover">
                                                    Years of Schooling: {riskData ? riskData.years_of_schooling : ""}
                                                </Tooltip>

                                            </div>
                                            <div >
                                                <Tooltip overlayStyle={{ borderRadius: '12px', fontSize: '12px' }} placement="right" title={<span >Proportion of households that has at least one child between 5-15 years that is not attending school.</span>} trigger="hover">
                                                    Child School Attendance: {riskData ? riskData.child_school_attendance : ""}
                                                </Tooltip>
                                            </div>
                                        </Col>
                                    </Card>
                                </Col>
                                <Col span={10}>
                                    <Card className='sidebar-content-card-large' size='small' bordered={false} style={{ marginBottom: '1px', marginLeft: '1px' }} bodyStyle={{ padding: '4px' }}>
                                        <div className='sidebar-sub-subtitles'>
                                            <Row >
                                                <Col span={18} style={{ textAlign: 'left' }}>
                                                    {"Health"}
                                                </Col>
                                                <Col span={2}>
                                                    <svg height="11" width="30">
                                                        <circle cx="15" cy="5.5" r="5.5"
                                                            fill={(
                                                                Math.round(average([riskData.child_mortality, riskData.nutrition])) > 0 ?
                                                                    getHealthIndicator(Math.round(average([riskData.child_mortality, riskData.nutrition]))).statusColor
                                                                    :
                                                                    "#abb8c3"
                                                            )}
                                                        />
                                                    </svg>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Col span={24} style={{ textAlign: "left" }}>
                                            <div >
                                                <Tooltip overlayStyle={{ borderRadius: '12px', fontSize: '12px' }} placement="right" title={<span >Proportion of households that has at least one child who died before the age of 5.</span>} trigger="hover">
                                                    Child Mortality: {riskData ? riskData.child_mortality : ""}
                                                </Tooltip>
                                            </div>
                                            <div >
                                                <Tooltip overlayStyle={{ borderRadius: '12px', fontSize: '12px' }} placement="right" title={<span >Proportion of households that has at least one adult woman (15-49) who is underweight (BMI greater than 18.5 kg/m).</span>} trigger="hover">
                                                    Nutrition: {riskData ? riskData.nutrition : ""}
                                                </Tooltip>
                                            </div>
                                        </Col>
                                    </Card>
                                </Col>
                            </Row>
                            <Row justify='center'>
                                <Col span={18}>
                                    <Card className='sidebar-content-card-large' size='small' bordered={false} style={{ marginBottom: '1px' }} bodyStyle={{ padding: '4px' }}>
                                        <div className='sidebar-sub-subtitles' >
                                            <Row align='middle'>
                                                <Col span={21} style={{ textAlign: 'left' }}>
                                                    {"Living Standards"}
                                                </Col>
                                                <Col span={2}>
                                                    <svg height="11" width="30">
                                                        <circle cx="15" cy="5.5" r="5.5"
                                                            fill={(
                                                                average([riskData.lighting, riskData.sanitation, riskData.use_of_water, riskData.floor, riskData.cooking_fuel, riskData.assets]) > 0 ?
                                                                    getLivingStandardsIndicator(average([riskData.lighting, riskData.sanitation, riskData.use_of_water, riskData.floor, riskData.cooking_fuel, riskData.assets])).statusColor
                                                                    :
                                                                    "#abb8c3"
                                                            )}
                                                        />
                                                    </svg>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row justify='center'>
                                            <Col span={12} style={{ textAlign: "left" }}>
                                                <div >
                                                    <Tooltip overlayStyle={{ borderRadius: '12px', fontSize: '12px' }} placement="right" title={<span >Proportion of households with no electricity.</span>} trigger="hover">
                                                        Lighting: {riskData ? riskData.lighting : ""}
                                                    </Tooltip>
                                                </div>
                                                <div >
                                                    <Tooltip overlayStyle={{ borderRadius: '12px', fontSize: '12px' }} placement="right" title={<span >Proportion of households with unfinished or shared toilet.</span>} trigger="hover">
                                                        Sanitation: {riskData ? riskData.sanitation : ""}
                                                    </Tooltip>
                                                </div>
                                                <div >
                                                    <Tooltip overlayStyle={{ borderRadius: '12px', fontSize: '12px' }} placement="right" title={<span >Proportion of individuals who live in households with unimproved water.</span>} trigger="hover">
                                                        Use of Water: {riskData ? riskData.use_of_water : ""}
                                                    </Tooltip>
                                                </div>
                                            </Col>
                                            <Col span={12} style={{ textAlign: "left" }}>
                                                <div >
                                                    <Tooltip overlayStyle={{ borderRadius: '12px', fontSize: '12px' }} placement="right" title={<span >Proportion of individuals who live in households with rudimentary floors.</span>} trigger="hover">
                                                        Floor: {riskData ? riskData.floor : ""}
                                                    </Tooltip>
                                                </div>
                                                <div >
                                                    <Tooltip overlayStyle={{ borderRadius: '12px', fontSize: '12px' }} placement="right" title={<span >Proportion of households that does not have advanced cooking fuel.</span>} trigger="hover">
                                                        Cooking Fuel: {riskData ? riskData.cooking_fuel : ""}
                                                    </Tooltip>
                                                </div>
                                                <div >
                                                    <Tooltip overlayStyle={{ borderRadius: '12px', fontSize: '12px' }} placement="right" title={<span >Proportion of households that has fewer than two assets and no car.</span>} trigger="hover">
                                                        Assets: {riskData ? riskData.assets : ""}
                                                    </Tooltip>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Row>

                    <Row style={{ margin: "3px", position: "relative" }} justify='center'>
                        <Card className='sidebar-content-card-large' size='small' bordered={false} bodyStyle={{ padding: '3px' }}>
                            <Row justify='center'>
                                <div className='sidebar-subtitles' style={{ marginBottom: '4px' }}>{"Risk Assessment"}</div>
                            </Row>
                            <Row >
                                <Card className='sidebar-content-card-OECD' size='small' bordered={false} bodyStyle={{ padding: '3px' }}>
                                    <Row >
                                        <Col span={20} style={{ textAlign: "left" }}>
                                            <Tooltip overlayStyle={{ fontSize: '12px' }} placement="right" title={
                                                <span >For any listing on Adaptive application, an annual Risk Assessment must be completed in accordance with Annex II of the OECD Due Diligence Guidance for Responsible Supply Chains of Minerals from Conflict-Affected and High-Risk Areas.</span>
                                            } trigger="hover">
                                                Valid OECD Annex II Aligned Assessment
                                            </Tooltip>
                                        </Col >
                                        <Col span={4}>
                                            {riskData.valid_risk_assessment === true ? <a style={{ fontSize: '12px' }} href={riskAssessmentPDF} target='blank'><SelectOutlined /></a> : <CloseCircleOutlined style={{ fontSize: '12px' }} />}
                                        </Col>
                                    </Row>
                                </Card>
                            </Row>
                            <Row >
                                <div className='sidebar-sub-subtitles'>{"Human Rights"}</div>
                            </Row>
                            <Row align='middle' justify='center'>
                                <Col span={24}>
                                    <Card className='sidebar-content-card-large' size='small' bordered={false} style={{ marginBottom: '1px' }} bodyStyle={{ padding: '3px' }}>
                                        <Col style={{ textAlign: "left" }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <RiskAssessmentIndicator riskData={riskData.serious_abuses} />
                                                <Tooltip overlayStyle={{ fontSize: '12px' }} placement="right" title={
                                                    <span >Systematic or widespread human rights abuses associated with the extraction, transport or trade of gold, including worst forms of child labour, any forms of torture, inhuman and degrading treatments, widespread sexual violence or other gross human rights violation forced or compulsory labour, war crimes, crimes against humanity or genocide.</span>
                                                } trigger="hover">
                                                    Serious abuses associated with the extraction, transport, or mineral trade.
                                                </Tooltip>
                                            </div>
                                        </Col >
                                    </Card>
                                </Col>
                            </Row>
                            <Row >
                                <div className='sidebar-sub-subtitles' >{"Conflict"}</div>
                                <Col span={24}>
                                    <Card className='sidebar-content-card-large' size='small' bordered={false} bodyStyle={{ padding: '3px' }}>
                                        <Col style={{ textAlign: "left" }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <RiskAssessmentIndicator riskData={riskData.support} />
                                                <Tooltip overlayStyle={{ fontSize: '12px' }} placement="right" title={
                                                    <span >Direct or indirect support to illegitimate non-state armed groups, public or private security forces which illegally control mines sites, traders, others intermediaries, transport routes through the supply chains or illegally tax or extort money or minerals through the supply chains.</span>
                                                } trigger="hover">
                                                    Support to non-state armed groups.
                                                </Tooltip>
                                            </div>
                                            <div style={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}>
                                                <RiskAssessmentIndicator riskData={riskData.security} />
                                                <Tooltip overlayStyle={{ fontSize: '12px' }} placement="right" title={
                                                    <span >Direct or indirect support to public or private security forces who illegally control mine sites, transportation routes, trading hubs.</span>
                                                } trigger="hover">
                                                    Public or private security forces.
                                                </Tooltip>
                                            </div>
                                        </Col>
                                    </Card>
                                </Col>
                            </Row>
                            <Row >
                                <div className='sidebar-sub-subtitles' >{"Good Governance"}</div>
                                <Col span={24}>
                                    <Card className='sidebar-content-card-large' size='small' bordered={false} style={{ marginBottom: '0px' }} bodyStyle={{ padding: '3px' }}>
                                        <Col style={{ textAlign: "left" }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <RiskAssessmentIndicator riskData={riskData.misrepresentation} />
                                                <Tooltip overlayStyle={{ borderRadius: '12px', fontSize: '12px' }} placement="right" title={
                                                    <span >Bribery and fraudulent misrepresentation of the origin of gold.</span>
                                                } trigger="hover">
                                                    Bribery and fraudulent misrepresentation of the origin of minerals.
                                                </Tooltip>
                                            </div>
                                            <div style={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}>
                                                <RiskAssessmentIndicator riskData={riskData.money_laundering} />
                                                <Tooltip overlayStyle={{ borderRadius: '12px', fontSize: '12px' }} placement="right" title={
                                                    <span >Money laundering or terrorism financing.</span>
                                                } trigger="hover">
                                                    Money Laundering.
                                                </Tooltip>
                                            </div>
                                            <div style={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}>
                                                <RiskAssessmentIndicator riskData={riskData.payment_of_taxes} />
                                                <Tooltip overlayStyle={{ borderRadius: '12px', fontSize: '12px' }} placement="right" title={
                                                    <span >Non-compliance with taxes, fees and royalties due to Governments related to mineral extraction, trade and export from conflict affected and high-risk areas.</span>
                                                } trigger="hover">
                                                    Payment of taxes, fees, and royalties due to governments.
                                                </Tooltip>
                                            </div>
                                        </Col>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Row>

                    <Row style={{ margin: "3px", position: "relative" }} justify='center'>
                        <Card className='sidebar-content-card-large' size='small' bordered={false} bodyStyle={{ padding: '3px' }}>
                            <Row justify='center'>
                                <div className='sidebar-subtitles' style={{ marginBottom: '4px' }}>{"Priority Impact Areas"}</div>
                            </Row>
                            <Row >
                                <Col span={24}>
                                    {impactAreas ? getPriorityImpactAreas(impactAreas) : <div className='sidebar-content-card-large'>{"Data not available"}</div>}
                                </Col>
                            </Row>
                        </Card>
                    </Row>

                </div >
                <div className="align-toggle-center" style={{ position: 'absolute', width: '95%', top: '50vh' }}>
                    <div className="sidebar-toggle rounded-rect left" onClick={() => {
                        const elem = document.getElementById('left');
                        elem.classList.toggle('collapsed');
                    }}>
                        &rarr;
                    </div>
                </div>

            </div >
        </div >
    );

    return (
        <div >
            {display}
            <div style={{ color: "#ed7151", position: "fixed", bottom: "5%", left: collapsed ? "47%" : "53%", padding: 0, display: siteClicked === "block" ? "block" : "none" }} >
                <Link to='/commodity-market'>
                    <button
                        className="front-marketplace-button"
                        shape="round"
                    >
                        Enter Marketplace
                    </button>
                </Link>
            </div>
        </div>
    );
}