import { Alert, Button, Card, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";


export default function BinusuUserSignUp({ }) {
    const [form] = Form.useForm();

    // state
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [NINNumber, setNINNumber] = useState();
    const [password, setPassword] = useState();
    const [submitted, setSubmitted] = useState(false);


    // *** FORM ITEMS ****

    // Handle Failed
    const onFinishFailed = async errorInfo => {
        console.log("Failed", form.getFieldsError);
    };

    // *** HANDLE SUBMIT ***

    const handleSubmit = async () => {
        setLoading(true);

        // 1. CREATE NEW KYC OBJECT
        // 2. bms_addUser
        // 3. bms_createWallet
        // 4. save wallet info

        //console.log(response);

        setLoading(false);
        setSubmitted(true);
    };

    const checkPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue('password')) {
            callback("The passwords don't match");
        } else {
            callback();
        }
    };

    return (
        <div style={{ margin: "30px", minHeight: "100vh" }}>
            <h1 style={{ color: "#203864" }}>Adaptive Wallet Setup - Eastern Africa Bank</h1>
            <Row justify="center">
                <Col span={14} className="create-claim-form" style={{ margin: "16px", fontSize: "16px" }}>
                    <Alert
                        type="info"
                        style={{ borderRadius: "18px" }}
                        showIcon
                        description={"Only for use with bank accounts in East Africa. Once approved, users can withdraw from and deposit to the Adaptive Wallet."}
                    />
                </Col>
            </Row>
            <Row justify="center">
                <Col xs={0} sm={0} md={1} lg={2} xl={5}></Col>
                <Col xs={24} sm={24} md={22} lg={18} xl={14}>
                    <Card hoverable={true} style={{ background: "#d9d9d950", borderRadius: "18px" }}>
                        <Form
                            className="create-claim-form"
                            form={form}
                            layout="horizontal"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={handleSubmit}
                            onFinishFailed={onFinishFailed}
                            requiredMark={false}
                            labelAlign="right"
                            labelWrap
                            colon={false}
                        >
                            <Form.Item
                                label={"First Name"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    disabled={submitted}
                                    placeholder="First Name"
                                    onChange={e => {
                                        setFirstName(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={"Last Name"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    disabled={submitted}
                                    placeholder="Last Name"
                                    onChange={e => {
                                        setLastName(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={"NIN Number"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    disabled={submitted}
                                    placeholder="National Identification Number"
                                    onChange={e => {
                                        setNINNumber(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={"Wallet Password"}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.Password
                                    disabled={submitted}
                                    placeholder="Wallet Password"
                                    onChange={e => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={"Confirm Password"}
                                name="confirm"
                                rules={[
                                    {
                                        required: true,
                                        validator: checkPassword,
                                    },
                                ]}
                            >
                                <Input.Password
                                    disabled={submitted}
                                    placeholder="Confirm Password"
                                />
                            </Form.Item>
                            <Button type="primary" shape="round" htmlType="submit" loading={loading} disabled={submitted || !firstName || !lastName || !NINNumber || !password}>
                                Submit
                            </Button>
                        </Form>
                        <div style={{ position: 'relative', marginTop: '18px' }}>
                            <p style={{ fontSize: '12px', padding: '0px' }}>Powered By:</p>
                            <img src="binusu_logo.png" style={{ width: '15%' }} />
                        </div>
                    </Card>
                </Col>
                <Col xs={0} sm={0} md={1} lg={2} xl={5}></Col>
            </Row>
        </div>
    );
}