import React, { useState } from "react";
import { Button, Row, Modal, Col } from "antd";
import Address from "./Address";
import AddressMain from "./AddressMain";
import USDCBalance from "./USDCBalance";
import { ImportOutlined, WalletOutlined } from '@ant-design/icons';


/*
  ~ What it does? ~

  Displays an Address, Balance, and Wallet as one Account component, 
  also allows users to log in to existing accounts and log out

  ~ How can I use? ~

  <Account
    address={address}
    localProvider={localProvider}
    mainnetProvider={mainnetProvider}
    price={price}
    web3Modal={web3Modal}
    loadWeb3Modal={loadWeb3Modal}
    logoutOfWeb3Modal={logoutOfWeb3Modal}
    blockExplorer={blockExplorer}
  />

  ~ Features ~
  
  - Provide address={address} and get balance corresponding to the given address
  - Provide localProvider={localProvider} to access balance on local network
  - Provide userProvider={userProvider} to display a wallet
  - Provide mainnetProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. "0xa870" => "user.eth")
  - Provide price={price} of ether and get your balance converted to dollars
  - Provide web3Modal={web3Modal}, loadWeb3Modal={loadWeb3Modal}, logoutOfWeb3Modal={logoutOfWeb3Modal}
              to be able to log in/log out to/from existing accounts
  - Provide blockExplorer={blockExplorer}, click on address and get the link
              (ex. by default "https://etherscan.io/" or for xdai "https://blockscout.com/poa/xdai/")
*/

export default function Account({
  address,
  localProvider,
  mainnetProvider,
  price,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  blockExplorer,
}) {

  // ==== Account Popout Modal ====
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //====== Connect modal ======
  const modalButtons = [];
  // account popout modal
  if (web3Modal) {
    if (web3Modal.cachedProvider) {
      modalButtons.push(
        <div key={"login"}>
          <Button
            className="connect-button"
            key="address"
            onClick={() => {
              showModal()
            }}
          >
            <ImportOutlined />
            <AddressMain
              useBurner={false}
              address={address}
              ensProvider={mainnetProvider}
              blockExplorer={blockExplorer}
              fontSize={16}
            />
          </Button>

          <Modal
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            <Row justify="left">
              <Col xs={24} sm={22} md={22} lg={22} xl={22}>
                <Address
                  address={address}
                  ensProvider={mainnetProvider}
                  blockExplorer={blockExplorer}
                  fontSize={20}
                />
                <Row align={'middle'}>
                  <span style={{ fontSize: '16px', fontWeight: 'bold', paddingRight: '4px' }}>USDC Balance:</span>
                  <USDCBalance address={address} provider={localProvider} price={price} size={16} />
                </Row>
              </Col>
            </Row>
            <Row justify='end'>
              <Col style={{ paddingRight: '24px' }}>
                <Button
                  className="connect-button"
                  key="logout"
                  shape="round"
                  onClick={logoutOfWeb3Modal}
                >
                  Disconnect
                </Button>
              </Col>
            </Row>
          </Modal>

        </div >
      );
    } else {
      modalButtons.push(
        <div key={"connect"}>
          <Button
            className="connect-button"
            key="connect"
            shape="round"
            onClick={loadWeb3Modal}
          >
            <WalletOutlined /> Connect web3 wallet
          </Button>
        </div>
      );
    }
  }

  return (
    <div>
      {modalButtons}
    </div>
  );
}
